import { useCallback } from 'react';
import useCognito from '@as_core/account/useCognito';
import {authClient} from "@utils/api/base";

export const CellPlatformAPI = {
  uploadFile: (token, data) =>
    authClient(token).post(`/cell_painting/upload`, data),
  finalizeUploadFile: (token, data) =>
    authClient(token).post(`/cell_painting/finalize`, data),
};

const useCellPainting = () => {
  const { getToken } = useCognito();


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const uploadFile = useCallback(async (data: any) => {
    try {
      const token = await getToken();
      let res = await CellPlatformAPI.uploadFile(token, data);
      return res;
    } catch (error) {
      console.error('Error updating saved work:', error);
      throw error;
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const finalizeUploadFile = useCallback(async (data: any) => {
    try {
      const token = await getToken();
      let res = await CellPlatformAPI.finalizeUploadFile(token, data);
      return res;
    } catch (error) {
      console.error('Error updating saved work:', error);
      throw error;
    }
  }, []);

  return {
    uploadFile,
    finalizeUploadFile,
  };
};

export default useCellPainting;
