import React from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import { FlexItem } from '@components/layout/FlexStyles';
import InputNewPassword from './fields/InputNewPassword';
import InputCountry from './fields/InputCountry';
import InputOrganization from './fields/InputOrganization';
import InputBillingCheck from './fields/InputBillingCheck';
import { Check } from '@mui/icons-material';
import InputField from '@as_core/components/inputs/InputField';

import { FormFieldsT } from '@as_core/account/forms/config';

export type PasswordStrengthT = {
  strength: number;
  reason: string;
};

// function to get the password strength -- 5 is required
export function getPasswordStrength(password: string): PasswordStrengthT {
  if (password === '') {
    return { strength: 0, reason: 'password not defined' };
  }
  const MIN_LENGTH = 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumbers = /\d/.test(password);
  const hasSpecialChars = /[-!@#$%^&*(),.?":{}|<>]/.test(password);

  let strengthCounter = 0;
  let reason = '';
  if (hasUppercase) {
    strengthCounter++;
  } else {
    if (reason === '') reason = 'no uppercase characters';
  }
  if (hasLowercase) {
    strengthCounter++;
  } else {
    if (reason === '') reason = 'no lowercase characters';
  }
  if (hasNumbers) {
    strengthCounter++;
  } else {
    if (reason === '') reason = 'no numbers';
  }
  if (hasSpecialChars) {
    strengthCounter++;
  } else {
    if (reason === '') reason = 'no special characters';
  }
  if (password.length >= MIN_LENGTH) {
    strengthCounter++;
  } else {
    if (reason === '') reason = 'too short';
  }
  return { strength: strengthCounter, reason: reason };
}

// function to test whether valid email form
export function isValidEmail(email: string): boolean {
  // Regular expression for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const isValidField = (field: string, userData) => {
  if (!Object.hasOwn(userData, field) || userData[field] === '') return false;
  if (field === 'email') return isValidEmail(userData[field]);
  if (field === 'organization.name')
    return (
      Object.hasOwn(userData, 'organization.type') &&
      userData['organization.type'] !== ''
    );
  if (field === 'password')
    return getPasswordStrength(userData['password']).strength === 5;
  return true;
};

// confirm the form fields are filled and valid before submitting
export const isFormValid = (
  type,
  userData,
  userDataValidationKeys,
  setErrorMessage
) => {
  let emptyFields = [];
  userDataValidationKeys.forEach((k) => {
    if (k !== 'email' || type === 'create') {
      if (!Object.hasOwn(userData, k) || userData[k] === '')
        emptyFields.push(k);
    }
  });
  if (emptyFields.length > 0) {
    setErrorMessage('Not all fields set');
    return false;
  }
  if (type === 'create') {
    // check email
    if (!isValidEmail(userData['email'])) {
      setErrorMessage('Not valid email format');
      return false;
    }
    // check password strength
    if (
      Object.hasOwn(userData, 'password') &&
      getPasswordStrength(userData['password']).strength < 5
    ) {
      setErrorMessage('Password fails requirements.');
      return false;
    }
  }
  setErrorMessage('');
  return true;
};

// function used by PgUserCreateAccount to render the various input fields
// that are stored in @controls/account/fields/...
export function renderAccountInputField(
  type: 'create' | 'register',
  info: FormFieldsT,
  index: string,
  userData: object,
  handleChange: (key: string, value: string) =>  void,
  error = false,
  handleBlur: { (): void } = () => null
) {
  if (info.type === 'password' && type === 'create') {
    const value = Object.hasOwn(userData, info.value)
      ? userData[info.value]
      : '';
    return (
      <FlexItem
        key={`item-${index}-${info.label}`}
        width={info?.width ? info.width : '100%'}
      >
        <InputNewPassword
          id={info.value}
          label={info.label}
          value={value}
          error={error}
          onChange={(v: string) => handleChange(info.value, v)}
          handleBlur={handleBlur}
          size='small'
        />
      </FlexItem>
    );
  } else if (info.type === 'country') {
    return (
      <FlexItem
        key={`item-${index}-${info.label}`}
        width={info?.width ? info.width : '100%'}
      >
        <InputCountry
          id={info.value}
          label={info.label}
          value={userData[info.value]}
          onChange={(v: string) => handleChange(info.value, v)}
          handleBlur={handleBlur}
          error={error}
        />
      </FlexItem>
    );
  } else if (info.type === 'organization') {
    return (
      <FlexItem
        key={`item-${index}-${info.label}`}
        width={info?.width ? info.width : '100%'}
      >
        <InputOrganization
          id={info.value}
          label={info.label}
          name_value={userData['organization.name']}
          type_value={userData['organization.type']}
          onChange={handleChange}
          error={error}
          handleBlur={handleBlur}
        />
      </FlexItem>
    );
  } else if (info.type === 'boolean') {
    return (
      <FlexItem
        key={`item-${index}-${info.label}`}
        width={info?.width ? info.width : '100%'}
      >
        <InputBillingCheck
          field={info.value}
          label={info.label}
          value={userData[info.value]}
          onChange={(v) => handleChange(info.value, v)}
        />
      </FlexItem>
    );
  } else {
    if (type === 'create' || !['email', 'password'].includes(info.value)) {
      const value = Object.hasOwn(userData, info.value)
        ? userData[info.value]
        : '';

      return (
        <FlexItem
          key={`item-${index}-${info.label}`}
          width={info?.width ? info.width : '100%'}
        >
          <InputField
            label={info.label}
            value={value}
            onChange={(e) => handleChange(info.value, e.target.value)}
            onBlur={handleBlur}
            error={error}
            type={info.type}
            size='small'
          />
        </FlexItem>
      );
    } else {
      return null;
    }
  }
}

// function used by PgUserCreateAccount to render the various input fields
// that are stored in @controls/account/fields/...
export function renderAccountField(f, index, userData) {
  if (f.type === 'password') {
    // ignore
  } else if (f.type === 'organization') {
    return (
      <>
        <FlexItem width={'40%'}>
          <Label>Type:</Label>
          <Value>{_.get(userData, 'organization.type', '')}</Value>
        </FlexItem>
        <FlexItem width={'60%'}>
          <Label>Name:</Label>
          <Value>{_.get(userData, 'organization.name', '')}</Value>
        </FlexItem>
      </>
    );
  } else if (f.type === 'boolean') {
    const value = _.get(userData, f.value, '');
    return (
      <FlexItem width={f?.width ? f.width : '100%'}>
        <Center>
          <FlexItem>
            {value ? <Check /> : '[ ]'}{' '}
            <Value>Use this address for billing</Value>
          </FlexItem>
        </Center>
      </FlexItem>
    );
  } else {
    const value = _.get(userData, f.value, '');
    return (
      <FlexItem width={f?.width ? f.width : '100%'}>
        <Label>{f.label}:</Label>
        <Value>{value}</Value>
      </FlexItem>
    );
  }
}

const Label = styled.div`
  font-size: 14px;
  max-width: 150px;
  min-width: 100px;
  text-align: right;
  padding-right: 5px;
  align-content: flex-end;
  justify-content: flex-end;
  white-space: nowrap;
  color: ${(p) => p.theme.palette.textPrimary};
`;

const Value = styled.div`
  font-size: 14px;
  white-space: nowrap;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const Center = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

// utility to take dot notation object and expand it to the full nested object
/* e.g.
 *
 * const input = {
 *   'user.name': 'Alice',
 *   'user.age': 30,
 *   'user.address.street': '123 Main St',
 *   'user.address.city': 'Anytown',
 * };
 * =>
 * const output = {
 *   user: {
 *     name: 'Alice',
 *     age: 30,
 *     address: {
 *       street: '123 Main St',
 *       city: 'Anytown',
 *     },
 *   },
 * };
 *
 */
export function fromDotNotation(obj) {
  const output = {};

  Object.entries(obj).forEach(([key, value]) => {
    const keys = key.split('.');
    let currentObj = output;
    keys.forEach((k, index) => {
      if (index === keys.length - 1) {
        currentObj[k] = value;
      } else {
        if (!currentObj[k]) {
          currentObj[k] = {};
        }
        currentObj = currentObj[k];
      }
    });
  });

  return output;
}
