import React, { memo } from 'react';
import styled from 'styled-components';

// import "ketcher-react/dist/index.css"; // imported as a static asset in index.ts

import { StandaloneStructServiceProvider } from 'ketcher-standalone';
import { Editor } from 'ketcher-react';

import Miew from 'miew';
import { Ketcher } from 'ketcher-core';
(window as typeof globalThis).Miew = Miew;

interface IEditorProps {
  mol?: string;
  onChange?: (mol: string, smiles: string) => void;
}

const debug = false;
export const CompoundEditor = memo(function CompoundEditor({
  mol,
  onChange,
}: IEditorProps) {
  const structServiceProvider = new StandaloneStructServiceProvider();

  const handleOnInit = async (ketcher: Ketcher) => {
    (window as typeof globalThis).ketcher = ketcher;

    ketcher.setMolecule(mol);

    ketcher.editor.subscribe('change', () => {
      Promise.all([ketcher.getMolfile('v3000'), ketcher.getSmiles(false)]).then(
        ([molfile, smiles]) => {
          if (debug)
            console.log('CompoundEditor | handleChange {smiles}', smiles);
          onChange?.(molfile, smiles);
        }
      );
    });
  };

  return (
    <Panel>
      <Editor
        staticResourcesUrl={''}
        structServiceProvider={structServiceProvider}
        onInit={handleOnInit}
        errorHandler={console.error}
      />
    </Panel>
  );
});

const Panel = styled.div`
  background-color: #b2b3d2;
  position: absolute;
  height: 87%;
  width: 100%;
  overflow: hidden;
`;
