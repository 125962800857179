import React, {useState, useEffect, useContext} from 'react';
import styled from 'styled-components/macro';
import { ListLoader } from '@as_core/elements/listStyles';
import useRequests, {RequestT} from '../../hooks/useRequests';
import { FlexRow } from '@as_core/elements/flexStyles';
import {UserContext} from "@stores/UserContext";
import RequestProcessMap from "@subApps/orders/components/Assays/RequestProcessMap";
import {filterByConsulting, getStatusCounts} from "@subApps/orders/utils";
import RequestsTable from "@subApps/orders/components/Common/RequestsTable";

const debug = false;
const RequestsList = () => {
  const { user} = useContext(UserContext);
  if (debug) console.log('requestsList | user: ', user);
  const { getUserRequests } = useRequests();
  const [ requests, setRequests ] = useState<Array<RequestT>>([]);
  const [ counts, setCounts ] = useState<{ [key:string]: number }>({});
  const [ loaded, setLoaded] = useState<boolean>(false);
  const [ isLoading, setIsLoading] = useState(false);
  const [ requestsUpdated, setRequestsUpdated] = useState<boolean>(false);

  if (debug) console.log('requestsList {requests}', requests);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      getUserRequests(user.authId).then((requests => {
        if (debug) console.log('useEffect | requests: ', requests);
        // aggregate them by status
        const nonConsultingRequests = filterByConsulting(requests, false);
        setCounts(getStatusCounts(nonConsultingRequests));
        setRequests(nonConsultingRequests);
        setLoaded(true);
        setIsLoading(false);
      }));
    }
  }, [requestsUpdated]);

  if (debug) console.log('loaded', loaded, 'isLoading', isLoading, 'requests.length', requests.length);
  if (debug) console.log('counts', counts);

  return (
    <>
      { loaded && !isLoading ?
        <ListContainer>
          <RequestProcessMap
            counts={counts}
          />
          <RequestsTable
            requests={requests}
            requestsUpdated={() => setRequestsUpdated(prevState => !prevState)}
            requestType='assay data'
          />
        </ListContainer>
      :
        <FlexRow width={'100%'} height={'200px'} v_centered h_centered>
          <ListLoader />
        </FlexRow>
      }
    </>
  );
};

export default RequestsList;


const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
