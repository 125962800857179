import useAggregation, {
  BucketCfieldsNumericT,
} from './useAggregation';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { LineGraph } from './plots/LineGraph';
import Tab from '@src/components/elements/Tab';
import useCognito from '@as_core/account/useCognito';

interface Datum {
  x: number;
  y: number;
}

interface DistributionT {
  id: string;
  data: Datum[];
}

const tabsContent = [
  {
    value: 'Cell Health Index',
    key: 'cell_health_index',
  },
  {
    value: 'Cell Morphology',
    key: 'cell_morphology',
  },
  {
    value: 'Cell Membrane Integrity',
    key: 'calcein',
  },
  {
    value: 'Reactive Oxygen Species',
    key: 'mitosox',
  },
  {
    value: 'Glutathione',
    key: 'mbbr',
  },
  {
    value: 'Nuc Membrane Integrity 1',
    key: 'sytox',
  },
  {
    value: 'Nuc Membrane Integrity 2',
    key: 'pi',
  },
  {
    value: 'Cell Cycle',
    key: 'violet',
  },
  {
    value: 'Mitochondrial Membrane Potential',
    key: 'jc9',
  },
];

export enum DistributionEvent {
  GRAPH = 'GRAPH',
  TAB = 'TAB',
}

type mapRequestDataProps = { result: BucketCfieldsNumericT; field_id: string };
const mapRequestData = ({
  result,
  field_id,
}: mapRequestDataProps): DistributionT => {
  const { values } = result;
  return {
    id: field_id,
    data: values
      .filter((item) => (item._id as unknown as string) !== 'other')
      .map((item) => ({
        x: item._id,
        y: item.count,
      })),
  };
};

export type PropType = {
  field_id: string;
  boundaries: number[];
  library: string;
  yAxisLabel: string;
  xAxisLabel: string;
  onClick?: (props: { eventType: string; payload: any }) => void;
};

const ScoreDistribution = ({
  field_id,
  boundaries,
  library,
  yAxisLabel,
  xAxisLabel,
  onClick,
}: PropType) => {
  const { getBucketCfieldsNumeric, getCancelSource } = useAggregation();
  const { getToken } = useCognito();
  const cancelSource = getCancelSource();
  const [data, setData] = useState<DistributionT[]>([]);
  const [yMax, setYMax] = useState(5);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loading) {
      setLoading(true);
      const params = new URLSearchParams();
      params.append('cfieldId', field_id);
      params.append('nbuckets', '10');
      params.append('library', library);
      boundaries.forEach((item) => {
        params.append('b', item.toString());
      });

      const config = {
        cancelToken: cancelSource.token,
        params,
      };
      getBucketCfieldsNumeric(getToken(), field_id, config).then((result) => {
        if (result != null) {
          setData([mapRequestData({ result, field_id })]);
          let max = 5;
          result.values.map((item) => {
            if (item?.count > max) {
              max = item.count;
            }
          });
          if (max > 5) {
            max = 5 * (Math.floor(max / 5.0) + 1);
          }
          setYMax(max);
        }
        setLoading(false);
      });
    }
    return () => {
      setLoading(false);
      cancelSource.cancel('exit');
    };
  }, [field_id, library]);

  const handleTabClick = (value) => {
    onClick({ eventType: DistributionEvent.TAB, payload: value });
  };

  const handleGraphClick = (point, event) => {
    onClick({ eventType: DistributionEvent.GRAPH, payload: { point, event } });
  };

  const graphProps = {
    data,
    xAxisLabel,
    yAxisLabel,
    yMax,
    onClick: handleGraphClick,
  };
  // console.log("graphProps", graphProps);

  return (
    <ScoreDistributionContainer>
      <TabWrapper>
        {tabsContent.map((content) => (
          <Tab
            key={content.key}
            title={content.value}
            isActive={field_id === content.key}
            onClick={() => handleTabClick(content.key)}
          />
        ))}
      </TabWrapper>
      <DefinedHeightDiv>
        {loading ? <div>Loading...</div> : <LineGraph {...graphProps} />}
      </DefinedHeightDiv>
    </ScoreDistributionContainer>
  );
};

const ScoreDistributionContainer = styled.div`
  display: flex;
  width: calc(100% - 20px);
  flex-direction: column;
  justify-items: center;
`;

const TabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: ${(p) => p.theme.palette.titlePrimary};
`;

const DefinedHeightDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 100%;
`;

export default ScoreDistribution;
