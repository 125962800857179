import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components/macro';
import { StyleContext } from '@theme/AppStyles';
import { scaleLinear } from 'd3';
import {getValueByDot} from "@utils/misc";
import { nanColor } from '../config/colors';
import {AesSettingsT, GraphSettingsT, VertexT} from '../types';
import { zebrafishToLinear } from '../utils/properties';
import CoronaSegment, {CoronaSegmentT} from './CoronaSegment';
import FieldDetail from './FieldDetail';

interface PropsI {
  vertex: VertexT;
  radius: number;
  vertexAesSettings: AesSettingsT;
  settings?: GraphSettingsT;
  hideValues?: boolean;
  inGraph?: boolean;
  narrowRing?: boolean;
  isNeighbor?: boolean;
  secondaryFields?: {[key: string]: number};
  secondaryColor?: string;
}

const Vertex = (props:PropsI) => {
  const {
    vertex,
    radius,
    vertexAesSettings,
    settings,
    secondaryColor = null,
    hideValues= false,
    inGraph = true,
    isNeighbor = false,
    narrowRing = false,
    secondaryFields = null
  } = props;
  // eslint-disable-next-line
  const [vertexScales, setVertexScales] = useState<any>();
  const [coronaFields, setCoronaFields] = useState([]);
  const [imageUrl, setImageUrl] = useState<string>();
  const [structureScale, setStructureScale] = useState<number>(1);
  const [appStyle] = useContext(StyleContext);
  const { mode, response_type } = settings;

  function handleStructureMouseEnter() {
    setStructureScale(2);
  }

  function handleStructureMouseLeave() {
    setStructureScale(1);
  }

  function getColor(segment:CoronaSegmentT): string {
    return getValueByDot(vertexAesSettings?.fill, segment?.fieldName, nanColor);
  }

  useEffect(() => {
    if (vertexAesSettings?.image) {
      const svg_url = 'data:image/svg+xml;base64,' + btoa(vertexAesSettings.image);
      setImageUrl(svg_url);
    } else {
      setImageUrl('/img/icons/MissingStructure.svg');
    }
  }, [vertexAesSettings]);

  useEffect(() => {
    const coronaRadiusScale = scaleLinear()
        .domain([0, 1])
        .range([radius * 1.07, radius * 1.5]);

    setVertexScales((scales) => ({
      ...scales,
      coronaRadiusScale,
    }));
  }, [radius]);

  useEffect(() => {
    if (vertexScales) {
      let min = 0;
      let max = 1;
      if (narrowRing) {
        min = 0;
        max = 0.5;
      }
      const tmpCoronaFields = settings.coronaFields.map((d) => {
        return {
          ...d,
          innerRadius: vertexScales.coronaRadiusScale(min),
          outerRadius: vertexScales.coronaRadiusScale(max),
        };
      });
      setCoronaFields(tmpCoronaFields);
    }
  }, [vertexScales, settings]);

  const barScale = (v: number): number => {
    if (v === null) return 0.0;
    if (mode === 'zebrafish') return zebrafishToLinear(v);
    if (response_type === '%') return Math.min(1.0, Math.max(0.0, v/100.0));
    if (response_type === '%_inverse') return Math.min(1.0, Math.max(0.0, 1.0 - v/100.0));
    return Math.min(1.0, Math.max(0.0, v));
  }

  if (vertex === null || vertex === undefined) {
    return null;
  }

  return (
    <Container
      className='vertex'
      transform={inGraph ? `translate(${vertex.x} ${vertex.y})` : ``}
    >
      <g className='vertex-corona'>
        {coronaFields.map((segment, index) => {
          return (
            <g key={segment.fieldType + '_' + index}>
              <CoronaSegment
                color={getColor(segment)}
                segment={segment}
              />
            </g>
          );
        })}
        {!hideValues && coronaFields.map((segment) => {
            return (
              <g key={segment.fieldType}>
                <FieldDetail
                  field={vertex.data[segment.fieldType]}
                  segment={segment}
                  secondaryColor={secondaryColor}
                  secondaryField={
                    secondaryFields
                      ? secondaryFields[segment.fieldType]
                      : null
                  }
                  barScale={barScale}
                  isNeighbor={isNeighbor}
                />
              </g>
            );
          })}
      </g>
      <ScaleGroup scale={structureScale}>
        <circle
          className='vertex-base'
          cx='0'
          cy='0'
          r={radius}
          stroke='none'
          fill={appStyle.palette.backgroundSecondary}
          fillOpacity={structureScale === 1 ? 1.0 : 0.9}
        ></circle>
        <g
          className='structure'
          onMouseEnter={handleStructureMouseEnter}
          onMouseLeave={handleStructureMouseLeave}
        >
          {imageUrl && (
            <image
              x={-0.75 * radius}
              y={-0.75 * radius}
              width={1.5 * radius}
              height={1.5 * radius}
              href={imageUrl}
            />
          )}
        </g>
      </ScaleGroup>
    </Container>
  );
};

export default Vertex;

const Container = styled.g`
`;

const ScaleGroup = styled.g<{scale: number}>`
  transform: scale(${(p) => p.scale});
  transition: transform 0.2s ease;
`;
