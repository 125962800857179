import React, { ChangeEvent, useState } from 'react';
import pako from 'pako';
import useCellPainting from "@subApps/dataUpload/userData/useCellPainting";

const FileUploader: React.FC = () => {
    const { uploadFile, finalizeUploadFile } = useCellPainting();
    const [uploadStatus, setUploadStatus] = useState<string>('');
    const sendFileInChunks = async (compressedFile: Blob, originalFileName: string) => {
        const CHUNK_SIZE = 1048576; // 1MB
        const totalChunks = Math.ceil(compressedFile.size / CHUNK_SIZE);
        let promises = [];
        for (let i = 0; i < totalChunks; i++) {
            const start = i * CHUNK_SIZE;
            const end = Math.min(start + CHUNK_SIZE, compressedFile.size);
            const chunk = compressedFile.slice(start, end);
            const formData = new FormData();
            formData.append('file', chunk, originalFileName);
            formData.append('filename', originalFileName);
            formData.append('start', start.toString());
            formData.append('chunkNumber', i.toString());
            promises.push(
                uploadFile(formData)
            );
        }
        Promise.allSettled(promises).then(async (results) => {
            let allChunksUploaded = true;
            results.forEach((result, index) => {
                if (result.status === 'fulfilled') {
                    setUploadStatus(`Chunk ${index + 1}/${totalChunks} uploaded successfully.`);
                } else {
                    console.error(`Error uploading chunk ${index + 1}:`, result.reason);
                    setUploadStatus(`Error in uploading chunk ${index + 1}.`);
                    allChunksUploaded = false;
                }
            });
            
            if (allChunksUploaded) {
                try {
                    const formData = new FormData();
                    formData.append('filename', originalFileName);
                    const finalizeResponse = await finalizeUploadFile(formData);
                    setUploadStatus(finalizeResponse.data.message);
                } catch (error) {
                    console.error('Error finalizing file upload:', error);
                    setUploadStatus('Error in finalizing file upload.');
                }
            }
        });
    };
const compressAndSendFile = async (file: File) => {
    // Create a new instance of FileReader every time this function is called
    const reader = new FileReader();
    reader.onload = async (event) => {
        const arrayBuffer = event.target?.result;
        if (arrayBuffer instanceof ArrayBuffer) {
            const compressed = pako.deflate(new Uint8Array(arrayBuffer));
            const compressedFile = new Blob([compressed], { type: 'application/octet-stream' });
            setUploadStatus('Starting upload...');
            await sendFileInChunks(compressedFile, file.name);
        } else {
            console.error('File read did not produce an ArrayBuffer.');
            setUploadStatus('Error in file read.');
        }
    };
    reader.onerror = (error) => {
        console.error('Error reading file:', error);
        setUploadStatus('Error in file read.');
    };
    // Start reading the file
    reader.readAsArrayBuffer(file);
};
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            setUploadStatus('Compressing file...');
            compressAndSendFile(file);
        }
    };
    return (
        <div>
            <input type="file" onChange={handleFileChange} />
            <p style={{
                color: 'white',
                fontWeight: 'bold',
            }}>{uploadStatus}</p>
        </div>
    );
};
export default FileUploader;