import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import PageContainer from '@as_core/pages/PageContainer';
import Tab from 'src/pages/biology/Tab';
import { useParams, useNavigate } from 'react-router-dom';
import { biologyTabContent } from '@pages/biology/OurBiologyContent';
import OurBiologyDisplay from '@pages/biology/OurBiologyDisplay';
import { MenuContext } from '@stores/MenuContext';

export interface IParameters {
  image?: string;
  parameterMeasured: Array<string>;
  features: Array<{ feature: string; devTox: string; acuteTox: string }>;
  readoutMeasurement: {
    description: string;
    subtitle: string;
    urls: Array<string>;
  };
}

export interface ITab {
  title: string;
  slug: string;
  section: string;
  subsection: string;
  description: string;
  parameters: IParameters;
  onClick: (section: string, subsection: string) => void;
  subsections: Array<{
    text: string;
    slug: string;
  }>;
}

const debug = false;

const PgOurBiology = () => {
  const { section, subsection } = useParams();
  const navigate = useNavigate();
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);

  useEffect(() => {
    if (activeMenuId !== 'biology') setActiveMenuId('biology');
  }, [activeMenuId]);

  if (debug) console.log('section:', section, 'subsection:', subsection);

  const handleSectionClick = (section: any, subsection: any) => {
    navigate(`/biology/${section}/${subsection}`);
  };

  return (
    <PageContainer title='Our Biology' scroll={false}>
      <Row>
        <TabContainerScroll>
          <TabWrapper>
            {biologyTabContent.map((tabElement: ITab, index: number) => (
              <Tab
                {...tabElement}
                key={index}
                onClick={handleSectionClick}
                section={section}
                subsection={subsection}
              />
            ))}
          </TabWrapper>
        </TabContainerScroll>
        <BiologyContent>
          <OurBiologyDisplay section={section} subsection={subsection} />
        </BiologyContent>
      </Row>
    </PageContainer>
  );
};

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(p) => p.theme.palette.titlePrimary};
  padding-block: 10px;
  width: 300px;
`;

export const TabContainerScroll = styled.div`
  display: flex;
  height: calc(100vh - 50px);
  width: 300px;
  overflow-y: auto;
  overflow-x: auto;
`;

const BiologyContent = styled.div`
  display: flex;
  height: calc(100vh - 50px);
  width: calc(100% - 300px);
`;

export default PgOurBiology;
