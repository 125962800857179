import React from 'react';
import styled from 'styled-components/macro';
import { Modal } from '@mui/material';
import { Close } from '@mui/icons-material';
type Props = {
  children: React.ReactNode;
  title: string;
  modalOpen: boolean;
  onCloseClick: () => void;
  width?: number;
  height?: number;
  opacity?: number;
  className?: string;
  zIndex?: number;
  marginLeft?: number;
  marginRight?: number;
};

const StationaryModal = (props: Props) => {
  const {
    title,
    modalOpen,
    onCloseClick,
    width = 600,
    height = 400,
    opacity = 0.90,
    zIndex = 100,
    marginLeft = 0,
    marginRight = 0,
  } = props;
  return (
    <StyledModal
      open={modalOpen}
      hideBackdrop={true}
      width={width}
      height={height}
      opacity={opacity}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      zIndex={zIndex}
      marginLeft={marginLeft}
      marginRight={marginRight}
    >
      <>
        <ModalHeader className={"handle"}>
          <FlexItem>{title}</FlexItem>
          <FlexItem>
            <ClosedIcon onClick={onCloseClick} />
          </FlexItem>
        </ModalHeader>
        <ModalContent height={height} width={width}>
          {props.children}
        </ModalContent>
        <ModalFooter>
        </ModalFooter>
      </>
    </StyledModal>
  );
};
export default StationaryModal;
const ClosedIcon = styled(Close)`
  cursor: pointer;
`
const StyledModal = styled(Modal) <{ width: number, height: number, opacity: number, zIndex?: number, marginLeft?: number, marginRight?: number }>`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  opacity: ${(p) => p.opacity};
  border-radius: 15px;
  border: 1px solid #636363;
  box-shadow: 20px 20px 10px ${(p) => p.theme.palette.backgroundSecondary};
  padding: 0;
  margin-left: ${(p) => p.marginLeft}px;
  margin-right: ${(p) => p.marginRight}px;
  &.MuiModal-root {
    position: absolute;
    z-index: ${(p) => p.zIndex};
    left: calc(50vw -  0.5 * ${(p) => p.width}px - ${(p) => p.marginLeft}px + ${(p) => p.marginRight}px);
    top: calc(50vh - 0.5 * ${(p) => p.height}px);
  }
`;
const ModalHeader = styled.div`
  display: flex;
  font-size: 18px;
  height: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
  border-radius: 15px 15px 0 0;
  width: 100%;
`;
const FlexItem = styled.div`
  display: flex;
  padding: 0 10px 0 10px;
`;
const ModalFooter = styled.div`
  display: flex;
  width: 100%;
`;
const ModalContent = styled.div<{ height: number, width: number }>`
  display: flex;
  flex-direction: column;
  width: ${(p) => p.width}px;
  height: calc(${(p) => p.height} - 50px);
  & ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  & ::-webkit-scrollbar-track {
    background: transparent;
  }
  & ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  & ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
`;