import React, { useContext } from 'react';
import styled from "styled-components/macro";
import { StyleContext } from '@theme/AppStyles';
import {mapCountsToPositions} from "@subApps/orders/utils";
import { positionT } from "@subApps/orders/utils";

const notificationPositions: {[key: string]: positionT} = {
  'Open' : { x: 25, y: 8},
  'Shipped': { x: 193, y: 8},
  'Received': { x: 361, y: 8},
  'Running': {x: 529, y: 8},
  'Processing': {x: 529, y: 159},
  'QC': {x: 361, y: 159},
  'Delivered': {x: 193, y: 159},
  'Available': {x: 25, y:159}
}

type PropsT = {
  counts: {[key:string]: number}
}

const debug = false;
const RequestProcessMap = (props:PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const image = (style.name === 'dark') ? '/img/Request_Process_images/Assay_Process_dark.svg' : '/img/Request_Process_images/Assay_Process_light.svg';
  const colorHighlight = style.palette.accentSecondary;
  // image details
  const width= 715.37;
  const height = 312.70;
  const scale = 1.0;

  const positions = mapCountsToPositions(props.counts, notificationPositions);
  if (debug) console.log('counts', props.counts, 'positions', positions);

  return (
    <MapContainer width={scale*width} height={scale*height}>
      { positions.map((p, index) => (
        <NotificationCountBubble key={`count_bubble_${index}`} x={p.x} y={p.y}>{p.count}</NotificationCountBubble>
      ))}
      <img src={image} alt={'process image map'}/>
      <svg width={scale*width} height={scale*height} viewBox="0 0 614.5 183.6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <g>
            <rect stroke={colorHighlight} strokeMiterlimit={10} strokeWidth={2} x="10.9" y="140.4" width="104" height="38" rx="11.4" ry="11.4"/>
            <g>
              <path fill={color} d="M23.8,155.1h2.9c2.6,0,4.3,1.6,4.3,4.2s-1.7,4.3-4.3,4.3h-2.9v-8.5ZM26.6,162.2c1.7,0,2.8-1,2.8-2.9s-1.1-2.9-2.8-2.9h-1.3v5.9h1.3Z"/>
              <path fill={color} d="M35.5,159.7h.2c0-.9-.5-1.2-1.2-1.2s-1.7.6-1.7.6l-.6-1.1s.9-.8,2.4-.8,2.5.9,2.5,2.4v3.8h-1.4v-.5c0-.2,0-.4,0-.4h0s-.5,1.1-1.9,1.1-2.1-.7-2.1-1.9c0-1.9,2.6-2.1,3.6-2.1ZM34.3,162.5c.8,0,1.4-.9,1.4-1.6h0c0-.1-.3-.1-.3-.1-.8,0-2,.1-2,1s.3.8.9.8Z"/>
              <path fill={color} d="M39,158.8h-.8v-1.2h.8v-1.8h1.5v1.8h1.4v1.2h-1.4v2.4c0,1,.8,1.2,1.2,1.2s.3,0,.3,0v1.3s-.2,0-.5,0c-.9,0-2.5-.3-2.5-2.3v-2.6Z"/>
              <path fill={color} d="M46.4,159.7h.2c0-.9-.5-1.2-1.2-1.2s-1.7.6-1.7.6l-.6-1.1s.9-.8,2.4-.8,2.5.9,2.5,2.4v3.8h-1.4v-.5c0-.2,0-.4,0-.4h0s-.5,1.1-1.9,1.1-2.1-.7-2.1-1.9c0-1.9,2.6-2.1,3.6-2.1ZM45.2,162.5c.8,0,1.4-.9,1.4-1.6h0c0-.1-.3-.1-.3-.1-.8,0-2,.1-2,1s.3.8.9.8Z"/>
              <path fill={color} d="M57.1,161.4h-3l-.7,2.2h-1.6l3-8.5h1.6l3,8.5h-1.6l-.7-2.2ZM55.6,156.5s-.2.8-.4,1.3l-.8,2.3h2.2l-.8-2.3c-.2-.5-.3-1.3-.3-1.3h0Z"/>
              <path fill={color} d="M59.3,157.5h1.6l1.3,3.8c.1.4.2.9.2.9h0s0-.6.2-.9l1.3-3.8h1.6l-2.2,6.1h-1.8l-2.2-6.1Z"/>
              <path fill={color} d="M69.5,159.7h.2c0-.9-.5-1.2-1.2-1.2s-1.7.6-1.7.6l-.6-1.1s.9-.8,2.4-.8,2.5.9,2.5,2.4v3.8h-1.4v-.5c0-.2,0-.4,0-.4h0s-.5,1.1-1.9,1.1-2.1-.7-2.1-1.9c0-1.9,2.6-2.1,3.6-2.1ZM68.3,162.5c.8,0,1.4-.9,1.4-1.6h0c0-.1-.3-.1-.3-.1-.8,0-2,.1-2,1s.3.8.9.8Z"/>
              <path fill={color} d="M72.8,155.1h1.5v1.4h-1.5v-1.4ZM72.8,157.5h1.5v6.1h-1.5v-6.1Z"/>
              <path fill={color} d="M75.9,155.1h1.5v6.4c0,.7.2.8.6.8s.2,0,.2,0v1.3s-.2,0-.5,0c-.8,0-1.9-.2-1.9-2v-6.6Z"/>
              <path fill={color} d="M82.5,159.7h.2c0-.9-.5-1.2-1.2-1.2s-1.7.6-1.7.6l-.6-1.1s.9-.8,2.4-.8,2.5.9,2.5,2.4v3.8h-1.4v-.5c0-.2,0-.4,0-.4h0s-.5,1.1-1.9,1.1-2.1-.7-2.1-1.9c0-1.9,2.6-2.1,3.6-2.1ZM81.4,162.5c.8,0,1.4-.9,1.4-1.6h0c0-.1-.3-.1-.3-.1-.8,0-2,.1-2,1s.3.8.9.8Z"/>
              <path fill={color} d="M85.8,155.1h1.5v2.6c0,.3,0,.5,0,.5h0s.5-.9,1.8-.9,2.7,1.3,2.7,3.2-1.2,3.2-2.8,3.2-1.8-.9-1.8-.9h0s0,.2,0,.4v.3h-1.4v-8.5ZM88.8,162.4c.8,0,1.5-.7,1.5-1.9s-.6-1.9-1.5-1.9-1.5.6-1.5,1.9.5,1.9,1.5,1.9Z"/>
              <path fill={color} d="M93.1,155.1h1.5v6.4c0,.7.2.8.6.8s.2,0,.2,0v1.3s-.2,0-.5,0c-.8,0-1.9-.2-1.9-2v-6.6Z"/>
              <path fill={color} d="M99.2,157.3c1.8,0,2.7,1.3,2.7,2.9s0,.6,0,.6h-4.2c0,1.1.9,1.6,1.8,1.6s1.8-.7,1.8-.7l.6,1.1s-.9.9-2.5.9-3.3-1.5-3.3-3.2,1.3-3.2,3.1-3.2ZM100.4,159.8c0-.8-.5-1.3-1.2-1.3s-1.3.5-1.5,1.3h2.7Z"/>
            </g>
          </g>
          <g>
            <rect stroke={colorHighlight} strokeMiterlimit={10} strokeWidth={2} x="211.1" y="140.7" width="104" height="38" rx="11.4" ry="11.4"/>
            <g>
              <path fill={color} d="M240.1,155.4h2.9c2.6,0,4.3,1.6,4.3,4.2s-1.7,4.3-4.3,4.3h-2.9v-8.5ZM242.9,162.6c1.7,0,2.8-1,2.8-2.9s-1.1-2.9-2.8-2.9h-1.3v5.9h1.3Z"/>
              <path fill={color} d="M251.8,160.1h.2c0-.9-.5-1.2-1.2-1.2s-1.7.6-1.7.6l-.6-1.1s.9-.8,2.4-.8,2.5.9,2.5,2.4v3.8h-1.4v-.5c0-.2,0-.4,0-.4h0s-.5,1.1-1.9,1.1-2.1-.7-2.1-1.9c0-1.9,2.6-2.1,3.6-2.1ZM250.6,162.9c.8,0,1.4-.9,1.4-1.6h0c0-.1-.3-.1-.3-.1-.8,0-2,.1-2,1s.3.8.9.8Z"/>
              <path fill={color} d="M255.3,159.1h-.8v-1.2h.8v-1.8h1.5v1.8h1.4v1.2h-1.4v2.4c0,1,.8,1.2,1.2,1.2s.3,0,.3,0v1.3s-.2,0-.5,0c-.9,0-2.5-.3-2.5-2.3v-2.6Z"/>
              <path fill={color} d="M262.7,160.1h.2c0-.9-.5-1.2-1.2-1.2s-1.7.6-1.7.6l-.6-1.1s.9-.8,2.4-.8,2.5.9,2.5,2.4v3.8h-1.4v-.5c0-.2,0-.4,0-.4h0s-.5,1.1-1.9,1.1-2.1-.7-2.1-1.9c0-1.9,2.6-2.1,3.6-2.1ZM261.5,162.9c.8,0,1.4-.9,1.4-1.6h0c0-.1-.3-.1-.3-.1-.8,0-2,.1-2,1s.3.8.9.8Z"/>
              <path fill={color} d="M273,155.3c2.5,0,4.4,1.9,4.4,4.4s-.4,2-.9,2.7l1,.9-.9,1-.9-.9c-.7.5-1.6.8-2.6.8-2.5,0-4.4-2-4.4-4.4s1.9-4.4,4.4-4.4ZM273,162.7c.5,0,1.1-.2,1.5-.4l-.9-.9.9-1,.9.9c.3-.5.5-1,.5-1.6,0-1.7-1.2-2.9-2.8-2.9s-2.8,1.2-2.8,2.9,1.2,3,2.8,3Z"/>
              <path fill={color} d="M282.9,155.3c2.1,0,3.1,1.2,3.1,1.2l-.7,1.2s-1-.9-2.3-.9-2.9,1.3-2.9,2.9,1.1,3,2.9,3,2.5-1.1,2.5-1.1l.8,1.1s-1.2,1.4-3.3,1.4-4.4-1.9-4.4-4.4,1.9-4.4,4.4-4.4Z"/>
            </g>
          </g>
          <g>
            <rect stroke={colorHighlight} strokeMiterlimit={10} strokeWidth={2} x="427.4" y="140" width="104" height="38" rx="11.4" ry="11.4"/>
            <g>
              <path fill={color} d="M435.1,153.4h2.9c2.6,0,4.3,1.6,4.3,4.2s-1.7,4.3-4.3,4.3h-2.9v-8.5ZM437.9,160.6c1.7,0,2.8-1,2.8-2.9s-1.1-2.9-2.8-2.9h-1.3v5.9h1.3Z"/>
              <path fill={color} d="M446.8,158.1h.2c0-.9-.5-1.2-1.2-1.2s-1.7.6-1.7.6l-.6-1.1s.9-.8,2.4-.8,2.5.9,2.5,2.4v3.8h-1.4v-.5c0-.2,0-.4,0-.4h0s-.5,1.1-1.9,1.1-2.1-.7-2.1-1.9c0-1.9,2.6-2.1,3.6-2.1ZM445.6,160.9c.8,0,1.4-.9,1.4-1.6h0c0-.1-.3-.1-.3-.1-.8,0-2,.1-2,1s.3.8.9.8Z"/>
              <path fill={color} d="M450.3,157.2h-.8v-1.2h.8v-1.8h1.5v1.8h1.4v1.2h-1.4v2.4c0,1,.8,1.2,1.2,1.2s.3,0,.3,0v1.3s-.2,0-.5,0c-.9,0-2.5-.3-2.5-2.3v-2.6Z"/>
              <path fill={color} d="M457.7,158.1h.2c0-.9-.5-1.2-1.2-1.2s-1.7.6-1.7.6l-.6-1.1s.9-.8,2.4-.8,2.5.9,2.5,2.4v3.8h-1.4v-.5c0-.2,0-.4,0-.4h0s-.5,1.1-1.9,1.1-2.1-.7-2.1-1.9c0-1.9,2.6-2.1,3.6-2.1ZM456.5,160.9c.8,0,1.4-.9,1.4-1.6h0c0-.1-.3-.1-.3-.1-.8,0-2,.1-2,1s.3.8.9.8Z"/>
              <path fill={color} d="M464.2,153.4h3.3c1.6,0,2.7,1.1,2.7,2.8s-1.1,2.8-2.7,2.8h-1.7v2.9h-1.5v-8.5ZM467.2,157.7c.9,0,1.4-.6,1.4-1.5s-.5-1.4-1.4-1.4h-1.5v2.9h1.5Z"/>
              <path fill={color} d="M471.3,155.8h1.5v1.1c0,.2,0,.4,0,.4h0c.3-.9,1-1.6,1.9-1.6s.3,0,.3,0v1.5s-.2,0-.4,0c-.6,0-1.4.4-1.7,1.3,0,.3-.1.6-.1,1v2.4h-1.5v-6.1Z"/>
              <path fill={color} d="M478.9,155.7c1.9,0,3.4,1.3,3.4,3.2s-1.5,3.2-3.4,3.2-3.4-1.3-3.4-3.2,1.5-3.2,3.4-3.2ZM478.9,160.8c1,0,1.8-.8,1.8-1.9s-.8-1.9-1.8-1.9-1.8.8-1.8,1.9.8,1.9,1.8,1.9Z"/>
              <path fill={color} d="M486.4,155.7c1.6,0,2.4.9,2.4.9l-.7,1s-.6-.7-1.6-.7-1.9.9-1.9,1.9.7,1.9,1.9,1.9,1.8-.8,1.8-.8l.6,1.1s-.9,1-2.5,1-3.3-1.4-3.3-3.2,1.3-3.2,3.3-3.2Z"/>
              <path fill={color} d="M492.7,155.7c1.8,0,2.7,1.3,2.7,2.9s0,.6,0,.6h-4.2c0,1.1.9,1.6,1.8,1.6s1.8-.7,1.8-.7l.6,1.1s-.9.9-2.5.9-3.3-1.5-3.3-3.2,1.3-3.2,3.1-3.2ZM493.9,158.2c0-.8-.5-1.3-1.2-1.3s-1.3.5-1.5,1.3h2.7Z"/>
              <path fill={color} d="M496.9,160.1s.7.7,1.7.7.8-.2.8-.6c0-.9-3-.8-3-2.8s1-1.8,2.3-1.8,2.1.7,2.1.7l-.6,1.1s-.6-.5-1.5-.5-.8.2-.8.6c0,.9,3,.8,3,2.7s-.9,1.8-2.3,1.8-2.4-.9-2.4-.9l.7-1.1Z"/>
              <path fill={color} d="M502.3,160.1s.7.7,1.7.7.8-.2.8-.6c0-.9-3-.8-3-2.8s1-1.8,2.3-1.8,2.1.7,2.1.7l-.6,1.1s-.6-.5-1.5-.5-.8.2-.8.6c0,.9,3,.8,3,2.7s-.9,1.8-2.3,1.8-2.4-.9-2.4-.9l.7-1.1Z"/>
              <path fill={color} d="M507.5,153.4h1.5v1.4h-1.5v-1.4ZM507.5,155.8h1.5v6.1h-1.5v-6.1Z"/>
              <path fill={color} d="M510.7,155.8h1.5v.7c0,.2,0,.4,0,.4h0c.3-.5.9-1.3,2.2-1.3s2.1.7,2.1,2.3v3.9h-1.5v-3.6c0-.7-.2-1.2-1-1.2s-1.4.5-1.6,1.2c0,.3-.1.5-.1.8v2.8h-1.5v-6.1Z"/>
              <path fill={color} d="M520.4,163.2c.9,0,1.7-.4,1.7-1.5v-.4c0-.1,0-.3,0-.3h0c-.4.5-.9.8-1.7.8-1.8,0-2.7-1.4-2.7-3.1s.9-3,2.7-3,1.8.8,1.8.8h0s0,0,0-.2v-.5h1.4v5.7c0,2.1-1.6,2.9-3.2,2.9s-1.6-.2-2.2-.5l.5-1.2s.8.4,1.7.4ZM522.2,158.8c0-1.4-.7-1.8-1.5-1.8s-1.4.7-1.4,1.7.6,1.9,1.5,1.9,1.4-.4,1.4-1.8Z"/>
            </g>
          </g>
          <g>
            <rect stroke={colorHighlight} strokeMiterlimit={10} strokeWidth={2} x="423.8" y="75.7" width="104" height="38" rx="11.4" ry="11.4"/>
            <g>
              <path fill={color} d="M440.7,95.5h-3l-.7,2.2h-1.6l3-8.5h1.6l3,8.5h-1.6l-.7-2.2ZM439.2,90.6s-.2.8-.4,1.3l-.8,2.3h2.2l-.8-2.3c-.2-.5-.3-1.3-.3-1.3h0Z"/>
              <path fill={color} d="M444,95.9s.7.7,1.7.7.8-.2.8-.6c0-.9-3-.8-3-2.8s1-1.8,2.3-1.8,2.1.7,2.1.7l-.6,1.1s-.6-.5-1.5-.5-.8.2-.8.6c0,.9,3,.8,3,2.7s-.9,1.8-2.3,1.8-2.4-.9-2.4-.9l.7-1.1Z"/>
              <path fill={color} d="M449.4,95.9s.7.7,1.7.7.8-.2.8-.6c0-.9-3-.8-3-2.8s1-1.8,2.3-1.8,2.1.7,2.1.7l-.6,1.1s-.6-.5-1.5-.5-.8.2-.8.6c0,.9,3,.8,3,2.7s-.9,1.8-2.3,1.8-2.4-.9-2.4-.9l.7-1.1Z"/>
              <path fill={color} d="M457.8,93.8h.2c0-.9-.5-1.2-1.2-1.2s-1.7.6-1.7.6l-.6-1.1s.9-.8,2.4-.8,2.5.9,2.5,2.4v3.8h-1.4v-.5c0-.2,0-.4,0-.4h0s-.5,1.1-1.9,1.1-2.1-.7-2.1-1.9c0-1.9,2.6-2.1,3.6-2.1ZM456.6,96.6c.8,0,1.4-.9,1.4-1.6h0c0-.1-.3-.1-.3-.1-.8,0-2,.1-2,1s.3.8.9.8Z"/>
              <path fill={color} d="M460.8,98.7s.3.3.7.3.8-.3,1.1-.8l.2-.5-2.6-6h1.7l1.3,3.5c.1.3.2.8.2.8h0s0-.5.2-.8l1.2-3.6h1.7l-2.7,7c-.4,1.1-1.3,1.6-2.2,1.6s-1.3-.4-1.3-.4l.5-1.1Z"/>
              <path fill={color} d="M470.5,89.2h2.7c.9,0,1.3,0,1.7.2.9.4,1.4,1.2,1.4,2.3s-.5,2-1.4,2.3h0s.1.1.3.4l1.8,3.2h-1.7l-1.7-3.2h-1.5v3.2h-1.5v-8.5ZM473.4,93.2c.8,0,1.3-.5,1.3-1.3s-.3-1.3-1.6-1.3h-1.2v2.7h1.4Z"/>
              <path fill={color} d="M478.1,91.6h1.5v3.6c0,.7.2,1.2.9,1.2,1.1,0,1.7-1,1.7-2.1v-2.8h1.5v6.1h-1.5v-.7c0-.2,0-.4,0-.4h0c-.3.6-1.1,1.3-2.1,1.3s-2.1-.7-2.1-2.3v-3.9Z"/>
              <path fill={color} d="M485.5,91.6h1.5v.7c0,.2,0,.4,0,.4h0c.3-.5.9-1.3,2.2-1.3s2.1.7,2.1,2.3v3.9h-1.5v-3.6c0-.7-.2-1.2-1-1.2s-1.4.5-1.6,1.2c0,.3-.1.5-.1.8v2.8h-1.5v-6.1Z"/>
              <path fill={color} d="M492.8,91.6h1.5v.7c0,.2,0,.4,0,.4h0c.3-.5.9-1.3,2.2-1.3s2.1.7,2.1,2.3v3.9h-1.5v-3.6c0-.7-.2-1.2-1-1.2s-1.4.5-1.6,1.2c0,.3-.1.5-.1.8v2.8h-1.5v-6.1Z"/>
              <path fill={color} d="M500.2,89.2h1.5v1.4h-1.5v-1.4ZM500.2,91.6h1.5v6.1h-1.5v-6.1Z"/>
              <path fill={color} d="M503.4,91.6h1.5v.7c0,.2,0,.4,0,.4h0c.3-.5.9-1.3,2.2-1.3s2.1.7,2.1,2.3v3.9h-1.5v-3.6c0-.7-.2-1.2-1-1.2s-1.4.5-1.6,1.2c0,.3-.1.5-.1.8v2.8h-1.5v-6.1Z"/>
              <path fill={color} d="M513.1,98.9c.9,0,1.7-.4,1.7-1.5v-.4c0-.1,0-.3,0-.3h0c-.4.5-.9.8-1.7.8-1.8,0-2.7-1.4-2.7-3.1s.9-3,2.7-3,1.8.8,1.8.8h0s0,0,0-.2v-.5h1.4v5.7c0,2.1-1.6,2.9-3.2,2.9s-1.6-.2-2.2-.5l.5-1.2s.8.4,1.7.4ZM514.8,94.5c0-1.4-.7-1.8-1.5-1.8s-1.4.7-1.4,1.7.6,1.9,1.5,1.9,1.4-.4,1.4-1.8Z"/>
            </g>
          </g>
          <g>
            <rect stroke={colorHighlight} strokeMiterlimit={10} strokeWidth={2} x="213.6" y="75.8" width="104" height="38" rx="11.4" ry="11.4"/>
            <g>
              <path fill={color} d="M235.7,83.2c2.1,0,3.1,1.2,3.1,1.2l-.7,1.2s-1-.9-2.3-.9-2.9,1.3-2.9,2.9,1.1,3,2.9,3,2.5-1.1,2.5-1.1l.8,1.1s-1.2,1.4-3.3,1.4-4.4-1.9-4.4-4.4,1.9-4.4,4.4-4.4Z"/>
              <path fill={color} d="M243.2,85.6c1.9,0,3.4,1.3,3.4,3.2s-1.5,3.2-3.4,3.2-3.4-1.3-3.4-3.2,1.5-3.2,3.4-3.2ZM243.2,90.7c1,0,1.8-.8,1.8-1.9s-.8-1.9-1.8-1.9-1.8.8-1.8,1.9.8,1.9,1.8,1.9Z"/>
              <path fill={color} d="M247.8,85.7h1.5v.7c0,.2,0,.4,0,.4h0c.3-.6,1-1.3,2-1.3s1.5.4,1.8,1.3h0c.3-.7,1.1-1.3,2.1-1.3s2,.7,2,2.3v3.9h-1.5v-3.6c0-.7-.1-1.2-.8-1.2s-1.3.6-1.5,1.4c0,.2,0,.5,0,.8v2.7h-1.5v-3.6c0-.7-.1-1.2-.8-1.2s-1.3.6-1.5,1.4c0,.2,0,.5,0,.8v2.7h-1.5v-6.1Z"/>
              <path fill={color} d="M258.8,85.7h1.4v.4c0,.2,0,.4,0,.4h0s.5-1,2-1,2.7,1.3,2.7,3.2-1.2,3.2-2.8,3.2-1.8-.8-1.8-.8h0s0,.2,0,.5v2.6h-1.5v-8.5ZM261.8,90.7c.8,0,1.5-.7,1.5-1.9s-.6-1.9-1.5-1.9-1.5.6-1.5,1.9.5,1.9,1.5,1.9Z"/>
              <path fill={color} d="M269,85.6c1.9,0,3.4,1.3,3.4,3.2s-1.5,3.2-3.4,3.2-3.4-1.3-3.4-3.2,1.5-3.2,3.4-3.2ZM269,90.7c1,0,1.8-.8,1.8-1.9s-.8-1.9-1.8-1.9-1.8.8-1.8,1.9.8,1.9,1.8,1.9Z"/>
              <path fill={color} d="M273.5,85.7h1.5v3.6c0,.7.2,1.2.9,1.2,1.1,0,1.7-1,1.7-2.1v-2.8h1.5v6.1h-1.5v-.7c0-.2,0-.4,0-.4h0c-.3.6-1.1,1.3-2.1,1.3s-2.1-.7-2.1-2.3v-3.9Z"/>
              <path fill={color} d="M280.9,85.7h1.5v.7c0,.2,0,.4,0,.4h0c.3-.5.9-1.3,2.2-1.3s2.1.7,2.1,2.3v3.9h-1.5v-3.6c0-.7-.2-1.2-1-1.2s-1.4.5-1.6,1.2c0,.3-.1.5-.1.8v2.8h-1.5v-6.1Z"/>
              <path fill={color} d="M290.6,85.6c1.3,0,1.8.8,1.8.8h0s0-.2,0-.3v-2.7h1.5v8.5h-1.4v-.5c0-.2,0-.4,0-.4h0s-.5,1-1.9,1-2.7-1.3-2.7-3.2,1.2-3.2,2.8-3.2ZM290.9,90.7c.8,0,1.5-.6,1.5-1.9s-.5-1.9-1.5-1.9-1.5.7-1.5,1.9.6,1.9,1.5,1.9Z"/>
              <path fill={color} d="M295.8,90s.7.7,1.7.7.8-.2.8-.6c0-.9-3-.8-3-2.8s1-1.8,2.3-1.8,2.1.7,2.1.7l-.6,1.1s-.6-.5-1.5-.5-.8.2-.8.6c0,.9,3,.8,3,2.7s-.9,1.8-2.3,1.8-2.4-.9-2.4-.9l.7-1.1Z"/>
              <path fill={color} d="M241.1,97.7h2.7c.9,0,1.3,0,1.7.2.9.4,1.4,1.2,1.4,2.3s-.5,2-1.4,2.3h0s.1.1.3.4l1.8,3.2h-1.7l-1.7-3.2h-1.5v3.2h-1.5v-8.5ZM244,101.7c.8,0,1.3-.5,1.3-1.3s-.3-1.3-1.6-1.3h-1.2v2.7h1.4Z"/>
              <path fill={color} d="M251.3,100c1.8,0,2.7,1.3,2.7,2.9s0,.6,0,.6h-4.2c0,1.1.9,1.6,1.8,1.6s1.8-.7,1.8-.7l.6,1.1s-.9.9-2.5.9-3.3-1.5-3.3-3.2,1.3-3.2,3.1-3.2ZM252.5,102.4c0-.8-.5-1.3-1.2-1.3s-1.3.5-1.5,1.3h2.7Z"/>
              <path fill={color} d="M258.2,100c1.6,0,2.4.9,2.4.9l-.7,1s-.6-.7-1.6-.7-1.9.9-1.9,1.9.7,1.9,1.9,1.9,1.8-.8,1.8-.8l.6,1.1s-.9,1-2.5,1-3.3-1.4-3.3-3.2,1.3-3.2,3.3-3.2Z"/>
              <path fill={color} d="M264.5,100c1.8,0,2.7,1.3,2.7,2.9s0,.6,0,.6h-4.2c0,1.1.9,1.6,1.8,1.6s1.8-.7,1.8-.7l.6,1.1s-.9.9-2.5.9-3.3-1.5-3.3-3.2,1.3-3.2,3.1-3.2ZM265.7,102.4c0-.8-.5-1.3-1.2-1.3s-1.3.5-1.5,1.3h2.7Z"/>
              <path fill={color} d="M268.5,97.7h1.5v1.4h-1.5v-1.4ZM268.5,100.1h1.5v6.1h-1.5v-6.1Z"/>
              <path fill={color} d="M270.9,100.1h1.6l1.3,3.8c.1.4.2.9.2.9h0s0-.6.2-.9l1.3-3.8h1.6l-2.2,6.1h-1.8l-2.2-6.1Z"/>
              <path fill={color} d="M280.6,100c1.8,0,2.7,1.3,2.7,2.9s0,.6,0,.6h-4.2c0,1.1.9,1.6,1.8,1.6s1.8-.7,1.8-.7l.6,1.1s-.9.9-2.5.9-3.3-1.5-3.3-3.2,1.3-3.2,3.1-3.2ZM281.8,102.4c0-.8-.5-1.3-1.2-1.3s-1.3.5-1.5,1.3h2.7Z"/>
              <path fill={color} d="M286.9,100c1.3,0,1.8.8,1.8.8h0s0-.2,0-.3v-2.7h1.5v8.5h-1.4v-.5c0-.2,0-.4,0-.4h0s-.5,1-1.9,1-2.7-1.3-2.7-3.2,1.2-3.2,2.8-3.2ZM287.2,105.1c.8,0,1.5-.6,1.5-1.9s-.5-1.9-1.5-1.9-1.5.7-1.5,1.9.6,1.9,1.5,1.9Z"/>
            </g>
          </g>
          <g>
            <rect stroke={colorHighlight} strokeMiterlimit={10} strokeWidth={2} x="7.9" y="76.6" width="110" height="38" rx="11.4" ry="11.4"/>
            <g>
              <path fill={color} d="M14,83h1.5v5.5c0,1.1.7,1.8,1.9,1.8s1.9-.6,1.9-1.8v-5.4h1.5v5.5c0,1.9-1.4,3.2-3.4,3.2s-3.4-1.3-3.4-3.2v-5.5Z"/>
              <path fill={color} d="M22.6,85.4h1.4v.4c0,.2,0,.4,0,.4h0s.5-1,2-1,2.7,1.3,2.7,3.2-1.2,3.2-2.8,3.2-1.8-.8-1.8-.8h0s0,.2,0,.5v2.6h-1.5v-8.5ZM25.6,90.4c.8,0,1.5-.7,1.5-1.9s-.6-1.9-1.5-1.9-1.5.6-1.5,1.9.5,1.9,1.5,1.9Z"/>
              <path fill={color} d="M29.8,83h1.5v6.4c0,.7.2.8.6.8s.2,0,.2,0v1.3s-.2,0-.5,0c-.8,0-1.9-.2-1.9-2v-6.6Z"/>
              <path fill={color} d="M36.2,85.2c1.9,0,3.4,1.3,3.4,3.2s-1.5,3.2-3.4,3.2-3.4-1.3-3.4-3.2,1.5-3.2,3.4-3.2ZM36.3,90.3c1,0,1.8-.8,1.8-1.9s-.8-1.9-1.8-1.9-1.8.8-1.8,1.9.8,1.9,1.8,1.9Z"/>
              <path fill={color} d="M43.9,87.7h.2c0-.9-.5-1.2-1.2-1.2s-1.7.6-1.7.6l-.6-1.1s.9-.8,2.4-.8,2.5.9,2.5,2.4v3.8h-1.4v-.5c0-.2,0-.4,0-.4h0s-.5,1.1-1.9,1.1-2.1-.7-2.1-1.9c0-1.9,2.6-2.1,3.6-2.1ZM42.8,90.5c.8,0,1.4-.9,1.4-1.6h0c0-.1-.3-.1-.3-.1-.8,0-2,.1-2,1s.3.8.9.8Z"/>
              <path fill={color} d="M49.6,85.2c1.3,0,1.8.8,1.8.8h0s0-.2,0-.3v-2.7h1.5v8.5h-1.4v-.5c0-.2,0-.4,0-.4h0s-.5,1-1.9,1-2.7-1.3-2.7-3.2,1.2-3.2,2.8-3.2ZM49.9,90.4c.8,0,1.5-.6,1.5-1.9s-.5-1.9-1.5-1.9-1.5.7-1.5,1.9.6,1.9,1.5,1.9Z"/>
              <path fill={color} d="M57.7,83h3.3c1.6,0,2.7,1.1,2.7,2.8s-1.1,2.8-2.7,2.8h-1.7v2.9h-1.5v-8.5ZM60.7,87.2c.9,0,1.4-.6,1.4-1.5s-.5-1.4-1.4-1.4h-1.5v2.9h1.5Z"/>
              <path fill={color} d="M64.8,83h1.5v6.4c0,.7.2.8.6.8s.2,0,.2,0v1.3s-.2,0-.5,0c-.8,0-1.9-.2-1.9-2v-6.6Z"/>
              <path fill={color} d="M71.4,87.7h.2c0-.9-.5-1.2-1.2-1.2s-1.7.6-1.7.6l-.6-1.1s.9-.8,2.4-.8,2.5.9,2.5,2.4v3.8h-1.4v-.5c0-.2,0-.4,0-.4h0s-.5,1.1-1.9,1.1-2.1-.7-2.1-1.9c0-1.9,2.6-2.1,3.6-2.1ZM70.2,90.5c.8,0,1.4-.9,1.4-1.6h0c0-.1-.3-.1-.3-.1-.8,0-2,.1-2,1s.3.8.9.8Z"/>
              <path fill={color} d="M74.9,86.7h-.8v-1.2h.8v-1.8h1.5v1.8h1.4v1.2h-1.4v2.4c0,1,.8,1.2,1.2,1.2s.3,0,.3,0v1.3s-.2,0-.5,0c-.9,0-2.5-.3-2.5-2.3v-2.6Z"/>
              <path fill={color} d="M81.8,85.2c1.8,0,2.7,1.3,2.7,2.9s0,.6,0,.6h-4.2c0,1.1.9,1.6,1.8,1.6s1.8-.7,1.8-.7l.6,1.1s-.9.9-2.5.9-3.3-1.5-3.3-3.2,1.3-3.2,3.1-3.2ZM83,87.7c0-.8-.5-1.3-1.2-1.3s-1.3.5-1.5,1.3h2.7Z"/>
              <path fill={color} d="M89.4,83h1.7l1.7,4.2c.2.5.4,1.2.4,1.2h0s.2-.7.4-1.2l1.7-4.2h1.7l.7,8.5h-1.5l-.4-4.8c0-.6,0-1.3,0-1.3h0s-.3.8-.5,1.3l-1.4,3.3h-1.4l-1.4-3.3c-.2-.5-.5-1.3-.5-1.3h0s0,.8,0,1.3l-.4,4.8h-1.5l.7-8.5Z"/>
              <path fill={color} d="M102.4,87.7h.2c0-.9-.5-1.2-1.2-1.2s-1.7.6-1.7.6l-.6-1.1s.9-.8,2.4-.8,2.5.9,2.5,2.4v3.8h-1.4v-.5c0-.2,0-.4,0-.4h0s-.5,1.1-1.9,1.1-2.1-.7-2.1-1.9c0-1.9,2.6-2.1,3.6-2.1ZM101.2,90.5c.8,0,1.4-.9,1.4-1.6h0c0-.1-.3-.1-.3-.1-.8,0-2,.1-2,1s.3.8.9.8Z"/>
              <path fill={color} d="M105.7,85.4h1.4v.4c0,.2,0,.4,0,.4h0s.5-1,2-1,2.7,1.3,2.7,3.2-1.2,3.2-2.8,3.2-1.8-.8-1.8-.8h0s0,.2,0,.5v2.6h-1.5v-8.5ZM108.7,90.4c.8,0,1.5-.7,1.5-1.9s-.6-1.9-1.5-1.9-1.5.6-1.5,1.9.5,1.9,1.5,1.9Z"/>
              <path fill={color} d="M15.5,103.7s.9.9,2.1.9,1.2-.3,1.2-1c0-1.5-4-1.3-4-3.9s1.2-2.5,2.9-2.5,2.5.9,2.5.9l-.7,1.3s-.8-.7-1.9-.7-1.3.4-1.3,1c0,1.5,4,1.1,4,3.9s-1,2.5-2.8,2.5-2.9-1.2-2.9-1.2l.8-1.2Z"/>
              <path fill={color} d="M21.7,97.4h1.5v3c0,.3,0,.5,0,.5h0c.3-.6,1-1.2,2.1-1.2s2.1.7,2.1,2.3v3.9h-1.5v-3.6c0-.7-.2-1.2-1-1.2s-1.4.5-1.7,1.3c0,.2,0,.5,0,.8v2.8h-1.5v-8.5Z"/>
              <path fill={color} d="M29,97.4h1.5v1.4h-1.5v-1.4ZM29,99.8h1.5v6.1h-1.5v-6.1Z"/>
              <path fill={color} d="M32.3,99.8h1.4v.4c0,.2,0,.4,0,.4h0s.5-1,2-1,2.7,1.3,2.7,3.2-1.2,3.2-2.8,3.2-1.8-.8-1.8-.8h0s0,.2,0,.5v2.6h-1.5v-8.5ZM35.3,104.8c.8,0,1.5-.7,1.5-1.9s-.6-1.9-1.5-1.9-1.5.6-1.5,1.9.5,1.9,1.5,1.9Z"/>
              <path fill={color} d="M46.5,97.2c2.1,0,3.1,1.2,3.1,1.2l-.7,1.2s-1-.9-2.3-.9-2.9,1.3-2.9,2.9,1.1,3,2.9,3,2.5-1.1,2.5-1.1l.8,1.1s-1.2,1.4-3.3,1.4-4.4-1.9-4.4-4.4,1.9-4.4,4.4-4.4Z"/>
              <path fill={color} d="M54,99.6c1.9,0,3.4,1.3,3.4,3.2s-1.5,3.2-3.4,3.2-3.4-1.3-3.4-3.2,1.5-3.2,3.4-3.2ZM54,104.7c1,0,1.8-.8,1.8-1.9s-.8-1.9-1.8-1.9-1.8.8-1.8,1.9.8,1.9,1.8,1.9Z"/>
              <path fill={color} d="M58.6,99.8h1.5v.7c0,.2,0,.4,0,.4h0c.3-.6,1-1.3,2-1.3s1.5.4,1.8,1.3h0c.3-.7,1.1-1.3,2.1-1.3s2,.7,2,2.3v3.9h-1.5v-3.6c0-.7-.1-1.2-.8-1.2s-1.3.6-1.5,1.4c0,.2,0,.5,0,.8v2.7h-1.5v-3.6c0-.7-.1-1.2-.8-1.2s-1.3.6-1.5,1.4c0,.2,0,.5,0,.8v2.7h-1.5v-6.1Z"/>
              <path fill={color} d="M69.5,99.8h1.4v.4c0,.2,0,.4,0,.4h0s.5-1,2-1,2.7,1.3,2.7,3.2-1.2,3.2-2.8,3.2-1.8-.8-1.8-.8h0s0,.2,0,.5v2.6h-1.5v-8.5ZM72.5,104.8c.8,0,1.5-.7,1.5-1.9s-.6-1.9-1.5-1.9-1.5.6-1.5,1.9.5,1.9,1.5,1.9Z"/>
              <path fill={color} d="M79.8,99.6c1.9,0,3.4,1.3,3.4,3.2s-1.5,3.2-3.4,3.2-3.4-1.3-3.4-3.2,1.5-3.2,3.4-3.2ZM79.8,104.7c1,0,1.8-.8,1.8-1.9s-.8-1.9-1.8-1.9-1.8.8-1.8,1.9.8,1.9,1.8,1.9Z"/>
              <path fill={color} d="M84.3,99.8h1.5v3.6c0,.7.2,1.2.9,1.2,1.1,0,1.7-1,1.7-2.1v-2.8h1.5v6.1h-1.5v-.7c0-.2,0-.4,0-.4h0c-.3.6-1.1,1.3-2.1,1.3s-2.1-.7-2.1-2.3v-3.9Z"/>
              <path fill={color} d="M91.7,99.8h1.5v.7c0,.2,0,.4,0,.4h0c.3-.5.9-1.3,2.2-1.3s2.1.7,2.1,2.3v3.9h-1.5v-3.6c0-.7-.2-1.2-1-1.2s-1.4.5-1.6,1.2c0,.3-.1.5-.1.8v2.8h-1.5v-6.1Z"/>
              <path fill={color} d="M101.4,99.6c1.3,0,1.8.8,1.8.8h0s0-.2,0-.3v-2.7h1.5v8.5h-1.4v-.5c0-.2,0-.4,0-.4h0s-.5,1-1.9,1-2.7-1.3-2.7-3.2,1.2-3.2,2.8-3.2ZM101.7,104.8c.8,0,1.5-.6,1.5-1.9s-.5-1.9-1.5-1.9-1.5.7-1.5,1.9.6,1.9,1.5,1.9Z"/>
              <path fill={color} d="M106.6,104.1s.7.7,1.7.7.8-.2.8-.6c0-.9-3-.8-3-2.8s1-1.8,2.3-1.8,2.1.7,2.1.7l-.6,1.1s-.6-.5-1.5-.5-.8.2-.8.6c0,.9,3,.8,3,2.7s-.9,1.8-2.3,1.8-2.4-.9-2.4-.9l.7-1.1Z"/>
            </g>
          </g>
        </g>
        <g>
          <path fill={color} d="M164,84.1V30.1c0-1.4-1.1-2.5-2.5-2.5s-2.5,1.1-2.5,2.5v54h5Z"/>
          <rect fill={color} x="159" y="105.1" width="5" height="44"/>
          <path fill={color} d="M159,170.1v3.5c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5v-3.5h-5Z"/>
        </g>
        <g>
          <path fill={colorHighlight} d="M192,93l-16.5-9.5c-1.3-.7-2.9.2-2.9,1.6v4.1h-35c-3,0-5.4,2.4-5.4,5.4s2.4,5.4,5.4,5.4h35v4.1c0,1.5,1.6,2.4,2.9,1.6l16.5-9.5c1.3-.7,1.3-2.6,0-3.3Z"/>
          <path fill={colorHighlight} d="M397.1,93l-16.5-9.5c-1.3-.7-2.9.2-2.9,1.6v4.1h-35c-3,0-5.4,2.4-5.4,5.4s2.4,5.4,5.4,5.4h35v4.1c0,1.5,1.6,2.4,2.9,1.6l16.5-9.5c1.3-.7,1.3-2.6,0-3.3Z"/>
          <path fill={colorHighlight} d="M341.1,158.8l16.5-9.5c1.3-.7,2.9.2,2.9,1.6v4.1s35,0,35,0c3,0,5.4,2.4,5.4,5.4s-2.4,5.4-5.4,5.4h-35s0,4.1,0,4.1c0,1.5-1.6,2.4-2.9,1.6l-16.5-9.5c-1.3-.7-1.3-2.6,0-3.3Z"/>
          <path fill={colorHighlight} d="M132.6,158.2l16.5-9.5c1.3-.7,2.9.2,2.9,1.6v4.1s35,0,35,0c3,0,5.4,2.4,5.4,5.4s-2.4,5.4-5.4,5.4h-35s0,4.1,0,4.1c0,1.5-1.6,2.4-2.9,1.6l-16.5-9.5c-1.3-.7-1.3-2.6,0-3.3Z"/>
          <path fill={colorHighlight} d="M564,97.4c18.9,1.8,33.5,13.8,33.5,28.3s-14.6,26.4-33.5,28.3v-4.4c0-1.5-1.6-2.4-2.9-1.6l-16.5,9.5c-1.3.7-1.3,2.6,0,3.3l16.5,9.5c1.3.7,2.9-.2,2.9-1.6v-3.7c.2,0,.5,0,.7,0,24.6-2.3,43.8-19,43.8-39.2s-19.1-38.1-43.8-39.2-15.1,0-15.1,0c-3,0-5.4,2.4-5.4,5.4s2.4,5.4,5.4,5.4h14.4Z"/>
        </g>
        <g>
          <g>
            <path fill={color} d="M433.4,57c-.9-1.5-2.3-2.6-4-3.1l-5.4-1.9c-1-.4-2-1-2.6-1.9-.3-.4-1-.5-1.4-.2s-.5,1-.2,1.4c.2.2.4.4.5.6l-1.2,3.5c-1.6,1.1-3.6,1.7-5.6,1.5-.6,0-1,.3-1.1.9s.3,1,.9,1.1c1.7.2,3.4,0,4.9-.7l-3.3,9.3c-.2.6.2,1.3.9,1.3s.8-.3.9-.7l5.3-14.9c.9.5,1.2.6,6.7,2.5,1.2.4,2.3,1.2,3,2.3.2.3.5.5.8.5.8,0,1.3-.9.8-1.5h0Z"/>
            <path fill={colorHighlight} d="M413.7,34.2h1.1c.4,1.7,2,3,3.9,3s4-1.8,4-4-1.8-4-4-4-3.4,1.3-3.9,3h-1.1c-.4-1.7-2-3-3.9-3s-4,1.8-4,4,1.8,4,4,4,3.4-1.3,3.9-3ZM418.7,31.2c1.1,0,2,.9,2,2s-.9,2-2,2-2-.9-2-2,.9-2,2-2ZM407.9,33.2c0-1.1.9-2,2-2s2,.9,2,2-.9,2-2,2-2-.9-2-2Z"/>
            <path fill={color} d="M408.4,52c1.1-1.1,1.8-2.5,2.1-4.1,8.2,2.5,16.6-3.4,17.2-11.8,1.8-.4,3.2-2.1,3.2-4v-3.5c2.2.5,3.9,2.4,3.9,4.8v11.6h-1.1c-1.2,0-2.4-.3-3.4-1-.5-.3-1.1-.2-1.4.3s-.2,1.1.3,1.4c1.4.8,2.9,1.3,4.5,1.3h2.1c.6,0,1-.4,1-1v-12.6c0-3.4-2.6-6.3-5.9-6.8v-4.2c0-7.5-6-13.5-13.5-13.5h-6c-7.4,0-13.5,6.1-13.5,13.5v9.8c0,.6.4,1,1,1s1-.4,1-1v-9.8c0-6.3,5.2-11.5,11.5-11.5h6c6.3,0,11.5,5.2,11.5,11.5v9.7c0,.8-.5,1.5-1.1,1.9v-7.1c0-.4-.3-.8-.7-1-2.3-.6-4-2.3-4.8-4.5-.2-.6-1-.8-1.5-.4-5.4,4.1-12,5.8-18.5,4.9-.6,0-1.1.4-1.1,1v8.3c0,3.2,1.1,6.3,3.2,8.7.4.4,1,.5,1.4.1.4-.4.5-1,.1-1.4-1.8-2.1-2.8-4.7-2.8-7.4v-7.1c6.2.6,12.5-1,17.8-4.7,1.1,2.1,2.8,3.5,4.9,4.3v7.5c0,8.1-8.3,13.6-15.8,10.5-.8-.3-1.6.4-1.3,1.3-.1,2.3-1.7,4.4-3.9,5.1l-5.4,1.9c-3.1,1.1-5.2,4-5.2,7.3v6.7c0,.6.4,1,1,1s1-.4,1-1v-6.7c0-2.5,1.6-4.7,3.9-5.4l2.5-.9-.3,9.2c0,.3,0,.5.3.7l3.5,3.7c.4.4,1,.4,1.4,0,.4-.4.4-1,0-1.4l-3.2-3.4.3-9.6c.9-.3,1.4-.5,2.2-.9l5.3,14.9c.2.5.8.8,1.3.6.5-.2.8-.8.6-1.3l-5.5-15.5Z"/>
            <path fill={color} d="M425.3,57.6c-.6,0-1,.5-1,1l.2,5.2-3.2,3.4c-.4.4-.4,1,0,1.4.4.4,1,.4,1.4,0l3.5-3.7c.2-.2.3-.4.3-.7l-.2-5.6c0-.6-.5-1-1-1h0Z"/>
            <path fill={colorHighlight} d="M453,60.1l-5.7-7.5v-8.7h.4c.6,0,1-.4,1-1s-.4-1-1-1h-8c-.6,0-1,.4-1,1s.4,1,1,1h5.6v9c0,.2,0,.4.2.6l5.9,7.8c1.8,2.3.1,5.6-2.8,5.6h-10c-2.9,0-4.6-3.3-2.8-5.6l2.5-3.3c2,.8,3.5.7,5.1-.1.5-.3.7-.9.4-1.3-.3-.5-.9-.7-1.3-.4-.7.4-1.5.7-2.9.2l2.2-2.8c.1-.2.2-.4.2-.6v-4.7c0-.6-.4-1-1-1s-1,.4-1,1v4.4l-5.7,7.5c-2.8,3.6-.2,8.8,4.4,8.8h10c4.5,0,7.1-5.2,4.3-8.8h0Z"/>
          </g>
          <g>
            <path fill={colorHighlight} d="M311.6,31.5c.5-.3.6-.9.3-1.4-.3-.5-.9-.6-1.4-.3-.8.6-1.4,1.4-1.7,2.3h-1.1c-.4-1.7-2-3-3.9-3s-4,1.8-4,4,1.8,4,4,4,3.4-1.3,3.9-3h1.1c.4,1.7,2,3,3.9,3s4.3-2.1,4-4.5c0-.6-.6-.9-1.1-.9-.6,0-.9.6-.9,1.1.2,1.2-.8,2.2-2,2.2s-2-.9-2-2c0-.7.3-1.3.9-1.7ZM303.8,35.2c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2Z"/>
            <path fill={color} d="M327.3,57c-.9-1.5-2.3-2.6-4-3.1l-5.4-1.9c-1-.4-2-1-2.6-1.9-.3-.4-1-.5-1.4-.2s-.5,1-.2,1.4c.2.2.4.4.5.6l-1.2,3.5c-1.6,1.2-3.6,1.7-5.6,1.5-.6,0-1,.3-1.1.9s.3,1,.9,1.1c1.7.2,3.3,0,4.9-.6l-3.3,9.2c-.2.5,0,1.1.6,1.3.5.2,1.1,0,1.3-.6l5.3-14.9c.9.5,1.2.6,6.7,2.5,1.2.4,2.3,1.2,3,2.3.3.5.9.6,1.4.3.5-.3.6-.9.3-1.4h0Z"/>
            <path fill={color} d="M302.4,52c1.1-1.1,1.8-2.5,2.1-4.1,8.5,2.6,17.2-3.8,17.2-12.8v-7c0-.6-.5-1.1-1.1-1-6.6.9-13.2-.9-18.5-4.9-.5-.4-1.3-.2-1.5.4-.8,2.2-2.6,3.9-4.8,4.5-.4.1-.7.5-.7,1v5.4c-.7-.4-1.1-1-1.1-1.8v-12.2c0-1.3,1.1-2.4,2.4-2.4s.8-.3.9-.6c1.4-3.4,4.6-5.6,8.3-5.6h8.5c4.9,0,8.9,4,8.9,9v11.5c0,.6.4,1,1,1s1-.4,1-1v-11.4c0-6-4.9-11-10.9-11h-8.5c-4.3,0-8.1,2.4-9.9,6.3-2.1.3-3.7,2.1-3.7,4.3v12.2c0,1.9,1.3,3.5,3.1,4,.1,3.5,1.6,6.9,4.2,9.3.4.4,1,.4,1.4,0,.4-.4.4-1,0-1.4-2.3-2.2-3.6-5.1-3.6-8.3v-6.3c2.1-.8,3.8-2.3,4.9-4.3,5.2,3.6,11.5,5.3,17.8,4.7v5.9c0,8.1-8.3,13.6-15.7,10.5-.7-.3-1.4.2-1.4.9,0,2.5-1.6,4.6-3.9,5.4l-5.4,1.9c-3.1,1.1-5.2,4-5.2,7.3v6.7c0,.6.4,1,1,1s1-.4,1-1v-6.7c0-2.5,1.6-4.7,3.9-5.4l2.5-.9-.3,9.2c0,.3,0,.5.3.7l3.5,3.7c.2.2.5.3.7.3.9,0,1.3-1,.7-1.7l-3.2-3.4.3-9.6c.9-.3,1.4-.5,2.2-.9l5.3,14.9c.1.4.5.7.9.7.7,0,1.2-.7.9-1.3l-5.5-15.5Z"/>
            <path fill={color} d="M319.2,57.6c-.6,0-1,.5-1,1l.2,5.2-3.2,3.4c-.4.4-.4,1,0,1.4.4.4,1,.4,1.4,0l3.5-3.7c.2-.2.3-.4.3-.7l-.2-5.6c0-.6-.5-1-1-1h0Z"/>
            <path fill={colorHighlight} d="M346.9,60.1l-5.7-7.5v-8.7h.4c.6,0,1-.4,1-1s-.4-1-1-1h-8c-.6,0-1,.4-1,1s.4,1,1,1h5.6v9c0,.2,0,.4.2.6l5.9,7.8c1.8,2.3.1,5.6-2.8,5.6h-10c-2.9,0-4.6-3.3-2.8-5.6l2.5-3.3c2,.8,3.5.7,5.1-.1.5-.3.7-.9.4-1.3-.3-.5-.9-.7-1.3-.4-.7.4-1.5.7-2.9.2l2.2-2.8c.1-.2.2-.4.2-.6v-4.7c0-.6-.4-1-1-1s-1,.4-1,1v4.4l-5.7,7.5c-2.8,3.6-.2,8.8,4.4,8.8h10c4.5,0,7.1-5.2,4.3-8.8h0Z"/>
          </g>
          <g>
            <path fill={color} d="M52.9,54.2c.9-.3,1.4-.5,2.2-.9l5.3,14.9c.2.5.8.8,1.3.6.5-.2.8-.8.6-1.3l-5.5-15.6c1.1-1.1,1.8-2.5,2.1-4.1,8.4,2.6,17.2-3.7,17.2-12.7v-8.2c0-3.9-3.1-7-7-7h-12.7c-3.9,0-7,3.1-7,7v8c-1.4-.4-2.5-1.4-2.9-2.8-.4-1.5-.6-3-.6-4.6.2-22.3,33.5-22.2,33.7,0,0,1.6-.2,3.1-.6,4.6-.1.5.2,1.1.7,1.2.5.1,1.1-.2,1.2-.7.4-1.7.7-3.4.6-5.1-.3-24.8-37.5-24.9-37.7,0,0,1.7.2,3.5.6,5.1.6,2.3,2.6,4.1,5,4.4.4,2.5,1.4,4.9,3.1,6.8.4.4,1,.5,1.4.1.4-.4.5-1,.1-1.4-1.8-2.1-2.8-4.7-2.8-7.4v-8.1c0-2.8,2.2-5,5-5h12.7c2.8,0,5,2.2,5,5v8.1c0,8.3-8.5,13.6-15.8,10.5-.8-.3-1.6.4-1.3,1.3-.1,2.3-1.7,4.4-3.9,5.1l-5.4,1.9c-3.2,1.1-5.2,4-5.2,7.3v6.7c0,.6.4,1,1,1s1-.4,1-1v-6.7c0-2.5,1.6-4.7,3.9-5.4h0s-.1,0,2.5-.9l2-.7Z"/>
            <path fill={color} d="M82,57.5l-.2-.4c0,0-.1-.2-.2-.2-.9-1.4-2.3-2.4-3.8-2.9l-5.4-1.9c-1-.4-2-1-2.6-1.9-.3-.4-1-.5-1.4-.2-.4.3-.5,1-.2,1.4.2.2.4.4.5.6l-1.2,3.5c-1.6,1.2-3.6,1.7-5.6,1.5-.6,0-1,.3-1.1.9s.3,1,.9,1.1c1.7.2,3.3,0,4.9-.6l-3.3,9.2c-.2.5,0,1.1.6,1.3.5.2,1.1,0,1.3-.6l5.3-14.9c1.2.7,1.8.7,6.7,2.5,1.2.4,2.3,1.2,3,2.3,0,0,0,0,0,0v.2c.8,1.3,1.1,2.7,1.1,4.1v5.4c0,.6.4,1,1,1s1-.4,1-1v-5.4c0-1.7-.4-3.5-1.2-5.1Z"/>
            <g>
              <path fill={colorHighlight} d="M58,34.1c-1.9,0-3.5-1.6-3.5-3.5s1.6-3.5,3.5-3.5,3.5,1.6,3.5,3.5-1.6,3.5-3.5,3.5ZM58,29.1c-.8,0-1.5.7-1.5,1.5s.7,1.5,1.5,1.5,1.5-.7,1.5-1.5-.7-1.5-1.5-1.5Z"/>
              <path fill={colorHighlight} d="M67,34.1c-1.9,0-3.5-1.6-3.5-3.5s1.6-3.5,3.5-3.5,3.5,1.6,3.5,3.5-1.6,3.5-3.5,3.5ZM67,29.1c-.8,0-1.5.7-1.5,1.5s.7,1.5,1.5,1.5,1.5-.7,1.5-1.5-.7-1.5-1.5-1.5Z"/>
              <path fill={colorHighlight} d="M62.3,41.6c-.6,0-1.3,0-1.8-.2-1.7-.4-2.9-1.4-3-2.6,0-.5.3-1.1.8-1.1.5,0,1.1.3,1.1.8,0,.2.5.7,1.5.9,1.4.4,3.5.2,4.7-1.4.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4-1.3,1.7-3.2,2.3-5,2.3Z"/>
            </g>
          </g>
        </g>
      </svg>
    </MapContainer>
  )
}

export default RequestProcessMap;

const MapContainer = styled.div<{width: number, height: number}>`
  display: flex;
  position: relative;
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  margin: 10px 0;
`;

const NotificationCountBubble = styled.div<{x:number, y:number}>`
  position: absolute;
  font-size: 16px;
  z-index: 1;
  top: ${(p)=>p.y}px;
  left: ${(p)=>p.x}px;
  border-radius: 50%;
  color: ${(props) => props.theme.palette.textPrimary };
  background-color:  ${(props) => props.theme.palette.accentPrimary };
  border: 2px solid ${(p)=>p.theme.palette.backgroundSecondary};
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;