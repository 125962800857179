import React from 'react';
import styled from 'styled-components/macro';

import { useNavigate } from 'react-router-dom';
import {FlexItem, FlexBanner} from '@components/layout/FlexStyles';
import {TextSpace, TextSmall, TextSmallItalics, TextSmallBold} from "@components/elements/TextStyles";
import TextButton from '@components/controls/TextButton';
import MenuButton from '@components/elements/MenuButton';
import TrashIcon from '@components/icons/trash.icon';
import KinomeUniverseIcon from "@components/icons/kinomeUniverse.icon";
import {truncateString} from "@utils/misc";
import CompoundUniverseIcon from "@components/icons/compoundUniverse.icon";
import {UniverseT} from "@components/universe/useUniverses";

const iconSet = {
  'Unique': <KinomeUniverseIcon />,
  'Reference': <CompoundUniverseIcon />
}

interface PropsI {
  universe: UniverseT,
  handleDeleteClick: (id: string) => void
}


const UniverseListItem = (props:PropsI) => {
  const { universe, handleDeleteClick } = props;
  const navigate = useNavigate();

  // HANDLERS
  const _handleDeleteClick = (uuid) => {
    handleDeleteClick(uuid);
  };

  const handleViewUniverse = (universeId) => {
    navigate('/universe/user/' + universeId);
  };

  const getViewResultsButton = (universe) => {
    return(
      <TextButton
        text={'View Universe'}
        icon={<KinomeUniverseIcon />}
        width={150}
        height={28}
        margin={'0'}
        onClick={() => handleViewUniverse(universe?.uuid)}
      />
    )
  }

  const getUniverseDetails = (universe: UniverseT) => {
    return (
      <>
        {universe.details.length} [To be implemented]
      </>
    );
  };

  const title =
    truncateString(universe?.title, 30) +
    ' (Created: ' +
    new Date(universe?.createdOn).toLocaleDateString() +
    ')';

  return (
      <DataSetContainer>
        <FlexBanner>
          <FlexItem width={'400px'}>
            {iconSet['Unique']}
            <TextSpace />
            <TextSmall color={'primary'}>{title}</TextSmall>
          </FlexItem>
          <FlexItem width={'150px'}><TextSmallBold color={'accentSecondary'}>{universe.status}</TextSmallBold></FlexItem>
          <FlexItem> { getViewResultsButton(universe) } </FlexItem>
          <FlexItem width={'200px'}><TextSmallItalics>{getUniverseDetails(universe)}</TextSmallItalics></FlexItem>
          <Actions>
            <MenuButton
                onClick={() => _handleDeleteClick(universe.uuid)}
                isActive={false}
                tooltipPlacement={'bottom'}
                icon={<TrashIcon />}
                margin={'0'}
                text={'Delete Data Set'}
            />
          </Actions>
      </FlexBanner>
      </DataSetContainer>
  );
};

export default UniverseListItem;

const DataSetContainer = styled.div`
  width: calc(100% - 10px);
  display: flex;
  padding: 0 5px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.backgroundTertiary};
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
  margin-bottom: 5px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  width: 50px;
`;
