import React, {useState, useEffect} from "react";
import styled from "styled-components/macro";
import {StripeProductT} from "@business/products/useStripeProducts";
import BasicTable from "@as_core/tables/BasicTable";
import {AddCircle} from "@mui/icons-material";

type FieldsT = {value: string, label: string, width: number, align?: string};
const fields: FieldsT[] = [
  {value: 'addToCart', label: '', width: 50},
  {value: 'name', label: 'Product Name', width: 200, align: 'left'},
  {value: 'metadata.catalogNumber', label: 'Catalog Number', width: 150, align: 'left'},
  {value: 'description', label: 'Description', width: 400, align: 'left'},
  {value: 'showUnit', label: 'Unit', width: 125},
  {value: 'showPrice', label: 'Unit Price', width: 100, align: 'right'},
  {value: 'minOrderPrice', label: 'Minimum Order Price', width: 100, align: 'right'}
]

function getShowPrice(product: StripeProductT) {
  return '$ ' + Number(product?.price.unit_amount/100.0).toLocaleString(undefined, {minimumFractionDigits: 2});
}

function getMinOrderPrice(product: StripeProductT) {
  const cost = Number(product?.price.unit_amount/100.0) * Number(product?.metadata.unitOrderMinSize);
  return '$ ' + cost.toLocaleString(undefined, {minimumFractionDigits: 2});
}

function getShowUnit(product: StripeProductT) {
  if (Number(product.metadata.unitOrderMinSize) === 1) return product.metadata.unitSize;
  return(
    <Column>
      <RowText>{product.metadata.unitSize}</RowText>
      <MinUnitText>Minimum Order: {product.metadata.unitOrderMinSize}</MinUnitText>
    </Column>
  )
}

function getAddToCart(product: StripeProductT, onSelect: (product: StripeProductT) => void) {
  return(
    <Link onClick={()=>onSelect(product)}>
      <AddCircle />
    </Link>
    )

}

type PropsT = {
  products: StripeProductT[],
  onSelect: (product: StripeProductT) => void;
}
const ProductsTable = (props:PropsT) => {
  const [rows, setRows] = useState<StripeProductT[]>([]);
  const {products} = props;

  useEffect(()=>{
    const updatedRows = products.map((r) => (
      { ...r,
        showPrice: getShowPrice(r),
        addToCart: getAddToCart(r, props.onSelect),
        minOrderPrice: getMinOrderPrice(r),
        showUnit: getShowUnit(r)
      }
    ))
    setRows(updatedRows)
  },[products]);

  return(
    <ProductsTableContainer>
      <BasicTable
        fields={fields}
        rows={rows}
        pillRows={false}
      />
    </ProductsTableContainer>
  )
}

export default ProductsTable;

const ProductsTableContainer = styled.div`
  display: flex;
  line-height: 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Link = styled.span`
  cursor: pointer;
  color: ${(p) => p.theme.palette.accentPrimary};
  :hover {
    color: ${(p) => p.theme.palette.textPrimary};
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RowText = styled.div`
  display: flex;
`;

const MinUnitText = styled(RowText)`
  color: ${(p) => p.theme.palette.accentSecondary};
  white-space: nowrap;
  font-style: italic;
`;
