import React, { useState, useEffect, useContext } from 'react';
import PageContainer from '@as_core/pages/PageContainer';

import CompoundUniverse from '@components/universe/CompoundUniverse';
import { PagePanelContainer } from '@components/pages/PageComponents';
import DataLoading from '@as_core/elements/DataLoading';
import useUniverses from '@components/universe/useUniverses';
import {
  abbvieTestSettings,
  cellHealthSettings,
  kinomeSettings,
  zebrafishSettings,
} from '@components/universe/config/universe';
import {SettingsT} from "@components/universe/types";
import { MenuContext } from '@stores/MenuContext';

const UniverseOptions = [
  { value: 'cell_health', label: 'Cell Health' },
  { value: 'zf_tanguay', label: 'Zebrafish' },
  { value: 'kinome', label: 'Kinome' },
  { value: 'abbvie', label: 'AbbVie (full)' },
  { value: 'abbvie_test', label: 'AbbVie (subset)' },
];

const settings: {[key: string] : SettingsT} = {
  cell_health: cellHealthSettings,
  kinome: kinomeSettings,
  zf_tanguay: zebrafishSettings,
  abbvie: abbvieTestSettings,
  abbvie_test: abbvieTestSettings
};

const debug = false;
const myComponentName = 'PgUniverse';
const PgUniverse = () => {
  const { getNetworkLocal, getImagesLocal } = useUniverses();
  // eslint-disable-next-line
  const [data, setData] = useState<any>();
  // eslint-disable-next-line
  const [images, setImages] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedUniverse, setSelectedUniverse] =
    useState<string>('cell_health');
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);

  useEffect(() => {
    if (activeMenuId !== 'compoundUniverse')
      setActiveMenuId('compoundUniverse');
  }, [activeMenuId]);

  const handleUniverseSelect = (v) => {
    setSelectedUniverse(v);
  };

  useEffect(() => {
    (async function () {
      const response = await getNetworkLocal(selectedUniverse);
      if (debug) console.log(myComponentName + ' | response: ', response);
      setData(response?.data);
      setLoading(false);
    })();
  }, [selectedUniverse]);

  useEffect(() => {
    (async function () {
      const response = await getImagesLocal(selectedUniverse);
      if (debug) console.log(myComponentName + ' | response(images): ', response);
      setImages(response?.data);
    })();
  }, [selectedUniverse]);

  if (debug) console.log(data);
  return (
    <PageContainer title={'Compound Universe'} padding={0} scroll={false}>
      <PagePanelContainer>
        {loading ? (
          <DataLoading />
        ) : (
          <CompoundUniverse
            universeSelector={{
              value: selectedUniverse,
              options: UniverseOptions,
              setValue: handleUniverseSelect,
            }}
            data={data}
            images={images}
            settings={settings[selectedUniverse]}
          />
        )}
      </PagePanelContainer>
    </PageContainer>
  );
};

export default PgUniverse;
