import React, { useEffect, useState } from 'react';
import DataBox from '@components/dataAnalytics/DataBox';
import TableDiv from '@components/dataAnalytics/TableDiv';
import { ThemeProvider, createTheme } from '@mui/material';
import PageContainer from '@as_core/pages/PageContainer';
import Filter from '@components/dataAnalytics/Filter';
import ErrorModal from '@components/dataAnalytics/ErrorModal';
import CustomToolbar from '@components/dataAnalytics/CustomToolbar';
import TableFilter from '@components/dataAnalytics/TableFilter';
import CustomSnackbar from '@components/dataAnalytics/CustomSnackbar';
import useCompounds from '@components/compounds/useCompounds';
import { Fab } from '@mui/material';
import SaveComponentsModal from '@components/dataAnalytics/SaveComponentsModal';

import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import EditComponentsModal from '@components/dataAnalytics/EditComponentsModal';
import AggregateAndCalculate from '@components/dataAnalytics/AggregateAndCalculate';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontFamily: 'Museo-Sans, Roboto, Verdana, sans-serif',
          fontWeight: 300,
        },
      },
    },
  },
});

export interface OrderedComponent {
  id: number;
  type: 'filter' | 'table' | 'aggregateAndCalculate' | 'allFilters';
}

interface Column {
  id: string;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
}

interface DataItem {
  _id: Record<string, unknown> | unknown;
  [key: string]: unknown;
}

interface Agginfo {
  selectedSingleColumn: string;
  selectedColumnsForAggregation: string[];
  selectedFunction: string;
  id: string;
}

interface SavedComponent {
  id: number;
  type: 'filter' | 'table' | 'aggregateAndCalculate';
  data?: Record<string, unknown>;
  filter_ids?: number[];
  columns?: {
    id: string;
    label: string;
    numeric: boolean;
    disablePadding: boolean;
  }[];
  relatedFilters?: number[];
  dataAgg?: Record<string, unknown>[];
  agginfo?: Record<string, unknown>[];
}

type DynamicComponent =
  | { id: number; type: 'filter'; data?: DataItem[]; isSaved?: boolean }
  | { id: number; type: 'table'; data: DataItem[]; relatedFilters: string[] }
  | {
      id: number;
      type: 'allFilters';
      relatedFilters: string[];
      data?: DataItem[];
      agginfo?: Agginfo[];
    }
  | {
      id: number;
      type: 'aggregateAndCalculate';
      columns: Column[];
      relatedFilters: string[];
      data?: DataItem[];
      agginfo?: Agginfo[];
    };

const PgDataAnalytics = ({ columns = [] }) => {
  columns = [
    'Compound Name',
    'CASRN',
    'Market Segment',
    'Compound Status',
    'Cell Health Index',
    'SMILES',
  ];
  const {
    createTable,
    fetchFilteredData,
    handleCreateNewFilter,
    deleteFilterData,
    fetchAllData,
    updateFilterData,
    removeFilterFromTable,
    deleteAggregateData,
    deleteTable,
  } = useCompounds();

  const [EditModalOpen, setEditModalOpen] = useState(false);
  const [savedWorkId, setSavedWorkId] = useState<string | null>(null);
  const [isSavedSuccess, setIsSavedSuccess] = useState(false);
  const [isapplied, setIsapplied] = useState(false);
  const [filterIds, setFilterIds] = useState<any[]>([]);
  const [, setIsFilterOpen] = useState(false);
  const [, setIsTableOpen] = useState(false);
  const [data, setData] = useState<{
    title: string;
    date: string;
    numRows: string;
    numColumns: string;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isTableFilterOpen, setIsTableFilterOpen] = useState(false);
  const [dynamicComponents, setDynamicComponents] = useState<
    DynamicComponent[]
  >([]);

  const [filters, setFilters] = useState<any[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>(
    'success'
  );

  const prepareDataForSaving = (
    dataArray: DynamicComponent[]
  ): OrderedComponent[] => {
    const orderedComponents: OrderedComponent[] = dataArray.map((item) => ({
      id: item.id,
      type: item.type,
    }));

    return orderedComponents;
  };

  const dataToSave = prepareDataForSaving(dynamicComponents);

  useEffect(() => {
    let isMounted = true;

    const getData = async () => {
      try {
        const fetchedData = await fetchAllData();
        if (isMounted && fetchedData) {
          fetchedData.title = 'Compound dataset';
          const currentDate = new Date();
          fetchedData.date = currentDate.toISOString();
          fetchedData.numRows = `${fetchedData?.length} rows and`;
          fetchedData.numColumns = `${
            Object.keys(fetchedData[0]).length
          } columns`;
          setData(fetchedData);
        }
      } catch (error) {
        console.error('No data was fetched', error);
      }
    };

    getData();

    return () => {
      isMounted = false;
    };
  }, []);
  const onOpenFilterClick = async () => {
    try {
      const newFilterId = await handleCreateNewFilter();
      setFilterIds((prev) => [...prev, newFilterId]);
      showSnackbar('success', 'New filter created successfully');
      setIsFilterOpen(true);
      setDynamicComponents([
        ...dynamicComponents,
        { id: newFilterId, type: 'filter', isSaved: false },
      ]);
      setFilters((prevFilters) =>
        // Add the new filter to the existing filters
        [
          ...prevFilters,
          {
            filterList: [],
            filterName: 'MyFilter',
            is_temporary: true, //
            columns: ['Column1', 'Column2'],
            filter_uuid: newFilterId,
          },
        ]
      );
    } catch (error) {
      showSnackbar('error', 'Failed to create new filter');
    }
  };

  const showSnackbar = (type: 'success' | 'error', message: string) => {
    setSnackbarType(type);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseAggregateAndCalculate = (id: string) => {
    setDynamicComponents(
      dynamicComponents.filter((component) => component.id?.toString() !== id)
    );

    deleteAggregateData(id);
  };

  const convertColumns = (columnNames: string[]) => {
    return columnNames.map((columnName) => ({
      id: columnName.toLowerCase().replace(/ /g, '_'),
      numeric: columnName === 'Cell Health Index',
      disablePadding: false,
      label: columnName,
    }));
  };

  const handlesaving = async (filter: any, AndOR: any, idFilter: any) => {
    const conditions = filter.filterList;
    const updatedFilters = filters.map((existingFilter) => {
      if (existingFilter.filter_uuid === idFilter) {
        return {
          ...existingFilter,
          filterList: [
            {
              AndOR: AndOR,
              conditions: conditions,
            },
          ],
        };
      } else {
        return existingFilter;
      }
    });

    const updatedFilter = updatedFilters.find(
      (f) => f.filter_uuid === idFilter
    );

    if (updatedFilter) {
      try {
        const filterID = idFilter;
        const dataToSend = {
          filterList: updatedFilter.filterList,
          filterName: updatedFilter.filterName,
          is_temporary: true,
          columns: updatedFilter.columns,
          filter_uuid: updatedFilter.filter_uuid,
        };

        await updateFilterData(filterID, dataToSend);

        setDynamicComponents((prevDynamicComponents) =>
          prevDynamicComponents.map((component) => {
            if (component.type === 'filter' && component.id === idFilter) {
              return { ...component, isSaved: true };
            }
            return component;
          })
        );

        setFilters(updatedFilters);
        showSnackbar('success', 'Filter updated successfully');

        const relatedTableIds = findTableIdsByFilterId(
          idFilter,
          dynamicComponents
        );

        for (const tableId of relatedTableIds) {
          try {
            const updatedTableData = await fetchFilteredData(tableId);
            updateTableDataById(tableId, updatedTableData);
          } catch (error) {
            console.error(
              `Error getting updated data for table ID ${tableId}:`,
              error
            );
            showSnackbar(
              'error',
              `Failed to update data for table ID ${tableId}`
            );
          }
        }

        showSnackbar('success', 'All related tables updated successfully');
      } catch (error) {
        console.error('Error updating filter:', error);
        setError('An error occurred while updating the filter.');
        showSnackbar('error', 'Error updating filter');
      }
    } else {
      console.error('Filter not found with id:', idFilter);
      setError('Filter not found.');
      showSnackbar('error', 'Error: Filter not found');
    }
  };

  function findTableIdsByFilterId(
    filterId: any,
    dynamicComponents: DynamicComponent[]
  ) {
    const relatedTableComponents = dynamicComponents.filter(
      (component) =>
        (component.type === 'table' ||
          component.type === 'aggregateAndCalculate') &&
        component.relatedFilters.includes(filterId)
    );

    const relatedTableIds = relatedTableComponents.map(
      (tableComponent) => tableComponent.id
    );

    return relatedTableIds;
  }
  const updateTableDataById = (tableId: any, newData: any) => {
    setDynamicComponents((prevDynamicComponents) =>
      prevDynamicComponents.map((component) => {
        if (
          (component.type === 'table' ||
            component.type === 'aggregateAndCalculate') &&
          component.id === tableId
        ) {
          return { ...component, data: newData };
        }
        return component;
      })
    );
  };

  const handleAllFiltersClick = () => {
    setIsTableFilterOpen(!isTableFilterOpen);
    setDynamicComponents([
      ...dynamicComponents,
      { id: Date.now(), type: 'allFilters', relatedFilters: [] },
    ]);
  };

  const handleTableClick = async () => {
    setIsTableOpen(true);

    try {
      const tablePayload = {
        filter_ids: filterIds,
      };

      const tableData = await createTable(tablePayload); // Using the separated API call function
      const newTableUuid = tableData.table_uuid;

      const filteredData = await fetchFilteredData(newTableUuid); // Using the separated API call function

      setDynamicComponents((prevDynamicComponents) => [
        ...prevDynamicComponents,
        {
          id: newTableUuid,
          type: 'table',
          data: filteredData,
          relatedFilters: filterIds,
        },
      ]);

      showSnackbar('success', 'Table created and data loaded successfully');
    } catch (error: any) {
      console.error('Error processing request:', error);
      showSnackbar('error', 'Failed to process request');
    }
  };

  const handleCloseFilter = async (filterId: any) => {
    // Remove the filter component from the dynamic components list
    setDynamicComponents(
      dynamicComponents.filter((component) => component.id !== filterId)
    );

    // Remove the filter ID from the filter IDs list
    setFilterIds((currentFilterIds) =>
      currentFilterIds.filter((id) => id !== filterId)
    );

    // Remove the filter ID from the related tables' relatedFilters
    setDynamicComponents((prevDynamicComponents) =>
      prevDynamicComponents.map((component) => {
        if (
          component.type === 'table' ||
          component.type === 'aggregateAndCalculate'
        ) {
          return {
            ...component,
            relatedFilters: component.relatedFilters.filter(
              (id) => id !== filterId
            ),
          };
        }
        return component;
      })
    );
    try {
      await deleteFilterData(filterId);
    } catch (error) {
      console.error('Error deleting filter:', error);
      showSnackbar('error', 'An error occurred while deleting the filter.');
    }

    const relatedTableIds = findTableIdsByFilterId(filterId, dynamicComponents);
    for (const tableUUID of relatedTableIds) {
      try {
        const response = await removeFilterFromTable(tableUUID, filterId);
        if (
          response.data.message === 'Filter removed successfully from table'
        ) {
          // Fetch the updated data for this specific

          const updatedTableData = await fetchFilteredData(
            tableUUID.toString()
          ); // replace with actual function call if different
          updateTableDataById(tableUUID, updatedTableData);
          showSnackbar(
            'success',
            'Filter removed and data updated successfully'
          );
        } else {
          showSnackbar('error', response.data.message);
        }
      } catch (error) {
        console.error(
          `Error updating table ${tableUUID} after filter removal:`,
          error
        );
        showSnackbar(
          'error',
          `Failed to update table ${tableUUID} after filter removal`
        );
      }
    }
  };

  const handleAddAggregateAndCalculate = async () => {
    try {
      const tablePayload = {
        filter_ids: filterIds,
      };

      const tableData = await createTable(tablePayload); // Using the separated API call function
      const newTableUuid = tableData.table_uuid;

      setDynamicComponents((prevDynamicComponents) => [
        ...prevDynamicComponents,
        {
          id: newTableUuid,
          type: 'aggregateAndCalculate',
          columns: convertedColumns,
          relatedFilters: filterIds,
        },
      ]);

      showSnackbar('success', 'Table created and data loaded successfully');
    } catch (error) {
      console.error('Error processing request:', error);
      showSnackbar('error', 'Failed to process request');
    }
  };

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  // Function to toggle the modal's visibility
  const handleToggleSaveModal = () => {
    setIsSaveModalOpen(!isSaveModalOpen);
  };

  const [, setAppliedWork] = useState<any[]>([]);

  const applySavedWork = (savedWorkData: { components: SavedComponent[] }) => {
    // Initialize an array to hold the new dynamic components
    const newDynamicComponents: DynamicComponent[] = [];

    // Iterate over each component in the savedWorkData
    savedWorkData.components.forEach((component) => {
      switch (component.type) {
        case 'filter':
          newDynamicComponents.push({
            id: component.id,
            type: 'filter',
            data: component.data as any,
            isSaved: true,
          });
          setFilterIds((prev) => [...prev, component.id]);
          setFilters((prevFilters) =>
            // Add the new filter to the existing filters
            [
              ...prevFilters,
              {
                filterList: [],
                filterName: 'MyFilter',
                is_temporary: true, //
                columns: ['Column1', 'Column2'],
                filter_uuid: component.id,
              },
            ]
          );

          break;
        case 'table':
          newDynamicComponents.push({
            id: component.id as number,
            type: 'table',
            data: component.data as any,
            relatedFilters: (component.filter_ids || []).map(String),
          });

          break;
        case 'aggregateAndCalculate':
          newDynamicComponents.push({
            id: component.id,
            type: 'aggregateAndCalculate',
            columns: component.columns || [],
            relatedFilters: (component.relatedFilters || []).map(String),
            data: component.dataAgg as DataItem[],
            agginfo: component.agginfo as unknown as Agginfo[],
          });
          break;
        default:
          console.warn(`Unknown component type: ${component.type}`);
      }
      setIsapplied(true);
    });

    // Update the dynamic components state
    setDynamicComponents((currentComponents) => [
      ...currentComponents,
      ...newDynamicComponents,
    ]);
    // Optionally, keep track of applied work
    setAppliedWork((currentAppliedWork) => [
      ...currentAppliedWork,
      ...savedWorkData.components,
    ]);
  };

  const handleCloseTable = (id: any) => {
    setDynamicComponents(
      dynamicComponents.filter((component) => component.id !== id)
    );
    deleteTable(id);
  };

  const handleCloseFilterTable = (id: number) => {
    setDynamicComponents(
      dynamicComponents.filter((component) => component.id !== id)
    );
  };
  const renderDynamicComponent = (component: any, index: any) => {
    switch (component.type) {
      case 'filter':
        return (
          <Filter
            key={component.id}
            columns={convertedColumns}
            handleClose={() => handleCloseFilter(component.id)}
            onSaved={handlesaving}
            datalist={filters}
            id={component.id}
            data={component.data}
            saved={component.isSaved}
          />
        );
      case 'aggregateAndCalculate':
        return (
          <AggregateAndCalculate
            key={component.id}
            columns={convertedColumns}
            handleClose={() => handleCloseAggregateAndCalculate(component.id)}
            id={component.id}
            relatedFilters={component.relatedFilters}
            dynamicComponents={dynamicComponents}
            dataa={component.data}
            agginfo={component.agginfo}
          />
        );

      case 'allFilters':
        if (!isapplied) {
          return (
            <TableFilter
              key={index}
              handleClose={() => handleCloseFilterTable(component.id)}
              id={component.id}
              onApply={applySavedWork}
            />
          );
        } else {
          return null;
        }
      case 'table':
        return component?.data && component?.data?.length > 0 ? (
          <TableDiv
            key={component.id}
            data={component.data}
            columns={convertedColumns}
            handleClose={() => handleCloseTable(component.id)}
            id={component.id}
          />
        ) : (
          <div
            style={{ textAlign: 'center', marginTop: '20px', color: 'white' }}
          >
            No Result Found
          </div>
        );
      default:
        return null;
    }
  };

  const handleEditModel = () => {
    setEditModalOpen(!EditModalOpen);
  };

  const handleToggleEdit = () => {
    setEditModalOpen(!EditModalOpen);
  };

  const convertedColumns = convertColumns(columns);

  return (
    <ThemeProvider theme={theme}>
      <PageContainer title='Upload Data'>
        <DataBox data={data} />
        {dynamicComponents.map(renderDynamicComponent)}
        {dynamicComponents.length > 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '-10px 0',
            }}
          >
            {!isSavedSuccess ? (
              <>
                <Fab
                  variant='extended'
                  size='medium'
                  sx={{
                    margin: theme.spacing(1),
                    backgroundColor: '#d60b51',
                    color: theme.palette.getContrastText('rgb(34, 38, 65)'),
                    '&:hover': {
                      backgroundColor: '#d60b51',
                    },
                    textTransform: 'none',
                  }}
                  onClick={handleToggleSaveModal}
                >
                  <SaveIcon sx={{ mr: 1 }} />
                  Save All Work
                </Fab>
                <SaveComponentsModal
                  open={isSaveModalOpen}
                  onClose={handleToggleSaveModal}
                  components={dataToSave}
                  onSaveSuccess={(data: any) => {
                    setIsSavedSuccess(true);

                    setSavedWorkId(data.document_id);
                  }}
                />
              </>
            ) : (
              <>
                {' '}
                <Fab
                  variant='extended'
                  size='medium'
                  sx={{
                    margin: theme.spacing(1),
                    backgroundColor: 'green', // You can choose a different color
                    color: theme.palette.getContrastText('rgb(34, 38, 65)'),
                    '&:hover': {
                      backgroundColor: 'lightgreen', // You can choose a different color for hover
                    },
                    textTransform: 'none',
                  }}
                  onClick={handleEditModel} // You should define handleEditModel function
                >
                  <EditIcon sx={{ mr: 1 }} /> {/* Import EditIcon at the top */}
                  Edit Model
                </Fab>
                <EditComponentsModal
                  open={EditModalOpen}
                  onClose={handleToggleEdit}
                  components={dataToSave}
                  workId={savedWorkId}
                  onSaveSuccess={() => {
                    setIsSavedSuccess(true);
                    console.log('isSavedSuccess', isSavedSuccess);
                  }}
                />
              </>
            )}
          </div>
        )}

        <CustomToolbar
          onFilterClick={onOpenFilterClick}
          onTableClick={handleTableClick}
          onAllFiltersClick={handleAllFiltersClick}
          onAddAggregateAndCalculate={handleAddAggregateAndCalculate}
          hasDynamicComponents={dynamicComponents.length > 0}
        />

        <CustomSnackbar
          open={snackbarOpen}
          type={snackbarType}
          message={snackbarMessage}
          handleClose={() => setSnackbarOpen(false)}
        />

        {error && (
          <ErrorModal
            message={error}
            open={true}
            onClose={() => setError(null)}
          />
        )}
      </PageContainer>
    </ThemeProvider>
  );
};

export default PgDataAnalytics;
