import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';
import { UserDataContainer } from '@pages/pages_styles/userData_style';
import { FlexRow, FlexItem, FlexSpacer } from '@components/layout/FlexStyles';
import TextButton from '@components/controls/TextButton';
import { TextSmallBold } from '@components/elements/TextStyles';
import RadioButtonSelector from '@pages/shared/RadioButtonSelector';
import Alert from '@components/elements/Alert';
import { Data, Universe } from '@utils/api';
import { ComputeUniversePropsT, CompoundUniverseT } from './types';
import useCognito from '@as_core/account/useCognito';
import InputField from '@as_core/components/inputs/InputField';
import Spinner from '@components/elements/Spinner';


const options = [
  { value: '%', label: '% Response (100%-Active)' },
  { value: '%_inverse', label: '% Response (0%-Active)' },
  { value: 'p_value', label: 'pIC50 -log([IC,ED]50)' },
];

const debug: boolean = process.env.REACT_APP_DEBUG === 'dev';
const debugComponentName = 'ComputeUniverse';
const ComputeUniverse = (props: ComputeUniversePropsT) => {
  const { dataSet, handleNext, updateStatus } = props;
  if (debug) console.log(debugComponentName + ' | props:', props);
  const [title, setTitle] = useState<string>('My Kinome Universe');
  const [isLoading, setIsLoading] = useState(false);

  const [results, setResults] = useState<CompoundUniverseT | null>(null);
  const [responseType, setResponseType] = useState<string>(options[0].value);
  if (debug) console.log(debugComponentName + ' | dataSet', dataSet);
  const { getToken } = useCognito();

  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const handleChange = (event) => {
    if (debug) console.log('ComputeUniverse | handleChange', event.target.value);
    setResponseType(event.target.value);
  };

  const createUniverse = async (
    title,
    universeType,
    responseType,
    dataSet,
    universeId
  ) => {
    const token = getToken();
    const payload = {
      uuid: universeId,
      response_type: responseType,
      title: title,
      data_uuid: dataSet.uuid,
      data: dataSet.data,
    };
    await Universe.create(token, 'kinome', payload).then((res) => {
      if (debug) console.log('useTargetPanelAPI:createUniverse | res:', res);
      if (res.status === 200) {
        const result = res.data;
        if (debug) console.log('createUniverse | result:', result);
        if (result?.errors.length) {
          if (debug) console.log(
            'createUniverse ' + result?.errors.length + ' API ERRORS:',
            result?.errors
          );
          setAlertMessage('Errors: ' + result.data?.errors.join(', '));
          setAlertOpen(true);
        } else {
          updateStatus('Universe Calculation: ' + result.data?.status);
          setResults(result.data);
        }
      }
    });
  };

  const uploadImportTargetPanelData = async (universeId, dataSet) => {
    debug &&
      console.log(
        'UploadImportTargetPanel | uploadImportTargetPanelData | dataSet:',
        dataSet
      );
    const token = getToken();
    const payload = dataSet;
    payload.universeId = universeId;
    payload.computationStatus = 'Pending';
    payload.data = encodeURIComponent(JSON.stringify(dataSet.data));
    if (debug) console.log('UploadImportTargetPanel | payload:', payload);
    // upload data
    await Data.create(token, payload).then();
  };

  const handleSubmit = async () => {
    setIsLoading(true); // Start loading
    try {
      if (debug) console.log(debugComponentName + ' | handleSubmit');
      const universeId = uuidv4();
      updateStatus('Submitting Universe Calculation');
      await createUniverse(title, 'kinome', responseType, dataSet, universeId);
      updateStatus('Saving Data Set');
      await uploadImportTargetPanelData(universeId, dataSet);
      handleNext();
    } catch (error) {
      console.error('Error in handleSubmit:', error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };
  if (debug) console.log('results: ', results);

  return (
    <UserDataContainer key={'Submit Universe Parameters'} vertical>
      {/* Render the LoadingSpinner when isLoading is true */}
      {isLoading && <Spinner />}

      {/* Render the rest of the content when not loading */}
      {!isLoading && (
        <>
          <ParametersContainer>
            <FlexRow width={'100%'} h_centered>
              <FlexItem width='50%'>
                <InputField
                  size='small'
                  value={title}
                  label='Set Universe Name'
                  onChange={(event) => setTitle(event.target.value)}
                />
              </FlexItem>
            </FlexRow>
            <FlexRow>
              <FlexItem>
                <TextSmallBold>Select Response Type:</TextSmallBold>
              </FlexItem>
            </FlexRow>
            <FlexRow>
              <FlexSpacer width={100} />
              <FlexItem>
                <RadioButtonSelector
                  selected={responseType}
                  options={options}
                  onChange={handleChange}
                />
              </FlexItem>
            </FlexRow>
            <FlexRow height={'20px'} />
          </ParametersContainer>
          <FlexRow width={'100%'} h_centered>
            <TextButton
              text='Submit Universe Graph Calculation'
              height={30}
              width={250}
              onClick={handleSubmit}
            />
          </FlexRow>
          <Alert
            type={'general'}
            title={'Curve Fit Issue'}
            message={alertMessage}
            alertOpen={alertOpen}
            closeAlert={() => setAlertOpen(false)}
          />
        </>
      )}
    </UserDataContainer>
  );

};

export default ComputeUniverse;

const ParametersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: max-content;
  width: max-content;
  margin-bottom: 25px;
  padding: 5px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
`;
