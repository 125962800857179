import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import FieldImportTable from './fieldImportTable/FieldImportTable';
import ImportReview from './importReviewTable/ImportReview';
import UploadDataStepper from '../shared/UploadDataStepper';
import UploadCompounds from './UploadCompounds';
import useFileImport from '../useFileImport';
import { CompoundT } from '../../../../type';
import useWindowDimensions from '@as_core/hooks/useWindowDimensions';
import Alert from '@components/elements/Alert';


const steps = [
  'Upload Spreadsheet',
  'Choose Identity',
  'Match Columns',
  'Review Results',
  'Save',
];

const debug: boolean = process.env.REACT_APP_HOST_TYPE === 'dev';
interface ImportCompoundSetProps {
  handleReset: () => void;
}

const ImportCompoundSet = (props: ImportCompoundSetProps) => {
  const { handleReset } = props;
  const [compoundSet, setCompoundSet] = useState<CompoundT[]>([]);
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const height = windowHeight - 100;
  const width = windowWidth - 200;
  const { context, removeIdentityColumns, hasSelectedIdentity } = useFileImport();
  debug &&
    console.log('ImportCompoundSet: {step, height, width}', context.activeStep);
  debug &&
    console.log('ImportCompoundSet | context.workbook: ', context.workbook);
  debug &&
    console.log('ImportCompoundSet | context.columns:  ', context.columns);

  // ###########################################################################
  // STEPPER
  // ###########################################################################
  const handleNext = (isSelect = true) => {
    if (context.activeStep === 1 && !hasSelectedIdentity() && !isSelect) {
      <Alert
        type={'general'}
        title={'Alert ID'}
        message={'Please select at least one identity column.'}
        alertOpen={true}
      />
      return;
    }
    context.setActiveStep((currStep) => currStep + 1);
  };

  const handleBack = () => {
    context.setActiveStep((currStep) => {
      const nextStep = currStep - 1;
      if (nextStep === 1) {
        removeIdentityColumns();
      }
      return nextStep;
    });
  };

  if (context.activeStep < 1) {
    handleReset();
  }

  if (debug) console.log('ImportCompoundSet | compoundSet', compoundSet);

  const usedComponent = useCallback(() => {
    switch (context.activeStep) {
      case 1:
        return (
          <FieldImportTable
            width={width}
            height={height}
            fieldFilter='Identity'
            incrementStep={handleNext}
            incrementAfterMapping={true}
          />
        );
      case 2:
        return (
          <FieldImportTable
            width={width}
            height={height}
            fieldFilter='all'
            incrementStep={handleNext}
          />
        );
      case 3:
        return (
          <ImportReview
            height={height}
            width={width}
            compoundSet={compoundSet}
            setCompoundSet={setCompoundSet}
            handleNext={handleNext}
            showUpload
          />
        );
      case 4:
        return (
          <UploadCompounds
            height={height}
            width={width}
            compoundSet={compoundSet}
            handleNext={handleNext}
          />
        );
      case 5:
        return (
          <UploadCompounds
            height={height}
            width={width}
            compoundSet={compoundSet}
            handleNext={handleNext}
          />
        );
      default:
        return <div />;
    }
  }, [context.activeStep]);

  return (
    <Container height={height} width={width}>
      <UploadDataStepper
        handleBack={handleBack}
        handleReset={handleReset}
        handleNext={handleNext}
        uploadStep={3}
        hideNextSteps={[]}
        steps={steps}
      />
      <StepContainer>{usedComponent()}</StepContainer>
    </Container>
  );
};

export default ImportCompoundSet;

const Container = styled.div<{ height?: number; width?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StepContainer = styled.div`
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  min-width: 600px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  background: ${(p) => p.theme.palette.backgroundSecondary};
`;
