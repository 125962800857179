import React, {useContext} from "react";

import { useParams } from 'react-router-dom';
import PageContainer from '@as_core/pages/PageContainer';
import CompoundView from '../components/compounds/CompoundView';
import {MenuContext} from "@stores/MenuContext";

const PgFavoritesView = () => {
  const { favId, view } = useParams<{
    favId: string;
    view?: string;
  }>();
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  if (activeMenuId !== 'favorites') setActiveMenuId('favorites');

  // I fixed everything but the only problem was with libraryId being 'favorites' - it triggered the alert error modal.
  // switched to user fixed the last error alert

  return (
    <PageContainer title='Favorites View' >
      <CompoundView matchId={favId} libraryId='user' view={view} />
    </PageContainer>
  );
};

export default PgFavoritesView;
