import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import PageContainer from "@as_core/pages/PageContainer";
import { MenuContext } from '@stores/MenuContext';
import {getStatusCounts} from './utils';
import {UserContext} from "@stores/UserContext";
import useRequests, {RequestT} from "@subApps/orders/hooks/useRequests";
import RequestProcessMap from "@subApps/orders/components/Assays/RequestProcessMap";
import RequestDetails from "@subApps/orders/components/Common/RequestDetails";
import {FlexRow} from "@as_core/elements/flexStyles";
import {ListLoader} from "@as_core/elements/listStyles";
import ErrorMessages, {MessageItem} from "@as_core/elements/UserMessages";
import styled from "styled-components/macro";
import MenuButton from "@components/elements/MenuButton";
import BackArrowIcon from "@components/icons/backArrow.icon";

type PropsT = {
  request?: RequestT;
}

const debug = false;
const PgRequests = (props:PropsT) => {
  const { request=null } = props;
  const { id=null } = useParams(); // this is the action that sets what to do
  console.log('PgRequests | request', request, 'id', id);
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  const { user, setUser} = useContext(UserContext);
  const { getRequestsByIds } = useRequests();
  const navigate = useNavigate();
  const [currentRequest, setCurrentRequest] = useState<RequestT>(request);
  const [ counts, setCounts ] = useState<{ [key:string]: number }>({});
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [requestsUpdated, setRequestsUpdated] = useState<boolean>(false);


  if (user.subApp !== 'Orders') {
    setUser((prev) => ({
      ...prev, subApp: 'Orders'
    }));
  }
  // make sure menu is correct for the subApp
  if (activeMenuId !== 'orders_requests') setActiveMenuId('orders_requests');

  useEffect(() => {
    if (!isLoading) {
      if (id === null && request !== null) {
        setCurrentRequest(request);
        setLoaded(true);
      } else {
        setIsLoading(true);
        getRequestsByIds([Number(id)]).then((requests => {
          if (debug) console.log('useEffect | requests: ', requests);
          setCounts(getStatusCounts(requests));
          // aggregate them by status
          setCurrentRequest(requests[0]);
          setLoaded(true);
          setIsLoading(false);
        }));
      }
    }
  }, [id, requestsUpdated]);

  if (debug) console.log("PgRequests | id:", id);

  return (
    <PageContainer title='Update/View Request' padding={0}>
      { loaded && !isLoading && currentRequest !== null ?
        <RequestContainer>
          <MenuButton
            onClick={()=>navigate('/orders/requests')}
            icon={<BackArrowIcon />}
            text={'Back to Requests List'}
            tooltipPlacement={'bottom'}
          />
          <RequestProcessMap
            counts={counts}
          />
          <RequestDetails
            request={currentRequest}
            updateRequest={setCurrentRequest}
            setUpdatedRequestForms={()=>setRequestsUpdated(prevState => !prevState)}
            viewBack={false}
          />
        </RequestContainer>
        : isLoading ?
          <FlexRow width={'100%'} height={'200px'} v_centered h_centered>
            <ListLoader />
          </FlexRow>
          :
          <ErrorMessages messages={[
            <MessageItem key='error_requests'>You do not have any requests</MessageItem>
          ]}/>
      }
    </PageContainer>
  );
};

export default PgRequests;

const RequestContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
