import React from 'react';
import { Stack } from '@mui/material';
import { VariableSelect } from '../../controls/VariableSelect';
import { ScatterBase, PlotConfig } from '@plotting/single-plot-view/plot.types';

interface ScatterModalEditorProps {
  plot?: PlotConfig<ScatterBase>;
  onSave?: (config: PlotConfig<ScatterBase>) => void;
}

export const ScatterModalEditor = ({
  plot,
  onSave,
}: ScatterModalEditorProps) => {

  const updateXColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: { ...plot.config, xColumn: columnName },
    };
    onSave(newPlotConfig);
  };

  const updateYColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: { ...plot.config, yColumn: columnName },
    };
    onSave(newPlotConfig);
  };

  const updateGroupColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: { ...plot.config, groupByColumn: columnName },
    };
    onSave(newPlotConfig);
  };

  return (
    <Stack spacing={2} marginTop={4} maxWidth={400}>
      <VariableSelect
        id='variable-value'
        label={'X Axis Variable'}
        value={plot.config.xColumn}
        options={plot.columns}
        onChange={updateXColumn}
      />
      <VariableSelect
        id='variable-value'
        label={'Y Axis Variable'}
        value={plot.config.yColumn}
        options={plot.columns}
        onChange={updateYColumn}
      />
      <VariableSelect
        id='variable-group'
        label={'Group By'}
        value={plot.config.groupByColumn}
        options={plot.columns}
        onChange={updateGroupColumn}
      />
    </Stack>
  );
};
