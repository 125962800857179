import { IParameters } from '../PgOurBiology';

const parameters: IParameters = {
  image: '/img/zebra-fish/zf_normal.png',
  parameterMeasured: [
    'Mortality 24 hpf',
    'Delayed Development 24 hpf',
    'Abnormal Spontaneous Movement 24 hpf',
    'Mortality at 120 hpf',
    'Craniofacial',
    'Axis',
    'Edema',
    'Muscle',
    'Lower Trunk',
    'Brain',
    'Skin',
    'Notochord',
    'Touch Response',
    'Embryonic and Larval Photomotor Response',
  ],
  features: [
    {
      feature: 'Test ID:',
      devTox: 'DevTox',
      acuteTox: 'Acute Tox',
    },
    {
      feature: 'Test species:',
      devTox: 'Zebrafish strain: Tropical 5D',
      acuteTox: 'Zebrafish strain: Tropical 5D',
    },
    {
      feature: 'Life stage:',
      devTox: 'Embryo: 6 – 120 hpf',
      acuteTox: 'Embryo: 6 – 96 hpf',
    },
    {
      feature: 'Test ID:',
      devTox: 'DevTox',
      acuteTox: 'Acute Tox',
    },
    {
      feature: 'Test species:',
      devTox: 'Zebrafish strain: Tropical 5D',
      acuteTox: 'Zebrafish strain: Tropical 5D',
    },
    {
      feature: 'Life stage:',
      devTox: 'Embryo: 6 – 120 hpf',
      acuteTox: 'Embryo: 6 – 96 hpf',
    },
    {
      feature: 'Test ID:',
      devTox: 'DevTox',
      acuteTox: 'Acute Tox',
    },
    {
      feature: 'Test species:',
      devTox: 'Zebrafish strain: Tropical 5D',
      acuteTox: 'Zebrafish strain: Tropical 5D',
    },
    {
      feature: 'Life stage:',
      devTox: 'Embryo: 6 – 120 hpf',
      acuteTox: 'Embryo: 6 – 96 hpf',
    },
    {
      feature: 'Test ID:',
      devTox: 'DevTox',
      acuteTox: 'Acute Tox',
    },
    {
      feature: 'Test species:',
      devTox: 'Zebrafish strain: Tropical 5D',
      acuteTox: 'Zebrafish strain: Tropical 5D',
    },
    {
      feature: 'Life stage:',
      devTox: 'Embryo: 6 – 120 hpf',
      acuteTox: 'Embryo: 6 – 96 hpf',
    },
  ],
  readoutMeasurement: {
    description:
      'Historically, data analysis methods used for zebrafish have been EC50/LC50 (concentration at half maximal effect/lethal concentration) and LOAEL (lowest-observed-adverse-effect level) strategies. The LOAEL approach is primarily used but may or may not be derived using a statistical test. Additionally, other shortcomings include, (1) lack of consideration of concentration-response trend, (2) limiting LOAEL options to tested concentrations, (3) inability to provide uncertainty factors for the reported potency. The benchmark concentration (BMC) analysis is now utilized to address these limitations. The BMC approach involves dose-response modeling to obtain BMD10 (ie: dose level corresponding to achieving a 10% benchmark response (BMR) value). The BMR is defined as the lowest response threshold at which the variance in potency estimation is sufficiently reduced, while BMC is the concentration that the response is observed.',
    subtitle:
      'Further information on the calculation of the BMC10 can be found here:',
    urls: [
      'https://academic.oup.com/toxsci/article/167/1/92/5129061',
      'https://www.epa.gov/sites/default/files/2015-01/documents/benchmark_dose_guidance.pdf',
    ],
  },
};

export const biologyTabContent = [
  {
    title: 'SYSTEMETRIC® Cell Health Screen',
    slug: 'systemetric',
    description:
      'The SYSTEMETRIC® Cell Health Screen is an Acute Cellular Stress Screen, using Flow Cytometry, that measures 12 parameters of acute cellular stress individually  in  approximately  10  thousand  cells  for  each  of  10  concentrations  of  compound.  Using  proprietary  algorithms,  the  resulting  data  is automatically converted into a digital biological fingerprint representing the multiparametric cellular stress response generated by a new compound. Using Machine Learning, this fingerprint is then compared to a large training set of 300 fingerprints from known toxins, and withdrawn, discontinued and  on-market  drugs.  Based  on  similarity  to  fingerprints  in  the  training  set,  a  risk  probability  is  generated,  called  the  Cell  Health  Index  (CHI), representing the potential safety risk for a new compound. The results can be viewed in 3RnD and compared to a library of over 3,000 compounds. A detailed description of the approach is available under the menu selection called “Our Biology”,',
    subsections: [
      {
        text: 'Summary',
        slug: 'summary',
      },
      {
        text: 'Measured Parameters',
        slug: 'measured-parameters',
      },
      {
        text: 'Our Cell Choice',
        slug: 'our-cell-choice',
      },
      {
        text: 'Detailed Method Description',
        slug: 'details-method-description',
      },
      {
        text: 'Data Analysis',
        slug: 'data-analysis',
      },
    ],
    parameters,
  },
  {
    title: 'ZBEscreen™ Zebrafish Screen',
    slug: 'zbescreen',
    description:
      'The ZBEScreen is a Zebrafish screen designed to identify potential Developmental Toxicity risk by measuring overt toxicity responses in a 13-endpoint battery that encompasses zebrafish development from 24 hpf to 120 hpf. The morphological endpoints measured are part of the different organ systems and processes that are readily visible under a dissecting microscope.  The assay is conducted in two phases – the first is to define the maximal tolerated concentration, which informs the 2nd phase to improve the accuracy of predicting the benchmark dose, or BMD10 - the dose at the 95% lower confidence limit on a 10% response. Detailed information on the screen and measured parameters are found',
    subsections: [
      {
        text: 'Approach',
        slug: 'approach',
      },
      {
        text: 'Normal',
        slug: 'normal',
      },
      {
        text: 'Groups of Reported Parameters',
        slug: 'groups-of-reported-parameters',
      },
      {
        text: 'Additional Measurements',
        slug: 'additional-measurements',
      },
    ],
    parameters,
  },
  {
    title: 'ZBEscreen-236™ OECD FET Screen',
    slug: 'zbescreen-236',
    description:
      'The ZBEScreen-236 is a zebrafish embryo acute toxicity test designed to meet the requirements outlined in OECD test guideline 236. Acute or lethal toxicity risk in zebrafish embryos is assessed by measuring overt toxicity responses in a 4-endpoint screen that encompasses coagulation of fertilized embryo, lack of somite formation, lack of detachment of the tail bud from the yolk sac, and lack of a heartbeat. Newly fertilized zebrafish eggs are exposed to a test chemical for 96 hours, with the chemical applied at each of 5 concentration levels every 24 hours. The 4-endpoints are measured and an LC50 calculate',
    subsections: [
      {
        text: 'Approach',
        slug: 'approach',
      },
      {
        text: 'Reported Parameters',
        slug: 'reported-parameters',
      },
    ],
    parameters,
  },
  {
    title: 'Biomimetic Screens',
    slug: 'biomimetic-screens',
    subsections: [
      {
        text: 'Overview',
        slug: 'overview',
      },
      {
        text: 'Measured Parameters',
        slug: 'measured-parameters',
      },
      {
        text: 'References',
        slug: 'references',
      },
    ],
  },

  // TO DO: Update when you have the right design/content
  // {
  //   title: 'ZBEscreen-236 OECD FET Screen',
  //   slug: 'zbescreen-236',
  //   subsections: []
  // }
];

export const BiologyContent = {
  systemetric: {
    summary: [
      { content_type: 'title', text: 'Summary' },
      {
        content_type: 'image',
        src: '/img/our-biology/CellPlasmaMembraneNormal.svg',
        width: 425,
      },
      {
        content_type: 'paragraph',
        text:
          'The SYSTEMETRIC® Cell Health Screen is a live cell,' +
          ' multiparametric acute cellular stress assay designed to identify acute toxicity risk' +
          ' for compounds earlier in R&D.' +
          ' The platform has been designed to facilitate the prioritization of safer compounds' +
          ' and has demonstrated benefits as an SAR tool for chemists to assess the impact of' +
          ' modifications to a chemical series. The screen is designed to detect acute risk' +
          ' for the parent compound only and has been extensively validated to demonstrate' +
          ' high reproducibility, specificity and positive predictive value (PPV).',
      },
      {
        content_type: 'paragraph',
        text:
          ' The screen measures the effect of a compound on ' +
          ' multiple parameters of cellular stress' +
          ' using a panel of fluorescent physiological reporting dyes, on an automated flow' +
          ' cytometry platform. As the screen was designed as an early indicator of safety risk,' +
          ' specificity was a major design goal, thus compounds have a relatively short incubation' +
          ' for 4 hours, ensuring only acute cellular stress responses are detected. 10 concentrations' +
          ' ranging from 100 uM down to 5 nM are incubated with HL60 cells for 4 hours, with' +
          ' approximately 104 individual cells per concentration then analyzed using flow cytometry.' +
          ' This generates a feature rich biological fingerprint representing the change in the' +
          ' heterogeneity of the cellular population across the ten concentrations of compound' +
          ' for all 12 parameters measured. In an industry first, a proprietary gate-free' +
          ' automated analysis of the flow cytometry output is generated to produce a' +
          ' digitized fingerprint of the cellular effect, generating a novel biological' +
          ' fingerprint for each new compound screened.',
      },
      {
        content_type: 'paragraph',
        text:
          'A proprietary machine learning (ML) algorithm then uses these ' +
          ' fingerprints to provide' +
          ' a color coded safety risk probability of a compound, scored on a scale from “0” to “1”' +
          ' with increasing probability of safety risk. In a process similar to facial recognition,' +
          ' fingerprints that are generated for client compounds are compared to a training set of' +
          ' 300 compound fingerprints representing the full range of possible phenotypes in the' +
          ' Cell Health Screen, from no response to acute stress, with sufficient representation' +
          ' across the spectrum. The ML classifier has been trained with 300 known compounds comprised' +
          ' of on-market and withdrawn drugs, known mitochondrial toxins and research compounds.' +
          ' Training set classification was based upon detailed, manually curated information from' +
          ' research literature and, where applicable, clinical trial results and commercial/regulatory' +
          ' histories. Based on similarity of fingerprint clustering within the training set, an' +
          ' accurate risk probability can be assigned for any new compound run through the' +
          ' AsedaSciences screen, allowing for improved scaffold prioritization and assessment of' +
          ' toxicity risk introduced by modifications within and across multiple SAR series.' +
          ' This screen has shown fidelity down to a single side chain across compound series,' +
          ' making the platform a valuable SAR tool for chemists to have in their design toolkit.' +
          ' (See Detailed Method Section Below)',
      },
    ],
    'measured-parameters': [
      { content_type: 'title', text: 'Measured Parameters' },
      {
        content_type: 'paragraph',
        text:
          'Mitochondrial Membrane Potential (MMP), Reactive Oxygen ' +
          ' Species (ROS),' +
          ' mbbr (GSH), Cytoplasmic Membrane Integrity (CMI), Nuclear Membrane Integrity (NMI),' +
          ' Cell Cycle (CC), Morphology (Morph)',
      },
      { content_type: 'line' },
      {
        content_type: 'image',
        src: '/img/our-biology/CellMitochondria.svg',
        width: 200,
      },
      {
        content_type: 'subtitle',
        text: 'MITOCHONDRIAL MEMBRANE POTENTIAL (MMP)',
      },
      {
        content_type: 'paragraph',
        text:
          'Mitochondria change their polarization state in response' +
          ' to a number of cell' +
          ' stresses. This screen uses a ratiometric mitochondrial membrane potential probe,' +
          ' JC-9, which exhibits green fluorescence that is independent of membrane' +
          ' potential and red fluorescence that is dependent upon polarization state.',
      },
      { content_type: 'subtitle', text: 'REACTIVE OXYGEN SPECIES (ROS)' },
      {
        content_type: 'paragraph',
        text:
          'This screen measures mitochondrial Reactive Oxygen ' +
          ' Species (ROS) (mostly' +
          ' superoxide) that reacts with the indicator dye MitoSOX™ Red. Increasing' +
          ' levels of mitochondrial ROS generation caused by a compound will cause' +
          ' an increase in the fluorescence signal of this dye.',
      },
      { content_type: 'line' },
      {
        content_type: 'image',
        src: '/img/our-biology/CellCytoplasm.svg',
        width: 200,
      },
      { content_type: 'subtitle', text: 'Glutathione Depletion (GSH)' },
      {
        content_type: 'paragraph',
        text:
          ' Normal cells contain substantial amounts of cytoplasmic mbbr,' +
          ' commonly used to scavenge reactive oxygen species. The dye Monobromobimane (mBBr) alkylates' +
          ' the thiol by displacing the bromine, attaching a fluorescent tag to mbbr.' +
          ' Depletion of cytoplasmic mbbr causes a decrease in binding targets for' +
          ' mBBr and thus decreased fluorescent signal.',
      },
      { content_type: 'line' },
      {
        content_type: 'image',
        src: '/img/our-biology/CellPlasmaMembrane.svg',
        width: 200,
      },
      {
        content_type: 'subtitle',
        text: 'CYTOPLASMIC MEMBRANE INTEGRITY (CMI)',
      },
      {
        content_type: 'paragraph',
        text:
          'Calcein AM is a non-fluorescent, cell permeant dye which is' +
          ' converted to a' +
          ' fluorescent compound (by intracellular serine esterases) that cannot pass' +
          ' back out through intact cytoplasmic membranes. Damage to cytoplasmic membrane' +
          ' integrity allows fluorescent intracellular Calcein to diffuse outside the cell,' +
          ' resulting in a decrease in cellular fluorescence. Damage to the cytoplasmic' +
          ' membrane can be reversible, so loss of intracellular Calcein is not necessarily' +
          ' a marker of cell death.',
      },
      { content_type: 'line' },
      {
        content_type: 'image',
        src: '/img/our-biology/CellNuclearMembrane.svg',
        width: 200,
      },
      { content_type: 'subtitle', text: 'NUCLEAR MEMBRANE INTEGRITY (NMI)' },
      {
        content_type: 'paragraph',
        text:
          'This screen uses two different DNA binding dyes – SYTOX™ Red' +
          ' and Propidium Iodide -' +
          ' which become highly fluorescent when bound to DNA. Neither dye can pass through' +
          ' intact cytoplasmic or nuclear membranes in live cells and only bind DNA after' +
          ' both membranes are compromised. Thus, a significant increase in cellular/nuclear' +
          ' fluorescence is an indicator of irreversible cell damage and death.',
      },
      { content_type: 'line' },
      {
        content_type: 'image',
        src: '/img/our-biology/CellNucleus.svg',
        width: 200,
      },
      { content_type: 'subtitle', text: 'CELL CYCLE (CC)' },
      {
        content_type: 'paragraph',
        text:
          'The reporter cell line used for the SYSTEMETRIC® Cell ' +
          ' Health Screen (HL60)' +
          ' is continuously proliferating.  As such, each assay well contains a distribution' +
          ' of cells in all phases of the cell cycle. The DNA binding dye used for cell cycle' +
          ' measurement (Vybrant™ DyeCycle™ Violet) can access the nucleus of viable and dead' +
          ' cells, binding quantitatively to DNA and emitting a fluorescent signal that is' +
          ' proportional to the mass of DNA present in the cell, enabling cell cycle reporting' +
          ' for the phase of individual cells.',
      },
      { content_type: 'line' },
      {
        content_type: 'image',
        src: '/img/our-biology/CellCytoplasm.svg',
        width: 200,
      },
      { content_type: 'subtitle', text: 'MORPHOLOGY (MORPH)' },
      {
        content_type: 'paragraph',
        text:
          'As cells pass through a laser in a flow cytometer, ' +
          ' they scatter light in all directions.' +
          ' The amount of light scattered at low angles (to the laser) is proportional to the volume' +
          ' (size) of the individual cell (Mie theory). The amount of light scattered at high angles' +
          ' (generally measured at 90 degrees) is proportional to the internal optical granularity of' +
          ' each cell. Thus, both parameters provide a measurement of changes in cell morphology. As' +
          ' the cells die, shrink, or expand in response to stress resulting from a toxic compound,' +
          ' detectable light scatter changes can be measured representing changes in the size or' +
          ' internal complexity of the cell caused by the compound.',
      },
    ],
    'our-cell-choice': [
      { content_type: 'title', text: 'Our Cell Choice' },
      {
        content_type: 'image',
        src: '/img/our-biology/CellPlasmaMembraneNormal.svg',
        width: 425,
      },
      {
        content_type: 'paragraph',
        text:
          'The SYSTEMETRIC® Cell Health Screen uses HL60 cells that ' +
          ' were selected from a panel of' +
          ' cell lines following a DOE designed to identify a cell line that could achieve the overall' +
          ' design goals. Critical requirements for selection were diversity, dynamic range and' +
          ' reproducibility of phenotypes at the desired concentrations and incubation time. Of the' +
          ' cell lines tested, HL60 cells delivered the broadest dynamic range and breadth of fingerprints' +
          ' across the compounds that were screened. Our approach to the choice of cells was driven by' +
          ' requiring the ability to visualize diverse cellular stress profiles from different compounds,' +
          ' classes, targets and mechanisms relative to their cellular stress profiles. Importantly,' +
          ' as the screen uses Flow Cytometry, a suspension cell line was needed to avoid trypsinization' +
          ' that would introduce artefacts and noise to the screening results, which could ultimately' +
          ' impact performance, particularly specificity. Another important factor was ensuring that' +
          ' the cells were not metabolically competent as it was important that any stress profile and' +
          ' phenotype generated was from the parent compound alone and not confounded by variations in' +
          ' number, stability and concentration of downstream metabolites. As the platform uses' +
          ' fingerprinting, the focus is on the parent compound for this screen.',
      },
      {
        content_type: 'paragraph',
        text:
          'HL60 cells also provided another added advantage from ' +
          ' a toxicity screening perspective in' +
          ' that they constitutively express the hERG potassium ion channel, a useful feature to have' +
          ' in a toxicity screen. The approach developed by AsedaSciences to grow and prepare HL60 cells' +
          ' for this screen is proprietary, has been extensively validated and is a key contributor to' +
          ' the high reproducibility of the screening results.',
      },
    ],
    'details-method-description': [
      { content_type: 'title', text: 'Detailed Method Description' },
      {
        content_type: 'title',
        text: 'AsedaSciences® Systemetric Cell Health Screen',
      },
      {
        content_type: 'paragraph',
        text:
          'The following represents a detailed methods description ' +
          ' from the following' +
          ' publication: Bieberich et al. (2021) Acute cell stress screen with supervised machine' +
          ' learning predicts cytotoxicity of excipients. Journal of Pharmacological and' +
          ' Toxicological Methods, 111. 107088.',
      },
      {
        content_type: 'link',
        href: 'https://doi.org/10.1016/j.vascn.2021.107088',
      },
      { content_type: 'line' },
      {
        content_type: 'paragraph',
        text:
          'The Cell Health Screen is a multiparametric acute ' +
          ' cell stress assay, using a panel of' +
          ' fluorescent physiological reporting dyes, on an automated flow cytometry platform.' +
          ' Rather than simply producing dose-response curves for all individual biological readouts,' +
          ' features are generated by computing custom-defined distance functions between test' +
          ' and control wells.  All test compounds are represented as feature vectors, after' +
          ' which the analysis algorithm employs a logistic regression model to classify test' +
          ' compounds relative to a training set.  This supervised machine learning (ML) approach' +
          ' integrates all measured readouts into a single predictive statistical model.  This' +
          ' data processing strategy has two notable advantages:  1) feature extraction and data' +
          ' reduction avoid subjective gating of flow cytometry data; 2) the ML classifier has been' +
          ' trained with 300 known compounds comprised of on-market and withdrawn drugs and research' +
          ' compounds.  This training set empirically covers the full range of possible phenotypes' +
          ' in the Cell Health Screen, from no response to acute stress, with sufficient representation' +
          ' across the spectrum.  Training set compounds were assigned to binary classes (“yes” =' +
          ' expectation of high cell stress or “positive” phenotype; “no” = no expectation of' +
          ' positive phenotype).  This externally established ground-truth was based upon manually' +
          ' curated information from research literature and, where applicable, clinical trial' +
          ' results and commercial/regulatory histories.',
      },
      {
        content_type: 'paragraph',
        text:
          'For an unknown test compound, the ML classifier uses ' +
          ' all the FC parameter features' +
          ' describing compound response, simultaneously, to predict the final assignment.  This' +
          ' is achieved by calculating the probability of assigning that compound’s screen phenotype' +
          ' to the “yes” class defined by the training set.  By specifying the problem as a' +
          ' classification challenge, the data analysis pipeline assures that any apparent lack of' +
          ' coordinated change among biological readouts presents no interpretation challenge.' +
          ' All phenotypic data are treated simply as input features to a statistical model.' +
          ' In contrast, many conventional flow cytometry assays require strict mechanistic' +
          ' interpretation of every measured biological readout, often resulting in conflicting' +
          ' conclusions (e.g. if reactive oxygen species increase, but mbbr is unaffected,' +
          ' which should be "believed"?).  The final probability score, or Cell Health Index, is' +
          ' a quantitative assessment of a multiparametric phenotype’s similarity to a diverse set' +
          ' of known good and bad actors.  Finally, choosing HL60 as our reporter cell line means' +
          ' that the screen is explicitly designed not to detect instances in which a parent compound' +
          ' only causes cellular toxicity via metabolites.  This design feature provides certain' +
          ' advantages, exemplified by the fact that our screen reports a stark difference between' +
          ' terfenadine (highly cytotoxic when not metabolized) and its metabolite fexofenadine.' +
          ' A more detailed description of the screen execution follows below.',
      },
      { content_type: 'subtitle', text: '1) Physical execution summary' },
      {
        content_type: 'paragraph',
        text:
          'In a 384-well platform, HL60 cells are exposed to a 10-step, ' +
          ' 3X dilution series of each' +
          ' test compound (5nM - 100µM) for 4 hours at 37°C with 5% CO2.  Each dilution series is screened' +
          ' in duplicate, occupying a total of 20 wells, allowing 16 test compounds to be assayed on each' +
          ' plate.  Each row contains one positive and one negative control well, for a total of 16 matched' +
          ' control pairs on each assay plate.  Compound formatting, cell deposition, and dye application' +
          ' are performed robotically, so that final assay conditions comprise 100,000 cells in a 40µl' +
          ' volume.  After compound exposure, live cells are rapidly stained with a panel of fluorescent' +
          ' dyes that report physiological signatures of both mitochondrial dysfunction and gross cell' +
          ' stress.  Fluorescence data are collected using automated flow cytometry with no gating.' +
          ' In addition, forward scatter and side scatter at 488nm are acquired for conversion into a' +
          ' morphology parameter.  Well-specific flow cytometry data files, with an accompanying map of' +
          ' well contents, are moved to cloud infrastructure where the automated algorithm for quality' +
          ' control and ML classification is triggered.',
      },
      { content_type: 'subtitle', text: '2) HL60 Cell Culture Production' },
      {
        content_type: 'paragraph',
        text:
          'HL60 cells are produced as suspension cultures in ' +
          ' non-treated 850cm2 roller bottles with' +
          ' vented caps, at 1 RPM, 5% CO2, and 37°C.  Culture medium is RPMI 1640 without glucose,' +
          ' supplemented with 10mM galactose and 10% dialyzed heat-inactivated FBS.  Further' +
          ' supplementation follows ATCC standard recommendations for this cell line.  Culture' +
          ' density is maintained at or below 1x106 cells/ml.  A new production lineage of HL60 cells' +
          ' is started each month, and a crossover screen is performed in which the old and new production' +
          ' lineages are compared by using a set of 16 reference compounds to produce a known set of' +
          ' stress phenotypes.  In this way, variation of screen performance is minimized by producing' +
          ' all screening cell populations within a narrow range of passage numbers, each checked for' +
          ' consistency of phenotypic performance with reference compounds.',
      },
    ],
    'data-analysis': [
      { content_type: 'title', text: 'Data Analysis' },
      {
        content_type: 'paragraph',
        text:
          'All well-specific flow cytometry data and matching ' +
          ' plate map files are transferred to an' +
          ' EC2 server instance on Amazon Web Services (AWS).  An automated algorithm converts the' +
          ' raw data to risk scores for each compound in two stages:',
      },
      { content_type: 'subtitle', text: 'Feature Extraction' },
      {
        content_type: 'paragraph',
        text:
          'For each test compound, ungated detection parameters are ' +
          ' converted to a feature vector' +
          ' as follows.  For each concentration step in a test compound dilution series, quadratic' +
          ' form (QF) distance is calculated between the empirical distribution of a flow cytometry' +
          ' parameter and that same parameter in the negative-control well, in the same plate row.' +
          ' All QF distance values for the dilution series then form a dose-response distance curve' +
          ' for that FC parameter.  The same process is executed for all FC parameters, after which' +
          ' each of these curves is further reduced to two values: the point of the maximum rate of' +
          ' change and the range within which change occurs.  By analogy, if a sigmoid curve' +
          ' approximated the observed response, the point of the maximum rate of change would be its' +
          ' inflection point, and the range would be described by the distance between the low and' +
          ' high “plateaus” of the curve.  These two values for each FC parameter, point of maximum' +
          ' change and range, are then assembled into a feature vector representing all FC parameters.' +
          ' This vector serves as the quantitative phenotype for the test compound, to be used in' +
          ' subsequent ML classification.  Note that the use of row-specific negative control wells' +
          ' serves to control for time-dependent signal drift that can occur for the fluorescent dyes' +
          ' as the assay plate sits on the autosampler stage.  This method allows QF distance values to' +
          ' be calculated between test compound wells and the negative control replicate closest to them' +
          ' in sampling time, which normalizes for signal drift from the beginning to the end of the' +
          ' assay plate acquisition.',
      },
      { content_type: 'subtitle', text: 'Machine Learning Classification' },
      {
        content_type: 'paragraph',
        text:
          'Risk scores are produced for test compounds with an ML ' +
          ' classifier employing supervised' +
          ' learning with a multidimensional logistic model.  The classifier is trained on a set of' +
          ' 300 known compounds drawn from on-market pharmaceuticals, withdrawn drugs, research compounds,' +
          ' and a few industrial/agricultural compounds.  First, all training set compounds are assigned to' +
          ' one of two binary classes: the “yes” (expectation of high cell stress) or “no” class.  This' +
          ' assignment is based upon manually curated external information from the scientific literature,' +
          ' clinical trial results, and/or known commercial histories.  Each training set compound was also' +
          ' screened to produce an empirical phenotypic feature vector, as described above.  The classifier' +
          ' is trained by repeated cross-validation.  For the two training classes, established from' +
          ' external information, the logistic model optimization process seeks the most parsimonious' +
          ' model allowing for maximum separation of the two populations of phenotypes.  The optimally' +
          ' fit model then becomes the classification tool allowing calculation of the probability that' +
          ' a feature vector, from any compound, could be assigned to the “yes” (high cell stress) class.',
      },
      {
        content_type: 'paragraph',
        text:
          'Subsequently, for any test compound, the final multi-parametric ' +
          ' risk score, or' +
          " Cell Health Index™ (CHI), is the probability with which the test compound's phenotypic" +
          ' feature vector can be assigned to the “yes” class defined by the training set.' +
          ' In addition, a series of unidimensional classifiers are trained and applied to the' +
          ' detection parameters separately, calculating the probability of “yes” class assignment' +
          ' if only data for that flow cytometry parameter are considered.  These single parameter' +
          ' classifications produce a “fingerprint” of scores that can be interpreted as indicating' +
          ' relative contributions of each parameter to the final multi-parameter CHI score.  However,' +
          ' note that the predictivity of the individual parameters is not assumed to be equal, among' +
          ' themselves or to the CHI.  All test compound results are traceable to specific screen' +
          ' run instances and original compound stocks, regardless of whether any compound name appears' +
          ' more than once within/among screening instances.',
      },
    ],
  },
  zbescreen: {
    approach: [
      { content_type: 'title', text: 'Our Approach' },
      {
        content_type: 'image',
        src: '/img/zebrafish-measures/Normal/Normal.svg',
        width: 400,
      },
      {
        content_type: 'paragraph',
        text:
          'The ZBEscreen is a zebrafish bioactivity screen designed' +
          ' to detect teratogenicity and' +
          ' behavior effects resulting from exposure to chemicals.',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'Developmental Toxicity' },
      {
        content_type: 'paragraph',
        text:
          'Overt toxicity responses in a 13-endpoint battery that ' +
          ' encompasses zebrafish development from 24' +
          ' hpf – 120 hpf. The morphological endpoints measured are part of the different organ systems and' +
          ' processes that are readily visible under a dissecting microscope.  The assay is conducted in two' +
          ' phases – the first is to define the maximal tolerated concentration to inform the 2nd phase to' +
          ' improve the accuracy of identifying the Lowest Effect Level (LEL) to assess chemical activity and safety.',
      },
      {
        content_type: 'subtitle',
        text: 'Embryo Photomotor Response (EPR) Behavior',
      },
      {
        content_type: 'paragraph',
        text:
          'The EPR assay is routinely used as an early sensor of chemical activity. ' +
          ' The normal EPR pattern for' +
          ' wild-type zebrafish embryos at 22 – 24 hpf, based on thousands of observations, is a Baseline' +
          ' frequency of 0.1 – 0.2 flexions per second. The Excitatory phase, commencing with the first' +
          ' light flash, normally has a frequency of 3 – 6 flexions per sec., generally not sustained for' +
          ' more than 4 seconds of the 10 seconds. The Refractory phase, commencing at the second light flash,' +
          ' and 10 seconds after the first, normally has a flexion frequency of 0/sec. Note that the EPR is' +
          ' used as an early, fast, and sensitive predictor of later, generalized, adverse outcomes, not' +
          ' restricted to behavioral endpoints.',
      },
      {
        content_type: 'subtitle',
        text: 'Larval Photomotor Response (LPR) Behavior',
      },
      {
        content_type: 'paragraph',
        text:
          'Zebrafish is a predictive model for neuroactivity in humans. Using an approach ' +
          ' to assess alterations' +
          ' in their locomotor patterns in response to a dark-light environment. The normal LPR pattern for' +
          ' wildtype zebrafish larvae, based on thousands of observations, is one of slow, short distance swimming' +
          ' activity in the lighted phases and 5 - 10 fold more swimming activity in the dark phases. Zebrafish' +
          ' embryos are assessed for viability, and phenotypically normal as to not impact their ability to swim.' +
          ' The total distance traveled over the assay is measured and analyzed to determine whether exposure to a' +
          ' compound has induced behavioral changes during development and potential neuroactivity.',
      },
    ],
    normal: [
      { content_type: 'title', text: 'Normal' },
      {
        content_type: 'image',
        src: '/img/zebrafish-measures/Normal/Normal.svg',
        width: 400,
      },
      {
        content_type: 'paragraph',
        text:
          'The zebrafish is a small complex organism that is amenable to ' +
          ' large-scale in vivo genetic and chemical studies' +
          ' (Pardo-Martin et al., 2010), having a short generation time, rapid development and short life cycle' +
          ' (Kimmel et al., 1995). The embryos develop externally and are transparent for the first few days of life,' +
          ' and due to their small size, only a small quantity of chemicals is needed for a full evaluation of biological' +
          ' response. There is significant physiological and genetic homology between humans and zebrafish (70% gene homology),' +
          ' and approximately 84% of human genes known to be associated with human diseases are also present in zebrafish' +
          ' (Howe et al., 2013). The versatility of zebrafish makes it an ideal model to address toxicity risk assessment,' +
          ' providing an essential bridge between in vitro and mammalian data. By combining the utility of the embryonic' +
          ' zebrafish with validated, high content in vitro cellular screens as the first tier to identify potential' +
          ' toxicity, new insights into toxicity mechanisms can be generated that begin to address the paradigm shift' +
          ' required for modern approaches to toxicity testing.',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'MEASURED PARAMETERS' },
      {
        content_type: 'paragraph',
        text:
          'The zebrafish screen analysis examines the effect of a compound on ' +
          ' multiple physiological effects' +
          ' associated with 16 anatomical different features. These features include the Axis, Brain, Caudal-Fin,' +
          ' Circulatory system, Eyes, Jaw, Notochord, Otic, Pericardial Edema, Pectoral Fin, Pigmentation, Snout,' +
          ' Somite, Swim-Bladder, Trunk and Yolk-Sac-Edema.',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'REPORTED PARAMETERS' },
      {
        content_type: 'paragraph',
        text:
          'While 16 features are assessed, some of these are combined into a summary ' +
          ' feature set as the toxic' +
          ' effects tend to group together. The final reported findings are combined into the following readouts' +
          ' that record the Lowest Effect Level (LEL), or the lowest concentration in the dilution series exhibiting' +
          ' an adverse effect on the measured parameters: Mortality 24 hpf (MO24), Delayed Development 24 hpf (DD24),' +
          ' Abnormal Spontaneous Movement 24 hpf (SM24), Mortality at 120 hours (MO120), Craniofacial (CRAN), Axis (AXIS),' +
          ' Edema (EDEM), Muscle (MUSC), Lower Trunk (LTRK), Brain (BRN), Skin (SKIN), Notochord (NC), Touch Response (TCHR),' +
          ' Embryonic and Larval Photomotor Response (EPR / LPRL / LPRD).',
      },
    ],
    'groups-of-reported-parameters': [
      { content_type: 'title', text: 'Groups of Reported Parameters' },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'AXIS (Readout: AXIS)' },
      {
        content_type: 'measurements',
        height: 220,
        config: [
          {
            label: 'Normal',
            image: '/img/zebrafish-measures/Normal/AxisNormal.svg',
          },
          { label: 'Mild', image: '/img/zebrafish-measures/Axis/AxisMild.svg' },
          {
            label: 'Moderate',
            image: '/img/zebrafish-measures/Axis/AxisModerate.svg',
          },
          {
            label: 'Severe',
            image: '/img/zebrafish-measures/Axis/AxisSevere.svg',
          },
          {
            label: 'Extreme 1',
            image: '/img/zebrafish-measures/Axis/AxisExtreme_1.svg',
          },
          {
            label: 'Extreme 2',
            image: '/img/zebrafish-measures/Axis/AxisExtreme_2.svg',
          },
        ],
      },
      {
        content_type: 'paragraph',
        text:
          'There is a presence of axial bend at 120 hours post fertilization (hpf).' +
          ' The body axis is' +
          ' visibly curved, either concave or convex, where control animals are straight.',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'BRAIN (Readout: BRN)' },
      {
        content_type: 'measurements',
        height: 150,
        config: [
          {
            label: 'Swelling',
            image: '/img/zebrafish-measures/Brain/BrainSwelling_Box.svg',
          },
          {
            label: 'Cloudy',
            image: '/img/zebrafish-measures/Brain/BrainSwellingCloudy_Box.svg',
          },
          {
            label: 'Grey',
            image:
              '/img/zebrafish-measures/Brain/BrainSwellingCloudyGrey_Box.svg',
          },
          {
            label: 'Mass',
            image: '/img/zebrafish-measures/Brain/BrainSwellingMass_Box.svg',
          },
        ],
      },
      {
        content_type: 'paragraph',
        text:
          'Abnormal brain and otic region at 120 hours post fertilization (hpf).' +
          ' The Brain region is' +
          ' abnormal in appearance based on color or size. The Brain and Otic abnormalities are combined' +
          ' as the final Brain (BRN) readout.',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'OTIC (Readout: BRN)' },
      {
        content_type: 'measurements',
        height: 150,
        config: [
          {
            label: 'Normal',
            image: '/img/zebrafish-measures/Otic/OticVesicleNormal.svg',
          },
          {
            label: 'Small',
            image: '/img/zebrafish-measures/Otic/OticVesicleSmall.svg',
          },
          {
            label: 'Large',
            image: '/img/zebrafish-measures/Otic/OticVesicleLarge.svg',
          },
          {
            label: 'Missing',
            image: '/img/zebrafish-measures/Otic/OticVesicleMissing.svg',
          },
        ],
      },
      {
        content_type: 'paragraph',
        text:
          'Malformed or missing otic vesicle at 120 hours post fertilization.' +
          ' The otic vesicle is either' +
          ' small, enlarged / swollen or missing. This measurement is grouped together under Brain (BRN).',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'PECTORAL FIN (Readout: BRN)' },
      {
        content_type: 'measurements',
        height: 150,
        config: [
          {
            label: 'Normal',
            image: '/img/zebrafish-measures/Pectoral-Fin/PectoralFinNormal.svg',
          },
          {
            label: 'Extended',
            image:
              '/img/zebrafish-measures/Pectoral-Fin/PectoralFinExtended.svg',
          },
          {
            label: 'Missing',
            image:
              '/img/zebrafish-measures/Pectoral-Fin/PectoralFinMissing.svg',
          },
          {
            label: 'Shorter',
            image:
              '/img/zebrafish-measures/Pectoral-Fin/PectoralFinShorter.svg',
          },
          {
            label: 'Varying',
            image:
              '/img/zebrafish-measures/Pectoral-Fin/PectoralFinVarying.svg',
          },
        ],
      },
      {
        content_type: 'paragraph',
        text:
          'Malformed or missing pectoral fin at 120 hours post fertilization (hpf).' +
          ' From the dorsal view,' +
          ' the pectoral fins may appear curled, extended and not parallel with the body, or exhibit varying sizes.' +
          ' This measurement is grouped together under Brain (BRN).',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'JAW (Readout: CRAN)' },
      {
        content_type: 'measurements',
        height: 150,
        config: [
          {
            label: 'Normal',
            image: '/img/zebrafish-measures/Normal/AxisNormal.svg',
          },
          {
            label: 'Misshapen',
            image: '/img/zebrafish-measures/Jaw/JawMisshapen.svg',
          },
          {
            label: 'Extended',
            image: '/img/zebrafish-measures/Jaw/JawPastSnout.svg',
          },
          {
            label: 'Protruding',
            image: '/img/zebrafish-measures/Jaw/JawProtruding.svg',
          },
        ],
      },
      {
        content_type: 'paragraph',
        text:
          'Abnormality with the jaw at 120 hours post fertilization. The jaw ' +
          ' is either misshapen,' +
          ' abnormally extended or protruding past the snout. This measurement is grouped together under' +
          ' Craniofacial Defects (CRAN).',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'SNOUT (Readout: CRAN)' },
      {
        content_type: 'measurements',
        height: 150,
        config: [
          {
            label: 'Normal',
            image: '/img/zebrafish-measures/Normal/AxisNormal.svg',
          },
          {
            label: 'Misshapen',
            image: '/img/zebrafish-measures/Snout/SnoutMisshapen.svg',
          },
          {
            label: 'Missing',
            image: '/img/zebrafish-measures/Snout/SnoutMissing.svg',
          },
          {
            label: 'Swollen',
            image: '/img/zebrafish-measures/Snout/SnoutSwollen.svg',
          },
        ],
      },
      {
        content_type: 'paragraph',
        text:
          'Abnormality with the snout at 120 hours post fertilization. The snout ' +
          ' is either misshapen' +
          ' or protruding well beyond the eyes, is recessed or has reduced or missing tissue, has swollen' +
          ' or excessive tissue, or is irregular / missing. The snout is grouped together with a highly' +
          ' correlated suite of malformations grouped together under Craniofacial Defects (CRAN).',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'EYES (Readout: CRAN)' },
      {
        content_type: 'measurements',
        height: 150,
        config: [
          {
            label: 'Normal',
            image: '/img/zebrafish-measures/Eyes/EyeDorsalNormal.svg',
          },
          {
            label: 'Abnormal',
            image: '/img/zebrafish-measures/Eyes/EyeDorsalAbnormal.svg',
          },
          {
            label: 'Swelling',
            image: '/img/zebrafish-measures/Eyes/EyeDorsalSwelling.svg',
          },
          {
            label: 'Normal (axis)',
            image: '/img/zebrafish-measures/Normal/AxisNormal.svg',
          },
          {
            label: 'Larger',
            image: '/img/zebrafish-measures/Eyes/EyeLarger.svg',
          },
          {
            label: 'Smaller',
            image: '/img/zebrafish-measures/Eyes/EyeSmaller.svg',
          },
          {
            label: 'Missing',
            image: '/img/zebrafish-measures/Eyes/EyeMissing.svg',
          },
        ],
      },
      {
        content_type: 'paragraph',
        text:
          'Abnormality with the eyes at 120 hours post fertilization. The ' +
          ' eyes are abnormal, swollen, smaller,' +
          ' larger or missing when compared to the normal controls and may also exhibit periocular edema. The eyes' +
          ' are grouped together with a highly correlated suite of malformations simplified to Craniofacial Defects (CRAN).',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'CIRCULATORY SYSTEM (Readout: MUSC)' },
      {
        content_type: 'measurements',
        height: 150,
        config: [
          {
            label: 'Normal',
            image: '/img/zebrafish-measures/Normal/AxisNormal.svg',
          },
          {
            label: 'Eye',
            image:
              '/img/zebrafish-measures/Circulatory/CirculatorySystemEyeHemorrhaging.svg',
          },
          {
            label: 'Cranium',
            image:
              '/img/zebrafish-measures/Circulatory/CirculatorySystemHemorrhagingCranial.svg',
          },
          {
            label: 'Body',
            image:
              '/img/zebrafish-measures/Circulatory/CirculatorySystemBodyHemorrhaging.svg',
          },
          {
            label: 'Yolk Sak',
            image:
              '/img/zebrafish-measures/Circulatory/CirculatorySystemHemorrhagingYolkSak.svg',
          },
        ],
      },
      {
        content_type: 'paragraph',
        text:
          'Abnormalities observed with the circulatory system at 120 hours post ' +
          ' fertilization (hpf).' +
          ' Hemorrhaging can be observed in the pericardium, yolk sac, body cavity, cranium or around the eyes.' +
          ' This measurement is grouped together under Muscle (MUSC).',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'SWIM BLADDER (Readout: MUSC)' },
      {
        content_type: 'measurements',
        height: 150,
        config: [
          {
            label: 'Normal',
            image: '/img/zebrafish-measures/Normal/AxisNormal.svg',
          },
          {
            label: 'Mild',
            image: '/img/zebrafish-measures/Swim-Bladder/SwimBladderMild.svg',
          },
          {
            label: 'Missing',
            image:
              '/img/zebrafish-measures/Swim-Bladder/SwimBladderNoPresence.svg',
          },
        ],
      },
      {
        content_type: 'paragraph',
        text:
          'Muscle and somite defect at 120 hours post fertilization (hpf). ' +
          ' Swim bladder is slightly deflated or' +
          ' the air bubble is not present or completely deflated. Swim bladder abnormalities are grouped together' +
          ' under Muscle (MUSC), that includes muscle pattern abnormalities and an irregular heart beat, or much' +
          ' different than 180 bpm, with blood flow absent or slow in the common cardinal vein (duct of Cuvier).',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'SOMITES (Readout: MUSC)' },
      {
        content_type: 'measurements',
        height: 150,
        config: [
          {
            label: 'Normal',
            image: '/img/zebrafish-measures/Somites/SomitesNormal.svg',
          },
          {
            label: 'Irregularity 1',
            image: '/img/zebrafish-measures/Somites/SomitesUndefined1.svg',
          },
          {
            label: 'Irregularity 2',
            image: '/img/zebrafish-measures/Somites/SomitesUndefined2.svg',
          },
          {
            label: 'Wider',
            image: '/img/zebrafish-measures/Somites/SomitesWider.svg',
          },
        ],
      },
      {
        content_type: 'paragraph',
        text:
          'Somite defects observed 120 hours post fertilization. The normal chevron ' +
          ' pattern is lost and the' +
          ' somites appear undefined, distended or no longer chevron shaped. Somite abnormalities are grouped' +
          ' together under Muscle (MUSC), that includes swim bladder abnormalities and an irregular heart beat,' +
          ' or much different than 180 bpm, with blood flow absent or slow in the common cardinal vein' +
          ' (duct of Cuvier).',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'PERICARDIAL EDEMA (Readout: EDEM)' },
      {
        content_type: 'measurements',
        height: 150,
        config: [
          {
            label: 'Normal',
            image: '/img/zebrafish-measures/Normal/AxisNormal.svg',
          },
          {
            label: 'Severe',
            image:
              '/img/zebrafish-measures/Pericardial-Edema/PericardialEdemaSevere.svg',
          },
          {
            label: 'Extreme',
            image:
              '/img/zebrafish-measures/Pericardial-Edema/PericardialEdemaExtreme.svg',
          },
        ],
      },
      {
        content_type: 'paragraph',
        text:
          'Abnormality with the heart at 120 hours post fertilization. Moderate, ' +
          ' severe and extreme swelling of' +
          ' the pericardium can be observed. Distended clear region around the heart with a lumpy appearance of' +
          ' swollen tissue. This measurement is grouped together under Edema (EDEM).',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'YOLK SAC EDEMA (Readout: EDEM)' },
      {
        content_type: 'measurements',
        height: 150,
        config: [
          {
            label: 'Normal',
            image: '/img/zebrafish-measures/Normal/AxisNormal.svg',
          },
          {
            label: 'Swollen',
            image:
              '/img/zebrafish-measures/Yolk-Sac-Edema/YolkSacEdemaSwollen.svg',
          },
          {
            label: 'Extended 1',
            image:
              '/img/zebrafish-measures/Yolk-Sac-Edema/YolkSacEdemaSwollenExtended1.svg',
          },
          {
            label: 'Extended 2',
            image:
              '/img/zebrafish-measures/Yolk-Sac-Edema/YolkSacEdemaSwollenExtended2.svg',
          },
        ],
      },
      {
        content_type: 'paragraph',
        text:
          'Abnormality with the yolk sac at 120 hours post fertilization. ' +
          ' Distended clear region around the yolk sac,' +
          ' with the yolk sac appearing swollen and enlarged, or extended down the trunk. This measurement is grouped' +
          ' together under Edema (EDEM).',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'LOWER TRUNK (Readout: LTRK)' },
      {
        content_type: 'measurements',
        height: 200,
        config: [
          {
            label: 'Normal',
            image: '/img/zebrafish-measures/Normal/AxisNormal.svg',
          },
          {
            label: 'Bent',
            image: '/img/zebrafish-measures/Trunk/TrunkBend.svg',
          },
          {
            label: 'Short',
            image: '/img/zebrafish-measures/Trunk/TrunkShort.svg',
          },
          {
            label: 'Curvature 1',
            image: '/img/zebrafish-measures/Trunk/TrunkCurvature1.svg',
          },
          {
            label: 'Curvature 2',
            image: '/img/zebrafish-measures/Trunk/TrunkCurvature2.svg',
          },
          {
            label: 'Extreme',
            image: '/img/zebrafish-measures/Trunk/TrunkExtremeCurvature.svg',
          },
        ],
      },
      {
        content_type: 'paragraph',
        text:
          'Lower trunk and caudal fin defect at 120 hours post fertilization (hpf).' +
          ' Shorter than normal body from the' +
          ' posterior of the yolk sac to the caudal fin. Abnormalities include curvature or bend of the trunk and' +
          ' overall trunk length is abbreviated. The trunk tissue may also appear misshapen or irregular.',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'CAUDAL FIN (Readout: LTRK)' },
      {
        content_type: 'measurements',
        height: 200,
        config: [
          {
            label: 'Normal',
            image: '/img/zebrafish-measures/Normal/AxisNormal.svg',
          },
          {
            label: 'Bent',
            image: '/img/zebrafish-measures/Caudal-Fin/CaudalFinBent.svg',
          },
          {
            label: 'Curvature',
            image: '/img/zebrafish-measures/Caudal-Fin/CaudalFinCurvature.svg',
          },
          {
            label: 'Misshapen',
            image: '/img/zebrafish-measures/Caudal-Fin/CaudalFinMisshapen.svg',
          },
          {
            label: 'Reduction',
            image: '/img/zebrafish-measures/Caudal-Fin/CaudalFinReduction.svg',
          },
        ],
      },
      {
        content_type: 'paragraph',
        text:
          'Caudal fin defects at 120 hours post fertilization (hpf). The caudal fin is malformed or missing,' +
          ' slightly bent or blunt, or with curvature in the peduncle region. Excess, absent, misshapen or' +
          ' atypical tissue growth can also be observed.',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'NOTOCHORD DEFECT (Readout: NOTO)' },
      {
        content_type: 'measurements',
        height: 200,
        config: [
          {
            label: 'Normal',
            image: '/img/zebrafish-measures/Notochord/NotochordNormal.svg',
          },
          {
            label: 'Abnormal 1',
            image: '/img/zebrafish-measures/Notochord/NotochordAbnormal.svg',
          },
          {
            label: 'Normal (side)',
            image: '/img/zebrafish-measures/Normal/AxisNormal.svg',
          },
          {
            label: 'Abnormal 2',
            image:
              '/img/zebrafish-measures/Notochord/NotochordSideAbnormal.svg',
          },
        ],
      },
      {
        content_type: 'paragraph',
        text:
          'Malformed and abnormal notochord at 120 hours post fertilization (hpf), ' +
          ' with the notochord appearing' +
          ' sinuous instead of straight. In both the dorsal and side view, the notochord appears undulated.',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'SKIN (Readout: SKIN)' },
      {
        content_type: 'measurements',
        height: 150,
        config: [
          {
            label: 'Normal',
            image: '/img/zebrafish-measures/Normal/AxisNormal.svg',
          },
          {
            label: 'Absence',
            image: '/img/zebrafish-measures/Pigment/PigmentAbsence.svg',
          },
          {
            label: 'Dark Spots',
            image: '/img/zebrafish-measures/Pigment/PigmentDarkSpots.svg',
          },
          {
            label: 'None',
            image: '/img/zebrafish-measures/Pigment/PigmentNone.svg',
          },
        ],
      },
      {
        content_type: 'paragraph',
        text:
          'Skin and pigmentation defect at 120 hours post fertilization (hpf), ' +
          ' with hyper- or hypopigmentation.' +
          ' Abnormalities include an overall absence of pigment, groups of melanocytes creating irregular dark spots,' +
          ' or an absence of any melanocytes.',
      },
    ],
    'additional-measurements': [
      { content_type: 'title', text: 'Additional Measurements' },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'TOUCH RESPONSE (Readout: TCHR)' },
      {
        content_type: 'paragraph',
        text:
          'Touch to the caudal fin is unresponsive at 120 hours post ' +
          ' fertilization (hpf).' +
          ' A gentle touch with a blunt tip probe produces no escape response.',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'MORTALITY [24HR] (Readout: MORT24)' },
      {
        content_type: 'paragraph',
        text:
          'Observed Mortality at 24 hours post fertilization (hpf). ' +
          ' Death occurred from' +
          ' shortly after chemical dosing to 24 hpf.',
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'DELAYED DEVELOPMENT [24HR] (Readout: DD24)',
      },
      {
        content_type: 'paragraph',
        text:
          'Delayed Development at 24 hours post fertilization (hpf). ' +
          ' Lag in development or otherwise' +
          ' morphologically different than control animals at 24 hpf.',
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'NO SPONTANEOUS MOVEMENT [24HR] (Readout: SMOV24)',
      },
      {
        content_type: 'paragraph',
        text:
          'Lack of spontaneous movement at 24 hours post fertilization (hpf). ' +
          ' Frequency of tail flexions at' +
          ' 28°C (typically once every 15-20 seconds) is visibly faster or slower than control animals.',
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'MORTALITY [120HR] (Readout: MORT120)',
      },
      {
        content_type: 'paragraph',
        text:
          'Observed Mortality by 120 hours post fertilization (hpf). Animals alive ' +
          ' at the 24 hpf check found' +
          ' dead at the 120 hpf evaluation.',
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'EMBRYONIC PHOTOMOTOR RESPONSE [24HR] (Readout: EPR)',
      },
      {
        content_type: 'paragraph',
        text:
          'Lowest Effect Level (LEL) for abnormal 24 hour behavior response. ' +
          ' The earliest behavioral' +
          ' assessment at 24 hr measuring the embryonic photomotor response in a 50 second assay.',
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'LARVAL PHOTOMOTOR RESPONSE-DARK [120HR] (Readout: LPRD)',
      },
      {
        content_type: 'paragraph',
        text:
          'Lowest Effect Level (LEL) for abnormal 120 hour behavior response in ' +
          ' the dark. The movement of' +
          ' larvae at 120 hour under a 24 minute light dark paradigm. The concentration that caused abnormal' +
          ' swimming behavior in the dark is noted.',
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'LARVAL PHOTOMOTOR RESPONSE-LIGHT [120HR] (Readout: LPRL)',
      },
      {
        content_type: 'paragraph',
        text:
          'Lowest Effect Level (LEL) for abnormal 120 hour behavior response in ' +
          ' the light. The movement of larvae' +
          ' at 120 hour under a 24 minute light dark paradigm. The concentration that caused abnormal swimming' +
          ' behavior in the light is noted.',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'ANY EFFECT (Readout: ANY)' },
      {
        content_type: 'paragraph',
        text:
          'Summary of any mortality and malformation effect. The detection of ' +
          ' either mortality or any' +
          ' abnormalities in the fish at 120 hpf.',
      },
      { content_type: 'break' },
    ],
  },
  'zbescreen-236': {
    approach: [
      { content_type: 'title', text: 'Our Approach' },
      {
        content_type: 'image',
        src: '/img/zebrafish-measures/Normal/Normal.svg',
        width: 400,
      },
      {
        content_type: 'paragraph',
        text:
          'The ZBEscreen-236 is a zebrafish embryo acute toxicity (FET) screen ' +
          ' designed to detect acute or' +
          ' lethal toxicity from exposure to chemicals as described in the OECD test guideline 236.' +
          ' The test measure 4 key endpoints - coagulation of fertilized embryo, lack of somite formation,' +
          ' lack of detachment of the tail bud from the yolk sac, and lack of a heartbeat.',
      },
    ],
    'reported-parameters': [
      { content_type: 'title', text: 'Reported Parameters' },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'COAGULATION OF FERTILIZED EMBRYO (Readout: MO24, MO48, MO72, MO96)',
      },
      {
        content_type: 'paragraph',
        text:
          'The presence of coagulation of a fertilized chorionated embryo ' +
          ' at 24, 48, 72, or 96 hours post fertilization (hpf).',
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'LACK OF SOMITE FORMATION (Readout: SO24, SO48, SO72, SO96)',
      },
      {
        content_type: 'paragraph',
        text:
          'The presence or absence of  formed somites beginning at 24, 48, ' +
          '72 and 96 24 hours post fertilization (hpf).',
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'LACK OF TAIL BUD DETACHMENT (Readout: TD24, TD48, TD73, TD96)',
      },
      {
        content_type: 'paragraph',
        text:
          'After 24 hours post fertilization (hpf), the tail detaches from the ' +
          ' yolk following posterior elongation of the body. The tail detachment is evaluated every 24 ' +
          ' hours until 96 hpf.',
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'LACK OF HEARTBEAT (Readout: HB48, HB72, HB96)',
      },
      {
        content_type: 'paragraph',
        text:
          'The heartbeat is measurable after 48 hours post fertilization (hpf) ' +
          ' and is recorded presence of an active heart beat at 48, 72 and 96 hpf.',
      },
      { content_type: 'line' },
      { content_type: 'subtitle', text: 'HATCH DELAY (Readout: HD72, HD96)' },
      {
        content_type: 'paragraph',
        text:
          'Observation of hatch delay from the chorion for embryos at 72 and 96 ' +
          ' hours post fertilization (hpf).',
      },
      { content_type: 'line' },
    ],
  },
  'biomimetic-screens': {
    overview: [
      { content_type: 'title', text: 'Biomimetic Screening Overview' },
      {
        content_type: 'paragraph',
        text: 'In drug discovery, quality is paramount in successful optimization and progression through the clinic[1].  A drug’s quality can be defined by physicochemical properties, notably lipophilicity, which define all aspects of its behavior and disposition. Chromatographic lipophilicity measurements, using High-Performance Liquid Chromatography (HPLC), have set new standards in throughput, quality and relevance for measuring lipophilicity, increasingly superseding octanol water measurements in enlightened organizations.[2,3,4] Furthermore, protein and phospholipid binding, which are critical measures for estimating free fraction, in vivo distribution and efficacy, can similarly be estimated by high throughput biomimetic chromatographic methods. Optimizing these parameters for drug candidates or SAR series for improved drug efficacy and efficiency, is critical to support the selection, ranking and prioritization of the most promising compounds.',
      },
      {
        content_type: 'paragraph',
        text: 'The chromatographic/biomimetic methods adopted for measuring lipophilicity and membrane / protein binding characteristics have substantially improved the quality and reproducibility of data generation, and transformed our understanding of the implications of these critical properties to in vivo distribution, dose and efficacy. Lipophilicity influences compound safety due to off-target binding or tissue partitioning, producing cytotoxic effects.  Understanding such binding with biomimetic methods mimicking membrane phospholipid and plasma proteins can inform on sequestration into nonspecific phospholipid binding sites and free drug concentrations that can perturb cellular function.',
      },
      {
        content_type: 'paragraph',
        text: 'Biomimetic chromatography screening is designed to predict the in vivo distribution of a compound, by simulating the interactions with human proteins and phospholipids. This is achieved through specialized HPLC columns equipped with immobilized human serum albumin (HSA), alpha 1-acid glycoprotein (AGP) and immobilized artificial membrane (IAM) – collectively constituting biorelevant stationary phases that mimic the lipid environment of a fluid cell membrane, or protein binding in circulation.',
      },
      {
        content_type: 'image-nowrap',
        src: '/img/our-biology/Biomimetics_Process.svg',
        width: 1000,
      },
      {
        content_type: 'paragraph',
        text: 'Biomimetic chromatography is now established as an efficient, cost-effective, and rapid method, providing a powerful tool for comprehending in vivo distribution of candidate compounds. By improving the candidate selection process, compounds with optimized properties can be progressed, helping to refine and reduce the use of animals by focusing resources on compounds with promising efficacy and minimal toxicity. This innovative technique not only streamlines drug development, but also offers a platform to enhance dosing strategies to improve efficacy.',
      },
    ],
    'measured-parameters': [
      { content_type: 'title', text: 'Measured Parameters' },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'Chromatographic Hydrophobicity Index (CHI) logD',
      },
      {
        content_type: 'paragraph',
        text: "Measurement of a compound's lipophilicity in its neutral, non-ionized state, expressed on the octanol/water scale. The lipophilicity of a compound plays an important role in its absorption, distribution, metabolism, and excretion, since the compound's ability to cross cellular membranes and interact with target molecules often depends on its lipophilicity. CHI logD 7.4 values less than 5 suggest that the compound has relatively low lipophilicity and is more likely to favor polar and aqueous environments over non-polar, or lipid-rich environments. Values between 5 and 6 suggest that the compound has moderate lipophilicity. Values greater than 5 suggest that a compound is likely to interact with both hydrophobic and hydrophilic environments, like cell membranes.",
      },
      {
        content_type: 'subtitle',
        text: 'Chromatographic Hydrophobicity Index (CHI) logD 7.4',
      },
      {
        content_type: 'image-nowrap',
        src: '/img/our-biology/Biomimetics_Parameters_Figure_5_6_dark.svg',
        src_dark: '/img/our-biology/Biomimetics_Parameters_Figure_5_6_dark.svg',
        src_light:
          '/img/our-biology/Biomimetics_Parameters_Figure_5_6_light.svg',
        width: 400,
      },
      {
        content_type: 'paragraph',
        text: "Measurement of a compound's lipophilicity at pH 7.4 (physiological), expressed on the octanol/water scale. Accounts for the pH-dependent ionization of the compound, simulating its interaction with physiological environments. The lipophilicity of compounds is a crucial physicochemical factor that can offer insights into aspects such as protein binding, volume of distribution, and other distribution properties of drug molecules. These parameters play a significant role in drug development, where a compound's ability to cross cellular membranes and interact with target molecules often depends on its lipophilicity.  CHI logD 7.4 values <5 suggest that a compound has relatively low lipophilicity and is more likely to favor polar and aqueous environments over non-polar or lipid-rich environments. Values between 5 and 6 suggest that a compound has moderate lipophilicity. Values >5 suggest that a compound is likely to interact with both hydrophobic and hydrophilic environments, like cell membranes.",
      },
      {
        content_type: 'image-nowrap',
        src: '/img/our-biology/Biomimetics_Parameters_Plot_1.svg',
        width: 600,
      },
      {
        content_type: 'paragraph',
        text: "CHI LogD 7.4 measurements are based on using a generic acetonitrile gradient with a C-18 reversed-phase HPLC column. The retention time values are converted to Chromatographic Hydrophobicity Index (CHI) [5] values projected to the octanol/water log D scale to provide CHI logD values. By measuring CHI logD at acidic (2.0), neutral (7.4) and alkaline (10.4) pH, we can calculate a compound's lipophilicity at pH 7.4 (CHI logD7.4), the lipophilicity of the neutral/unionized form (CHI logP) of the molecules and also, we can reveal the acid-base character[6].",
      },

      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'Property Forecast Index (PFI)',
      },
      {
        content_type: 'image-nowrap',
        src: '/img/our-biology/Biomimetics_Parameters_Figure_6_dark.svg',
        src_dark: '/img/our-biology/Biomimetics_Parameters_Figure_6_dark.svg',
        src_light: '/img/our-biology/Biomimetics_Parameters_Figure_6_light.svg',
        width: 400,
      },
      {
        content_type: 'paragraph',
        text: 'The Property Forecast Index (PFI) is calculated as the sum of ChromlogD and the count of aromatic rings within the molecule. The PFI can be used to estimate the cell partition coefficient (Kp cell). The ChromlogD values are obtained from the CHI values and provide the basis of the PFI (Property Forecast Index) [7], together with the number of aromatic rings (PFI = ChromlogD 7.4 + Number of Aromatic Rings). High PFI values (greater than 6) are associated with low solubility, strong albumin binding, high p450 metabolism and clearance, and low permeability. The neutral form Chromlog D is used in the iPFI calculation, which can indicate promiscuous binding and hERG inhibition, the potential for cardiotoxicity.',
      },
      {
        content_type: 'image',
        src: '/img/our-biology/Biomimetics_Parameters_Table.png',
        width: 800,
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'Chromatographic Hydrophobicity Index Immobilized Artificial Membrane (CHI IAM)',
      },
      {
        content_type: 'image-nowrap',
        src: '/img/our-biology/Biomimetics_Parameters_Figure_50_55_dark.svg',
        src_dark:
          '/img/our-biology/Biomimetics_Parameters_Figure_50_55_dark.svg',
        src_light:
          '/img/our-biology/Biomimetics_Parameters_Figure_50_55_light.svg',
        width: 400,
      },
      {
        content_type: 'paragraph',
        text: "CHI IAM measurement reflects a compound's ability to bind to phospholipids. CHI IAM values <50 suggest less phospholipid affinity, between 50 and 55 suggest a moderate phospholipid affinity, while >55 indicate robust phospholipid binding. Higher CHI IAM values correlate with extensive tissue distribution, a large volume of distribution, diminished drug efficiency, and potential indiscriminate binding. Elevated CHI IAM values, particularly when combined with a positive charge, suggest the potential for interactions with phospholipids.",
      },
      {
        content_type: 'paragraph',
        text: 'The IAM HPLC column has been designed to mimic the lipid environment of the cell membrane containing phosphatidylcholine, and the generic gradient retention times obtained on the IAM column measure compounds partitioning into phospholipids. When CHI IAM is greater than 55, the compound shows very strong phospholipid binding that is associated with strong tissue partition, high volume of distribution, low drug efficiency and possible promiscuous binding.',
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'CAD-likeness (similarity to cationic amphiphilic drugs causing phospholipidosis)',
      },
      {
        content_type: 'image-nowrap',
        src: '/img/our-biology/Biomimetics_Parameters_Figure_50_55_dark.svg',
        src_dark:
          '/img/our-biology/Biomimetics_Parameters_Figure_50_55_dark.svg',
        src_light:
          '/img/our-biology/Biomimetics_Parameters_Figure_50_55_light.svg',
        width: 400,
      },
      {
        content_type: 'paragraph',
        text: "CAD-likeness is a measurement of a compound's phospholipidotic potential by comparing it to well-established phospholipidosis inducers - cationic amphiphilic drugs. It is quantified using CHI IAM values and the variance between CHI lipophilicity measured at elevated and neutral pH levels. CAD-likeness values <55 suggest reduced likelihood of the compound inducing phospholipidosis, between 50 and 55 suggest moderate potential of the compound for inducing phospholipidosis and >55 suggests the potential for inducing phospholipidosis. In such cases, further evaluation through in vivo assessment is recommended.",
      },
      {
        content_type: 'paragraph',
        text: 'The similarity of compounds to cationic amphiphilic drugs that often cause phospholipidosis can be expressed from the CHI IAM values and the difference between CHI lipophilicity obtained at high and neutral pHs, respectively. CAD-likeness number =  CHI IAM + (CHI at pH 10.5 – CHI at pH 7.4)',
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: '% Bound Human Serum Albumin (HSA)',
      },
      {
        content_type: 'image-nowrap',
        src: '/img/our-biology/Biomimetics_Parameters_Figure_98_99_dark.svg',
        src_dark:
          '/img/our-biology/Biomimetics_Parameters_Figure_98_99_dark.svg',
        src_light:
          '/img/our-biology/Biomimetics_Parameters_Figure_98_99_light.svg',
        width: 400,
      },
      {
        content_type: 'paragraph',
        text: '% bound HSA is the percentage of a substance that is bound to Human Serum Albumin (HSA). Increased binding decreases the free concentration available for potency and impairs its distribution into tissues and elimination from the body. This measurement helps to indicate how a substance may behave in the body and how it might impact the intended therapeutic index and potential side effects. Having less than 98% binding to HSA is favorable, while moderate binding between 98% and 99% is acceptable. However, binding exceeding 99% raises concerns about bioavailability, dose and the effect on efficacy.',
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: '% bound α1-acid glycoprotein (AGP)',
      },
      {
        content_type: 'image-nowrap',
        src: '/img/our-biology/Biomimetics_Parameters_Figure_75_80_dark.svg',
        src_dark:
          '/img/our-biology/Biomimetics_Parameters_Figure_75_80_dark.svg',
        src_light:
          '/img/our-biology/Biomimetics_Parameters_Figure_75_80_light.svg',
        width: 400,
      },
      {
        content_type: 'paragraph',
        text: "α1-Acid Glycoprotein (AGP) is a minor plasma component, and its concentration changes based on health status. Diseases such as cancer and inflammation can elevate AGP levels, leading to stronger plasma protein binding and a decreased fraction of free drug. Evaluating AGP binding allows assessment of a drug's availability and effectiveness within the body, which can influence its therapeutic index and potential side effects. Less than 75% binding to AGP is favorable, while moderate binding between 75% and 80% is acceptable, while binding exceeding 80% may decrease the efficacy of a drug, depending on the individual.",
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'Calculated % Plasma Protein Binding (PPB)',
      },
      {
        content_type: 'image-nowrap',
        src: '/img/our-biology/Biomimetics_Parameters_Figure_98_99_dark.svg',
        src_dark:
          '/img/our-biology/Biomimetics_Parameters_Figure_98_99_dark.svg',
        src_light:
          '/img/our-biology/Biomimetics_Parameters_Figure_98_99_light.svg',
        width: 400,
      },
      {
        content_type: 'paragraph',
        text: "Estimation of total plasma protein binding considers a compound's binding to both HSA and AGP, along with its overall lipophilicity[8]. This calculation helps in the prediction of how much of the compound will bind to these proteins and lipids in the blood, influencing the free concentration of the compound. Less than 98% is favorable, while moderate binding between 98% and 99% is acceptable, while binding exceeding 99% may decrease drug efficacy.",
      },

      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'Calculated % Blood Binding (Calc % Blood)',
      },
      {
        content_type: 'image-nowrap',
        src: '/img/our-biology/Biomimetics_Parameters_Figure_98_99_dark.svg',
        src_dark:
          '/img/our-biology/Biomimetics_Parameters_Figure_98_99_dark.svg',
        src_light:
          '/img/our-biology/Biomimetics_Parameters_Figure_98_99_light.svg',
        width: 400,
      },
      {
        content_type: 'paragraph',
        text: "Calculated % Blood Binding estimates the binding of a compound to blood controls, including blood cells and plasma proteins. This parameter helps understand how a substance might distribute and interact within the body, and it's particularly important when studying pharmacokinetics and toxicology. It is also important to estimate the blood/brain distribution.",
      },
      {
        content_type: 'paragraph',
        text: "The calculated % blood binding estimates a compounds' partitioning into the blood cells and binding to plasma proteins. This is important to estimate the blood/brain distribution and calculate log k bb. The blood binding model is based on measured blood binding by equilibrium dialysis and the HPLC based biomimetic measurements for 133 drug discovery compounds[8].9899DesirableModerateUndesirable",
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: '% Brain Tissue Binding (BTB)',
      },
      {
        content_type: 'image-nowrap',
        src: '/img/our-biology/Biomimetics_Parameters_Figure_98_99_dark.svg',
        src_dark:
          '/img/our-biology/Biomimetics_Parameters_Figure_98_99_dark.svg',
        src_light:
          '/img/our-biology/Biomimetics_Parameters_Figure_98_99_light.svg',
        width: 400,
      },
      {
        content_type: 'paragraph',
        text: "BTB is the estimation of the binding of a compound to brain tissue, expressed as a percentage. The model encompasses two key elements: the compound's interaction with an immobilized artificial membrane (log k IAM) and its binding to human serum albumin (log k (HSS)). % BTB <98% suggests reduced interaction with brain tissue and lower risk for potential central nervous system (CNS) side effects. Values between 98 and 99% suggest moderate interaction with brain tissue, which could be appropriate for drugs targeting the CNS without causing excessive binding-related effects. Values 99% suggest extremely high brain tissue binding, which might hinder a drug's distribution to other parts of the body or lead to unwanted CNS effects.",
      },
      {
        content_type: 'paragraph',
        text: 'The %BTB was obtained using a model based on rat brain tissue binding data obtained by equilibrium dialysis for a diverse set of 135 compounds. The model includes the compounds binding to an immobilized artificial membrane (log k IAM) and the human serum albumin binding (log k (HSA)).',
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'Brain to Plasma Ratio (Brain to Plasma Kbbb)',
      },
      {
        content_type: 'paragraph',
        text: "The distribution of a compound between the brain and plasma compartments can be estimated using brain tissue binding and blood binding data. It reflects the relationship between the unbound fraction of the compound present in both the brain and blood. This value does not account for active transport or efflux mechanisms; rather, it is derived from the compound's partition between brain tissue and plasma[8]. Kbbb values >3 indicate that a compound is capable of penetrating the central nervous system (CNS)",
      },

      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'hERG Inhibition',
      },
      {
        content_type: 'image-nowrap',
        src: '/img/our-biology/Biomimetics_Parameters_Figure_5_dark.svg',
        src_dark: '/img/our-biology/Biomimetics_Parameters_Figure_5_dark.svg',
        src_light: '/img/our-biology/Biomimetics_Parameters_Figure_5_light.svg',
        width: 400,
      },
      {
        content_type: 'paragraph',
        text: 'When a compound inhibits the hERG membrane-bound receptor, it may cause serious cardiotoxicity and therefore hERG inhibition is a key measurement conducted during drug discovery. The cardiotoxicity potential can be estimated from compounds binding to phospholipids (CHI IAM) and glycoproteins (log k AGP)[5]. Values greater than 5 indicates cardiotoxicity potential [9].',
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'Volume of distribution L/kg (Vd L/kg)',
      },
      {
        content_type: 'image-nowrap',
        src: '/img/our-biology/Biomimetics_Parameters_Figure_VOD_dark.svg',
        src_dark: '/img/our-biology/Biomimetics_Parameters_Figure_VOD_dark.svg',
        src_light:
          '/img/our-biology/Biomimetics_Parameters_Figure_VOD_light.svg',
        width: 400,
      },
      {
        content_type: 'paragraph',
        text: "Volume of Distribution (Vd) is a pharmacokinetic parameter that estimates how a compound remains in the bloodstream or distributes across various tissue compartments, expressed in liters per kilogram. When at a steady state, Vd illustrates the distribution equilibrium between a compound's presence in the plasma and its penetration into the tissues. It can be modeled by the compound's binding to phospholipids relative to albumin. While an exact optimal value isn't universally defined due to the varying locations of target receptors within the body, Vd L/kg that is small (<5 L/kg) or moderate (between 5 and 10 L/kg) suggests that the drug remains primarily in the bloodstream and has limited distribution to tissues. A substantial Vd L/kg (surpassing 10 to 20 L/kg) indicates an extensive tissue distribution and a prolonged half-life [10].",
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'Drug Efficiency (DE) Max %',
      },
      {
        content_type: 'image-nowrap',
        src: '/img/our-biology/Biomimetics_Parameters_Figure_DE_dark.svg',
        src_dark: '/img/our-biology/Biomimetics_Parameters_Figure_DE_dark.svg',
        src_light:
          '/img/our-biology/Biomimetics_Parameters_Figure_DE_light.svg',
        width: 400,
      },
      {
        content_type: 'paragraph',
        text: "The DE Max% is the maximum drug efficiency determined from the free plasma concentration of a compound relative to the dose, or the maximum free concentration at the site of action. This calculation assumes 100% absorption of the dose and no permeability barrier or active transport that reduces the biophase concentration of the drug at the site of action. It can be estimated from the sum of the compound's affinity for albumin (log k HSA) and phospholipid binding (CHI IAM) [11]. It is the reciprocal value of the unbound volume of distribution (Vdu).  Values of DE max% >1.2% suggest that the compound could have a strong and efficient response, between 1 and 1.2 indicate a moderate level of efficiency and <1% suggests lower drug efficiency.",
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'Drug Efficiency Index (DEI)',
      },
      {
        content_type: 'paragraph',
        text: 'A drug is more efficient when the concentration at the site of action is high, with the smallest possible dose. The Drug Efficiency Index is determined by adding the pIC50 value (negative logarithm of the half-maximal inhibitory concentration) and the logarithm of DEmax (Maximum Drug Efficiency). It is expected to be greater than 1 for potential effective drug candidates. The drug efficiency index indicates the in vivo potency of the compounds. When the drug efficiency is 1%, the DEI equals the pIC 50. Drug efficiency less than 1 means negative log DE, which means the in vivo potency is much less than the in vitro potency [12].',
      },

      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: '% Lung Tissue Binding (LTB)',
      },
      {
        content_type: 'paragraph',
        text: 'Lung tissue binding is influenced by the binding interactions with albumin, glycoproteins, and the overall lipophilicity of the molecules in their neutral form. This value is presented as a percentage, indicating the proportion of the compound that is bound to lung tissue. This metric can be employed to predict the extent to which inhaled compounds are retained in the lungs. A binding percentage exceeding 98% suggests extensive lung retention of the compound, implying prolonged presence within the lung tissue. The lung tissue binding model is based on the equilibrium dialysis measurements with lung homogenate spiked with 32 compounds. It is expressed as a percentage of the compound bound to the lung tissue [8].',
      },

      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'Estimated Skin Partition Coefficient (Kp Skin) and Log Kp Skin',
      },
      {
        content_type: 'image-nowrap',
        src: '/img/our-biology/Biomimetics_Parameters_Figure_Skin_dark.svg',
        src_dark:
          '/img/our-biology/Biomimetics_Parameters_Figure_Skin_dark.svg',
        src_light:
          '/img/our-biology/Biomimetics_Parameters_Figure_Skin_light.svg',
        width: 400,
      },
      {
        content_type: 'paragraph',
        text: 'The estimated skin partition coefficient (Kp Skin) and Log Kp Skin are used to assess how readily a compound can penetrate the skin barrier. The skin partition model is based on the equilibrium dialysis data using spiked human homogenized skin obtained from hospital liposuction. The skin permeability can be modelled by chromatographic lipophilicity (CHI logD 7.4) and the molecular weight of the compounds. Larger and more hydrophilic compounds have limited skin permeability, while lipophilic compounds and compounds that bind strongly to albumin and phospholipids have strong skin absorption.',
      },
      {
        content_type: 'paragraph',
        text: 'Restricted skin permeability occurs when Log Kp skin values are around 0, whereas significant skin absorption generally occurs with Log Kp skin values greater than 0.17. Compounds with moderate skin permeability fall within the range of Log Kp skin values between 0 and 0.17.',
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'Estimated Cell Partition (Kp Cell) and Log Kp Cell',
      },
      {
        content_type: 'image-nowrap',
        src: '/img/our-biology/Biomimetics_Parameters_Figure_Skin_dark.svg',
        src_dark:
          '/img/our-biology/Biomimetics_Parameters_Figure_Skin_dark.svg',
        src_light:
          '/img/our-biology/Biomimetics_Parameters_Figure_Skin_light.svg',
        width: 400,
      },
      {
        content_type: 'paragraph',
        text: 'The cell partition coefficient estimates the partitioning of a compound between cells and their surrounding medium. It is determined by equilibrating compounds with HELA cells. Subsequently, the cells are separated, disrupted, and the concentration of the compound within the disrupted cells is quantified using rapid-fire mass spectrometry [13]. Log Kp cell values >0.17 suggests that the compound has a strong affinity for partitioning into cells, between 0 and 0.17 suggest moderate cellular uptake and if the Kp cell value is less than 0, it indicates that the compound has a weak tendency to partition into cells. The model is based on data from over 300 drug discovery compounds.',
      },
      { content_type: 'line' },
      {
        content_type: 'subtitle',
        text: 'Biomimetic Property Summary',
      },
      {
        content_type: 'image',
        src: '/img/our-biology/Biomimetics_Parameters_Table_2.png',
        width: 800,
      },
    ],
    references: [
      { content_type: 'title', text: 'References' },
      {
        content_type: 'paragraph',
        text: '[1] Bender, A.; Cortés-Ciriano, I., Artificial intelligence in drug discovery: what is realistic, what are illusions? Part 1: Ways to make an impact, and why we are not there yet. Drug Discovery Today 2021, 26 (2), 511-524.',
      },
      {
        content_type: 'paragraph',
        text: '[2] Bunally, S.; Young, R. J., The role and impact of high throughput biomimetic measurements in drug discovery. ADMET and DMPK 2018, 6 (2), 74-84',
      },
      {
        content_type: 'paragraph',
        text: '[3] Bunally, S. B.;  Luscombe, C. N.; Young, R. J., Using Physicochemical Measurements to Influence Better Compound Design. SLAS Discov 2019, 24 (8), 791-801',
      },
      {
        content_type: 'paragraph',
        text: '[4] Caron, G.;  Vallaro, M.;  Ermondi, G.;  Goetz, G. H.;  Abramov, Y. A.;  Philippe, L.; Shalaeva, M., A Fast Chromatographic Method for Estimating Lipophilicity and Ionization in Nonpolar Membrane-Like Environment. Mol. Pharm. 2016, 13 (3), 1100-10',
      },
      {
        content_type: 'paragraph',
        text: '[5] Valkó, K.; Bevan, C.; Reynolds, D.; Chromatographic Hydrophobicity Index by Fast-Gradient RP-HPLC: A High-Throughput Alternative to log P/log D., Anal. Chem. 69 (1997) 2022–2029. doi:10.1021/ac961242d.',
      },
      {
        content_type: 'paragraph',
        text: '[6] Valko, K.; Du, C. M.; Bevan, C.; Reynolds, D. P.; Abraham, M. H., Rapid method for the estimation of octanol/water partition coefficient (Log Poct) from gradient RP-HPLC retention and a hydrogen bond acidity term (∑α2 H), Curr. Med. Chem. 8 (2001) 1137–1146',
      },
      {
        content_type: 'paragraph',
        text: '[7] Young, R. J.; Green, D. V. S. S.; Luscombe, C. N.; Hill, A. P.,Getting physical in drug discovery II: the impact of chromatographic hydrophobicity measurements and aromaticity, Drug Discov. Today. 16 (2011) 822–830. doi:10.1016/j.drudis.2011.06.001',
      },
      {
        content_type: 'paragraph',
        text: '[8] Valko, K., Physicochemical and Biomimetic Properties in Drug Discovery: Chromatographic Techniques for Lead Optimization, Wiley Hoboken NJ, 2014. http://eu.wiley.com/WileyCDA/WileyTitle/productCd-1118152123.html.',
      },
      {
        content_type: 'paragraph',
        text: '[9] Stergiopoulos, C.; Tsopelas, F.; Valko, K., Prediction of hERG inhibition of drug discovery compounds using biomimetic',
      },
      {
        content_type: 'paragraph',
        text: '[10] Hollósy, F.; Valkó, K.; Hersey, A.; Nunhuck, S.; Kéri, G.; Bevan, C., Estimation of volume of distribution in humans from high throughput HPLC-based measurements of human serum albumin binding and immobilized artificial membrane partitioning., J. Med. Chem. 49 (2006) 6958–71. doi:10.1021/jm050957i.',
      },
      {
        content_type: 'paragraph',
        text: '[11] Valkó, K.; Nunhuck, S. B.; Hill, A. P., Estimating unbound volume of distribution and tissue binding by in vitro HPLC-based human serum albumin and immobilised artificial membrane-binding measurements., J. Pharm. Sci. 100 (2011) 849–62. doi:10.1002/jps.22323.',
      },
      {
        content_type: 'paragraph',
        text: '[12] Valko, K.; Chiarparin, E.; Nunhuck, S.; Montanari, D., In vitro measurement of drug efficiency index to aid early lead optimization., J. Pharm. Sci. 101 (2012) 4155–69. doi:10.1002/jps.23305.',
      },
      {
        content_type: 'paragraph',
        text: '[13] Gordon, L. J.; Allen, M.; Artursson, P.; Hann, M. M.; Leavens, B. J.; Mateus, A.; Readshaw, S.; Valko, K.; Wayne, G. J.; West, A., Direct Measurement of Intracellular Compound Concentration by RapidFire Mass Spectrometry Offers Insights into Cell Permeability., J. Biomol. Screen. 21 (2016) 156–64. doi:10.1177/1087057115604141.',
      },
    ],
  },
};
