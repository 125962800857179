import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components/macro';
import useFileImport from '../../useFileImport';
import ColumnFieldPicker from './ColumnFieldPicker';
import RowBuilder from '../../shared/assignColumns/RowBuilder';
import { Header, Row } from '../../shared/RowElements';
import { FlexItem } from '@as_core/elements/flexStyles';
import { TextSmallItalics } from '@as_core/elements/textStyles';
import StationaryModal from '@components/elements/StationaryModal';
import useWindowDimensions from '@as_core/hooks/useWindowDimensions';
import ForwardArrowIcon from '@components/icons/forwardArrow.icon';
import DataMatchingIcon from '@components/icons/dataMatching.icon'
import TextButton from "@components/controls/TextButton";
import { FileImportContext } from '@stores/FileImportContext';
import CheckBox from '@components/controls/CheckBox';
import { RootState } from "@src/store";
import { useSelector, useDispatch } from 'react-redux';
import { setIsGlobalChecked, initializeChildChecked } from '@stores/FileImportContext';
import Alert from '@components/elements/Alert';


interface FieldImportTableProps {
  incrementStep: () => void;
  width?: number;
  height?: number;
  incrementAfterMapping?: boolean;
  fieldFilter?: string;
  forcePick?: number;
}
const debug = false;

const FieldImportTable = (props: FieldImportTableProps) => {
  const {
    incrementStep,
    incrementAfterMapping = false,
    fieldFilter = 'all',
    forcePick = -1
  } = props;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [fieldName, setFieldName] = useState('');
  const { height, width } = useWindowDimensions();
  const { setActiveColumn } = useContext(FileImportContext);
  const isGlobalChecked = useSelector((state: RootState) => state.globalCheckbox.isGlobalChecked);
  const dispatch = useDispatch();

  if (debug) console.log('FieldImportTable | height:', height, ' width:', width);
  const {
    context: { columns },
    clearActiveColumn,
    selectColumn,
    mapFieldToActiveColumn,
    removePickedColumn,
    mapFieldToActiveColumnMatch,
  } = useFileImport();

  // ###########################################################################
  // Already Mapped Columns
  // ###########################################################################
  const [alreadyMappedColumns, setAlreadyMappedColumns] = React.useState<Array<string>>([]);
  const addAlreadyMappedColumn = (field_id) => {
    if (debug) console.log('addAlreadyMappedColumn', field_id);
    const newAlreadyMappedColumns = alreadyMappedColumns;
    newAlreadyMappedColumns.push(field_id);
    setAlreadyMappedColumns(newAlreadyMappedColumns);
  };
  const [bulkMatch, setBulkMatch] = React.useState<boolean>(false);
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const removeAlreadyMappedColumn = (field_id) => {
    if (debug) console.log('removeAlreadyMappedColumn', field_id);
    const newAlreadyMappedColumns = alreadyMappedColumns.filter((f) => f !== field_id);
    if (debug) console.log('removeAlreadyMappedColumn (new)', newAlreadyMappedColumns);
    setAlreadyMappedColumns(newAlreadyMappedColumns);
  };

  // ###########################################################################
  // Modal
  // ###########################################################################
  const modalHeight = 500;
  const modalWidth = 900;
  if (debug) console.log('ColumnFieldPicker | modalHeight:', modalHeight, ' modalWidth:', modalWidth);
  // ###########################################################################
  // DeSelection Actions
  // ###########################################################################
  const handleColumnDeselect = (columnIdx) => {
    if (debug) console.log('FieldImportTable handleColumnDeselect', columnIdx, columns[columnIdx]);
    const field_id = columns[columnIdx].field_id;
    removePickedColumn(columnIdx);
    removeAlreadyMappedColumn(field_id);
    dispatch(setIsGlobalChecked(false));
  };

  const handleColumnSelected = (columnIdx) => {
    if (debug) console.log('handleColumnSelected');
    selectColumn({ columnIdx, fieldFilter });
    setModalOpen(true);
    if (incrementAfterMapping) {
      incrementStep();
    }
  };

  const handleColumnMapped = (field_id) => {
    
    field_id = typeof field_id === 'object' ? field_id['field_id'] :field_id;
    addAlreadyMappedColumn(field_id);
    mapFieldToActiveColumn(field_id);
    clearActiveColumn();
    setModalOpen(false);
    setFieldName(field_id['field_id']);
    if (fieldFilter === 'Identity') {
      incrementStep();
    }
  };

  const handleColumnMappedMatch = ({ field_id, columnIdx }) => {
    selectColumn({ columnIdx, fieldFilter });
    setActiveColumn(columnIdx);
    addAlreadyMappedColumn(field_id);
    mapFieldToActiveColumnMatch(field_id, columnIdx);
    clearActiveColumn();
    if (fieldFilter === 'Identity') {
      incrementStep();
    }
  };

  useEffect(() => {
    if (forcePick > 0) {
      selectColumn({ columnIdx: forcePick, fieldFilter });
      setModalOpen(true);
    }
  }, [forcePick]);

  useEffect(() => {
    const numberOfChildren = columns?.length || 0;
    if (numberOfChildren > 0) {
      dispatch(initializeChildChecked(numberOfChildren)); // Initialize the child checkboxes based on the number of columns
    }
  }, [columns, dispatch]);

  if (debug) console.log('FieldImportTable | columns:', columns);


  const handleGlobalChange = (value: boolean) => {
    dispatch(setIsGlobalChecked(value));
  };

  const handleBulkMatch = () => {
    if (fieldFilter !== "Identity") {
      setBulkMatch(true)
    }
    else {
      setAlertOpen(true);
    }
  }

  const handleAlert = () => {
    setAlertOpen(false);
  };



  return (
    <Container>
      {fieldFilter === 'Identity' ? (
        <FlexItem>
          <TextSmallItalics color={'accentSecondary'}>
            Selected identity joins new data to reference or previously uploaded data
          </TextSmallItalics>
        </FlexItem>
      ) : null}
      {forcePick < 0 ? (
        <>
          <ScrollContainer height={285}>
            <Alert
              type={'general'}
              title={'Alert ID'}
              message={'Please select at least one identity column.'}
              alertOpen={alertOpen}
              closeAlert={handleAlert}
            />
            <Table width={width}>
              <tbody>
                <Row>
                  <Header parent="compoundSet">
                    <CheckBox
                      selected={isGlobalChecked}
                      onChange={handleGlobalChange}
                      disabled={false}
                    />
                  </Header>
                  <Header>File Columns</Header>
                  <Header>
                    {fieldFilter === 'all'
                      ? 'Map to 3RnD Properties'
                      : 'Identities'}
                  </Header>
                  <Header>
                    Automatic Match
                  </Header>

                </Row>
                <RowBuilder
                  fieldFilter={fieldFilter}
                  onSubmit={handleColumnSelected}
                  onDeselect={handleColumnDeselect}
                  onColumnPicked={handleColumnMappedMatch}
                  startMatching={bulkMatch}
                  setStartMatching={setBulkMatch}
                  mapped_FieldName={fieldName}
                />
              </tbody>
            </Table>
          </ScrollContainer>
          <ContainerBottomButton>
            {fieldFilter === 'Identity' ? null :
              <ButtonContainer>
                <TextButton
                  text={'Done with Mapping'}
                  height={30}
                  width={200}
                  onClick={() => incrementStep()}
                  icon={<ForwardArrowIcon />}
                />
              </ButtonContainer>
            }
            {!isGlobalChecked ? null :

              <ButtonContainer>
                <TextButton
                  text={'Bulk Match'}
                  height={30}
                  width={200}
                  onClick={handleBulkMatch}
                  icon={<DataMatchingIcon />}
                />
              </ButtonContainer>
            }
          </ContainerBottomButton>

          <StationaryModal
            title='Mapping of Imported Columns to 3RnD Fields'
            modalOpen={modalOpen}
            onCloseClick={() => setModalOpen(false)}
            opacity={1}
            width={modalWidth}
            height={modalHeight}
          >
            <ColumnFieldPicker
              height={height}
              width={width}
              onColumnPicked={handleColumnMapped}
              selectedColumns={alreadyMappedColumns}
            />
          </StationaryModal>
        </>
      ) : (
        <StationaryModal
          title='Mapping of Imported Columns to 3RnD Fields'
          modalOpen={modalOpen}
          onCloseClick={() => setModalOpen(false)}
          opacity={1}
          width={modalWidth}
          height={modalHeight}
        >
          <ColumnFieldPicker
            height={modalHeight}
            width={modalWidth}
            onColumnPicked={handleColumnMapped}
          />
        </StationaryModal>
      )}
    </Container>
  );
};

export default FieldImportTable;

const Container = styled.div<{ height?: number; width?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ContainerBottomButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 10px;
  margin-left: auto;
`;
const ScrollContainer = styled.div<{ height?: number }>`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: calc(100vh - ${(p) => p.height}px);
  width: 100%;
  border-radius: 15px;
  -ms-overflow-style: none; /* IE and Edge */
  & ::-webkit-scrollbar {
    display: none;
  }
`;

const Table = styled.table<{ height?: number; width?: number }>`
  table-layout: auto;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
`;
