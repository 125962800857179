import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PageContainer from '@as_core/pages/PageContainer';

import Favorites from '@components/compounds/favorites/Favorites';
import { RootState } from '@src/store';
import {
  addCompoundView,
  CompoundViewItemT,
  removeCompoundView,
} from '@stores/compoundViews';
import { getCompoundViews } from '@stores/compoundViews';
import useCognito from '@as_core/account/useCognito';
import { MenuContext } from '@stores/MenuContext';

const PgCompoundsFavorites = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getToken } = useCognito();
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);

  useEffect(() => {
    if (activeMenuId !== 'favorites') setActiveMenuId('favorites');
  }, [activeMenuId]);

  const compoundViews = useSelector((state: RootState) => state.compoundViews);

  const handleAddFavorite = (favorite: CompoundViewItemT) => {
    dispatch(addCompoundView(getToken(), favorite));
  };

  const handleSelectFavorite = (favId: string) => {
    navigate(`/favorites/${favId}/table`);
  };

  const handleDeleteFavorite = (favId: string) => {
    dispatch(removeCompoundView(getToken(), favId));
  };

  useEffect(() => {
    dispatch(getCompoundViews(getToken()));
  }, [dispatch]);

  return (
    <PageContainer title='Favorites List'>
      <Favorites
        title='Favorites'
        compoundViews={compoundViews}
        handleAddFavorite={handleAddFavorite}
        appendFavorite={handleSelectFavorite}
        handleDeleteFavorite={handleDeleteFavorite}
      />
    </PageContainer>
  );
};

export default PgCompoundsFavorites;
