import React from 'react';
import styled from 'styled-components/macro';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

interface SingleSelectRendererProps {
  getValue: () => string;
  getOptions: () => string[];
  onChange: (event: SelectChangeEvent<unknown>) => void;
}

const SingleSelectRenderer = (props: SingleSelectRendererProps) => {
  const { getValue, getOptions, onChange } = props;

  return (
    <Container>
      <StyledFormControl>
        <StyledSelect
          variant={'filled'}
          value={getValue()}
          onChange={onChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value=''>-</MenuItem>
          {getOptions().map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </Container>
  );
};

export default SingleSelectRenderer;

const Container = styled.div`
  border-radius: ${(p) => p.theme.borderRadius};
  border: ${(p) => p.theme.borders.thin };
`;

const StyledFormControl = styled(FormControl)`
  color: ${(props) => props.theme.palette.textPrimary};
  margin: ${(props) => props.theme.sizes.xsmall};
  min-width: ${(props) => props.theme.sizes.xsmall};

  & .MuiSelect-icon {
    right: -${(p) => p.theme.sizes.xsmall};
    background-color: ${(p) => p.theme.palette.backgroundSecondary};
    border-radius: ${(p) => p.theme.borderRadius};
    color: ${(props) => props.theme.palette.accentPrimary};
  }
`;

const StyledSelect = styled(Select)``;
