import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import PageContainer from '@as_core/pages/PageContainer';
import { MenuContext } from '@stores/MenuContext';
import Info from '@components/pages/infoFaq/Info';
import Faq from '@components/pages/infoFaq/Faq';
import UploadExamples from '@components/pages/infoFaq/UploadExamples';
import { useNavigate, useParams } from 'react-router-dom';
import { FlexBanner } from '@as_core/elements/flexStyles';
import HeaderMenu from '@as_core/controls/HeaderMenu';

type actionT = {
  action: string;
  label: string;
};

const MENU_ACTIONS: Array<actionT> = [
  { action: 'upload', label: 'Data Upload/Plotting Examples' },
  { action: 'overview', label: '3RnD Overview' },
  { action: 'faq', label: 'FAQ - Frequently Asked Questions' },
];

const PgInfo = () => {
  let { action } = useParams();
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (activeMenuId !== 'info') setActiveMenuId('info');
  }, [activeMenuId]);

  const handleMenuClick = (a) => {
    navigate(`/info/${a}`);
  };

  return (
    <PageContainer title='Info/FAQ/Videos' padding={0}>
      <FlexBanner>
        {MENU_ACTIONS.map((m, index) => (
          <HeaderMenu
            key={`headermenu_info_${index}`}
            label={m.label}
            isActive={m.action === action}
            onClick={() => handleMenuClick(m.action)}
          />
        ))}
      </FlexBanner>
      <FaqBody>
        {action === 'overview' ? (
          <Info />
        ) : action === 'faq' ? (
          <Faq />
        ) : (
          <UploadExamples />
        )}
      </FaqBody>
    </PageContainer>
  );
};

export default PgInfo;

const FaqBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
