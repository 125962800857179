import React, { useState, Fragment } from 'react';
import styled from 'styled-components/macro';
import { ufromNow } from '@utils/misc';
import { v4 as uuidv4 } from 'uuid';

import {
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  ListItemText,
  Badge,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import DeleteSnackbar from './DeleteSnackbar';
import {
  CompoundViewItemT,
  CompoundViewsT,
  CompoundViewTypeT,
} from '@stores/compoundViews';
import UploadIcon from '@components/icons/upload.icon';
import StarIcon from '@components/icons/star.icon';
import ErrorMessages, { MessageItem } from '@as_core/elements/UserMessages';
import InputField from '@as_core/components/inputs/InputField';

type IFavorites = {
  title: string;
  compoundViews: CompoundViewsT;
  appendFavorite: (favId: string) => void;
  handleAddFavorite?: (favorite: CompoundViewItemT) => void;
  handleDeleteFavorite?: (favId: string) => void;
  handleAddAndSelect?: (favItem: CompoundViewItemT) => void;
};

const Favorites = (props: IFavorites) => {
  // FAVORITES DATA
  const {
    compoundViews,
    appendFavorite,
    handleAddAndSelect,
    handleAddFavorite,
    handleDeleteFavorite,
  } = props;

  // UNDO FUNCTIONALITY
  const [undoOpenState, setUndoOpenState] = React.useState(false);
  const [undoItem, setUndoItem] = React.useState<CompoundViewItemT | null>();
  const handleUndoClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setUndoOpenState(false);
  };
  const handleUndoDelete = () => {
    handleAddFavorite(undoItem);
    setUndoItem(null);
    setUndoOpenState(false);
  };
  const _handleDeleteFavorite = (favId) => {
    setUndoItem(compoundViews.views[favId]);
    setUndoOpenState(true);
    handleDeleteFavorite(favId);
  };

  // New Favorite
  const [newFavoriteName, setNewFavoriteName] = useState('');
  const handleChangeFavoriteName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewFavoriteName(event.target.value);
  };

  const handleSubmitNewFavorite = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.keyCode === 13) {
      const favItem: CompoundViewItemT = {
        uuid: uuidv4(),
        title: newFavoriteName,
        compoundIds: [],
        fieldIds: [],
        viewType: CompoundViewTypeT.Finite,
      };
      handleAddAndSelect(favItem);
      setNewFavoriteName('');
    }
  };

  return (
    <>
      {compoundViews && compoundViews.viewIds.length > 0 ? (
        <Container>
          {/* Used for undo functionality */}
          <DeleteSnackbar
            openState={undoOpenState}
            handleClose={handleUndoClose}
            handleUndo={handleUndoDelete}
          />
          {/* Create New List */}
          {handleAddAndSelect ? (
            <InputField
              id='standard-name'
              label='New List'
              value={newFavoriteName}
              onChange={handleChangeFavoriteName}
              onKeyDown={handleSubmitNewFavorite}
            />
          ) : (
            <></>
          )}
          {compoundViews.viewIds.length > 1 ? (
            <Center>
              <ListContainer>
                <List>
                  {compoundViews.viewIds
                    .filter(
                      (id) =>
                        compoundViews.views[id].viewType ===
                        CompoundViewTypeT.Finite
                    )
                    .map((favId) => {
                      const _modifiedOn =
                        compoundViews.views[favId].modifiedOn || '';
                      return (
                        <ListItem
                          key={compoundViews.views[favId].uuid}
                          button
                          onClick={() => appendFavorite(favId)}
                        >
                          <Badge
                            badgeContent={
                              compoundViews.views[favId].compoundCount
                            }
                            max={99}
                            color='primary'
                          >
                            <BookmarkIcon />
                          </Badge>
                          <MListItemText
                            primary={compoundViews.views[favId].title}
                            secondary={`changed: ${ufromNow(_modifiedOn)}`}
                          />
                          {handleDeleteFavorite != null ? (
                            <ListItemSecondaryAction
                              onClick={() => _handleDeleteFavorite(favId)}
                            >
                              <IconButton edge='end' aria-label='delete'>
                                <DeleteIcon color='primary' />
                              </IconButton>
                            </ListItemSecondaryAction>
                          ) : (
                            <Fragment />
                          )}
                        </ListItem>
                      );
                    })}
                </List>
              </ListContainer>
            </Center>
          ) : (
            <StyledErrorMessages
              messages={[
                <MessageItem key='message-item-1' color='accentSecondary'>
                  No compound favorite lists saved.{' '}
                </MessageItem>,
                <MessageItem key='message-item-2'>
                  Create a new list from the compound tables.
                </MessageItem>,
              ]}
            />
          )}
        </Container>
      ) : (
        <ErrorMessages
          messages={[
            <MessageItem key='message-item-3' color='accentSecondary'>
              2# - No compound favorite lists available.{' '}
            </MessageItem>,
            <MessageItem key='message-item-4'>
              Use the <StarIcon /> in the Compound Table view to create lists of
              favorites.
            </MessageItem>,
            <MessageItem key='message-item-4'>
              Uploading <UploadIcon /> compounds will also create a favorites
              list of these uploaded compounds.
            </MessageItem>,
          ]}
        />
      )}
    </>
  );
};

export default Favorites;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 400px;
`;

const Center = styled.div`
  display: flex;
  width: 60%;
  min-width: 600px;
`;

const ListContainer = styled.div`
  margin-top: ${(p) => p.theme.sizes.small};
  padding: ${(p) => p.theme.sizes.small};
  width: 100%;
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  max-height: 75vh;
  overflow: auto;
`;

const MListItemText = styled(ListItemText)`
  && {
    padding-left: ${(p) => p.theme.sizes.small};
  }
`;

const StyledErrorMessages = styled(ErrorMessages)`
  margin-top: 10px;
  width: 400px;
`;
