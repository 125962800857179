import React, {
  useContext,
  ReactNode,
  useEffect,
  useState,
  useRef,
} from 'react';
import {UserContext, UserEmpty} from '@stores/UserContext';
import { APP_CONFIG } from "@app_config/app";
import * as AWSCognitoIdentity from 'amazon-cognito-identity-js';
import useCognito from '@as_core/account/useCognito';
import { useNavigate } from 'react-router-dom';
import Alert from '@components/elements/Alert';

import useUserRegistrationInfo from "@utils/useUserRegistrationInfo";

/*
  Primary component to handle the user authentication and inactivity states
  Parameters:
    CHECK_AUTHENTICATION_PERIOD: time interval for the app to recheck inactivity and time since authenticated
 */
const CHECK_AUTHENTICATION_PERIOD: number = 60 * 1000; // in milliseconds = 1 minute

interface AuthenticatorProps {
  children: ReactNode;
}

const debug: boolean = false;
const AuthWrapper: React.FC<AuthenticatorProps> = ({ children }) => {
  const { logout, isTokenExpired, getAuthRoles } = useCognito();
  const { getUserRegistrationInfo } = useUserRegistrationInfo();
  const [isUserRegistrationLoading, setIsUserRegistrationLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const { user, setUser } = useContext(UserContext);
  // console.log('AuthWrapper | pathname', location.pathname);

  // need to use references for functions so do not have stale states
  const isAuthenticatedRef = useRef(user?.isAuthenticated);
  const isLoadingRef = useRef(user?.isLoading);
  const userSessionRef = useRef(user?.authSession);
  isAuthenticatedRef.current = user.isAuthenticated;
  userSessionRef.current = user.authSession;
  isLoadingRef.current = user.isLoading;

  if (debug)
    console.log(
      'AuthWrapper | .isLoading',
      isLoadingRef.current,
      '.isAuthenticated',
      isAuthenticatedRef.current,
      '.isEmailVerified',
      user.isEmailVerified,
      '.isRegistered',
      user.isRegistered,
      'alertOpen',
      alertOpen
    );

  const clearUser = () => {
    setUser(UserEmpty);
  };

  const getCurrentCognitoUser = () => {
    let cognitoUser = null;
    const poolData = {
      UserPoolId: APP_CONFIG.cognitoUserPoolId,
      ClientId: APP_CONFIG.cognitoClientId,
    };
    let userPool = null;
    try {
      userPool = new AWSCognitoIdentity.CognitoUserPool(poolData);
    } catch {
      alert('getUserPool error');
      console.error('getUserPool error');
      return null;
    }
    if (userPool === null) return null;
    try {
      cognitoUser = userPool.getCurrentUser();
      if (debug) console.log('cognitoUser:', cognitoUser);
      return cognitoUser
    } catch {
      alert('getCurrentUser error');
      console.error('getCurrentUser error');
      return null;
    }
  }

  // action to take when user inactive or too long since authentication - dhr
  const logoutActionEmail = (authEmail: string, showAlert: boolean) => {
    if (debug) console.log(
      'logout Action by email triggered | authEmail',
      authEmail,
      new Date().toLocaleString()
    );
    if (showAlert) setAlertOpen(true);
    if (authEmail) logout(authEmail);
    clearUser();
    navigate('/user/login');
  };

  const logoutActionSession = (
    session: AWSCognitoIdentity.CognitoUserSession,
    showAlert: boolean
  ) => {
    if (debug) console.log(
      'logout Action by session triggered | session',
      session,
      new Date().toLocaleString()
    );
    const authEmail = session?.getIdToken().payload?.email;
    logoutActionEmail(authEmail, showAlert);
  };

  const checkAuthenticationStatus = () => {
    const timestamp = new Date().getTime() / 1000;
    if (debug)
      console.log(
        'checkActiveAuthentication isAuthenticated: ',
        isAuthenticatedRef.current,
        ' isTokenExpired: ',
        isTokenExpired(userSessionRef.current),
        ' current date:',
        new Date().toLocaleString(),
        ' current datetime: ',
        Math.floor(timestamp)
      );
    if (isAuthenticatedRef.current) {
      if (user.isEmailVerified && isTokenExpired(userSessionRef.current)) {
        if (debug) console.log('tokenExpired --- forcing logout', new Date().toLocaleString());
        logoutActionSession(userSessionRef.current, true);
      }
    }
  };

  // Set the event timer for checking user token not expired;
  useEffect(() => {
    const timerId = setInterval(
      checkAuthenticationStatus,
      CHECK_AUTHENTICATION_PERIOD
    );
    return () => clearInterval(timerId);
  }, []);

  // If user not authenticated (first time) -- check to see if user has active session using cognito
  // -- needed for the redirects back from Stripe (or other applications)
  useEffect(() => {
    if (!isAuthenticatedRef.current) {
      let cognitoUser = getCurrentCognitoUser();
      if (cognitoUser !== null) {
        // only reload if not longer than authentication timeout
        cognitoUser.getSession(function (
          err: { message: string },
          session: AWSCognitoIdentity.CognitoUserSession
        ) {
          if (debug) console.log('AuthWrapper | session', session);
          setUser((prev) => ({
            ...prev,
            authId: cognitoUser.username,
            authEmail: session.getIdToken().payload['email'],
            authSession: session
          }));
          if (err) {
            alert('cognitoUser.getSession error: ' + err);
            console.error('cognitoUser.getSession error', err);
            clearUser();
            return;
          } else {
            if (debug) console.log('Reloading user cognito session for user', session);
            if (isTokenExpired(session)) {
              console.log(
                'Expiring user authentication state --- tokenExpired ...',
                session,
                new Date().toLocaleString()
              );
              clearUser();
              return;
            } else {
              setUser((prev) => ({
                ...prev,
                isAuthenticated: true,
                isEmailVerified: true
              }));
              const userId = session.getIdToken().payload;
              const token = session.getAccessToken().getJwtToken();
              // const token = session.getIdToken().getJwtToken(); // force failure
              if (!isUserRegistrationLoading) {
                setIsUserRegistrationLoading(true);
                getUserRegistrationInfo(token, {}).then((userRegInfo) => {
                  if (debug) console.log('AuthWrapper | getUserRegistrationInfo {userRegInfo}:', userRegInfo);
                  setUser((prev) => ({
                    ...prev,
                    isLoading: false,
                    authId: userId['cognito:username'],
                    authEmail: userId.email,
                    authSession: session,
                    authRoles: getAuthRoles(session),
                    isRegistered: true,
                    regInfo: userRegInfo,
                  }));
                  setIsUserRegistrationLoading(false);
                }).catch((error)=>{
                  // only alert if it appears to be a different error message
                  if (error !== 'user not registered') {
                    console.error('AuthWrapper | getUserRegistrationError | error ', error);
                    alert('Issue loading user registration information -- system outage? \n' + error + '\nTry again later');
                  }
                  setUser((prev) => ({
                    ...prev,
                    isLoading: false,
                    isAuthenticated: false,
                    isRegistered: false
                  }));
                  setIsUserRegistrationLoading(false);
                  navigate('/user/register');
                });
              }
            }
          }
        });
      } else {
        setUser((prev) => ({
          ...prev,
          isLoading: false,
        }));
      }
    } else {
      setUser((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  }, [user.isLoading]);

  return (
    <>
      {children}
      <Alert
        type={'logout'}
        alertOpen={alertOpen}
        closeAlert={() => setAlertOpen(false)}
      />
    </>
  );
};

export default AuthWrapper;
