import React, { useEffect, useState, useContext } from 'react';
import { APP_CONFIG } from '@app_config/app';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import MenuButton from '@components/elements/MenuButton';
import {MenuContext} from "@stores/MenuContext";
import {UserContext} from "@stores/UserContext";
import {getUserSubscriptionType} from "@utils/useUserAppInfo";
import {MenuItemsT} from "@app_config/sideMenu";

interface PropsI {
  menu: MenuItemsT[];
  isAuthorized: boolean;
}

const debug = false;
// Side Menu -- changes whether the user is logged in or not
const SideNav = (props:PropsI) => {
  const { menu, isAuthorized } = props;
  const [activeMenus, setActiveMenus] = useState([]);
  const { user } = useContext(UserContext);
  const { subscription } = user.appInfo;
  const { subApp } = user;
  const { activeMenuId } = useContext(MenuContext);
  if (debug) console.log('SideNav | isAuthorized:', isAuthorized, 'subscription', subscription, 'subApp', subApp);

  useEffect(() => {
    const subscriptionType = getUserSubscriptionType(user.appInfo);
    const filtered = menu
      .filter((m) => !m?.subscriptions || m?.subscriptions.includes(subscriptionType))
      .filter((m) => !m?.subApp || m.subApp.includes(subApp));
    setActiveMenus(filtered);
  }, [menu, subscription, subApp]);
  if (debug) console.log('SideNav | activeMenus:', activeMenus);

  return (
    <Container>
      <Menu>
        <LogoItem>
          <img src={APP_CONFIG.logo} alt='Logo' width={32} />
        </LogoItem>
        <SideMenu>
          <SideMenuItems>
            {activeMenus.map((menuItem, menuId) => {
              return (
                <Link to={menuItem.pathname} key={menuId}>
                  <MenuButton
                    text={menuItem.name}
                    icon={menuItem.icon}
                    isActive={menuItem.id === activeMenuId}
                    tooltipPlacement='right'
                  />
                </Link>
              );
            })}
          </SideMenuItems>
        </SideMenu>
      </Menu>
    </Container>
  );
};

export default SideNav;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 50px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 0;
  margin: 0;
  background-color: ${(p) => p.theme.palette.backgroundTertiary};

  /* z-index: 105; */
  & ::-webkit-scrollbar {
    height: 0;
    width: 0;
  } /* hide scrollbar chrome */
`;

const LogoItem = styled.div`
  display: flex;
  margin-top: 5px;
  margin-left: 5px;
  height: 50px;
  justify-items: center;
`;

const SideMenu = styled.div`
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  flex: none;
  flex-grow: 0;
`;

const SideMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  overflow-y: scroll;
  & ::-webkit-scrollbar {
    display: none;
  }
`;

const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  & ::-webkit-scrollbar {
    display: none;
  }
`;
