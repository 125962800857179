export type FormFieldsT = {
  label: string;
  value: string;
  type?: string;
  validator?: string;
  width?: string;
}
export const userFormFields: Array<FormFieldsT[]> = [
  [
    {
      label: 'Email Address',
      value: 'email',
      type: 'string',
      validator: 'email',
    },
  ],
  [
    {
      label: 'Password',
      value: 'password',
      type: 'password',
      validator: 'password',
    },
  ],
  [
    { label: 'First Name', value: 'name.first', type: 'string', width: '40%' },
    { label: 'Surname', value: 'name.last', type: 'string', width: '60%' },
  ],
  [
    {
      label: 'Use this address for billing',
      value: 'address.billing',
      type: 'boolean',
    },
  ],
  [{ label: 'Street Address', value: 'address.address', type: 'string' }],
  [
    { label: 'Town/City', value: 'address.city', type: 'string', width: '50%' },
    {
      label: 'Postcode/Zip Code',
      value: 'address.postalCode',
      type: 'string',
      width: '50%'
    },
  ],
  [
    {
      label: 'State/Province',
      value: 'address.state',
      type: 'string',
      width: '60%',
    },
    {
      label: 'Country',
      value: 'address.country',
      type: 'country',
    },
  ],
  [
    {
      label: 'Company/Institute Name',
      value: 'organization.name',
      type: 'organization',
    },
  ],
];

export const userCreateAccountFields: Array<FormFieldsT[]> = [
  [
    {
      label: 'Email Address',
      value: 'email',
      type: 'string',
      validator: 'email',
    },
  ],
  [
    {
      label: 'Password',
      value: 'password',
      type: 'password',
      validator: 'password',
    },
  ]
];

export const errorMessages = {
  invalidEmailFormat: 'Not valid email - entered incorrectly',
  invalidPasswordLength:
    'Password too short - should have minimum 8 characters',
  server: 'Server error - check console',
};

export const passwordRequirements = [
  '8 characters minimum',
  'At least one lowercase',
  'At least one uppercase',
  'At least one number',
  'At least one special character',
];

export const registerTooltipInfo =
  'An account is required to store your SciScore credits and deliver your reports. This same account allows access to other AsedaSciences applications and services such as 3RnD.';
