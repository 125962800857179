import React from 'react';
import { KeyColor } from '@plotting/single-plot-view/plot.types';
import { SCHEMES } from '@utils/scales/color/ColorSchemes';
import { scaleOrdinal } from 'd3-scale';
import { VariableSelect } from './VariableSelect';

type ColorPaletteSelectProps = {
  values: KeyColor[];
  onChange?: (colors: KeyColor[]) => void;
};

export const DiscreteColorPaletteSelect = ({
  values,
  onChange,
}: ColorPaletteSelectProps) => {
  const availablePalettes = SCHEMES['discrete'].map(p => ({
    ...p,
    label: p.name,
    value: p.name
  }));

  const allGroups = values.map((group) => group.id);

  const updateScheme = (newPaletteName: string) => {
    const newPalette = availablePalettes.find(
      (pal) => pal.name === newPaletteName
    );

    const colorScale = scaleOrdinal<string>()
      .domain(allGroups.map((g) => String(g)))
      .range(newPalette.scheme);

    const newKeyColors: KeyColor[] = values.map((group) => {
      return {
        id: group.id,
        color: colorScale(group.id),
      };
    });

    onChange(newKeyColors);
  };

  return (
    <VariableSelect
      id={'palette-selector'}
      onChange={updateScheme}
      options={availablePalettes}
      label='Color Palette'
      value={undefined}
    />
  );
};
