import React from 'react';
import styled from 'styled-components/macro';
import { TextField } from '@mui/material';
import { FocusEventHandler, ReactNode } from 'react';

export interface IInputField {
  value?: string | number;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: (event?: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: (
    event?: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  label?: string;
  placeholder?: string;
  marginTop?: number;
  type?: string;
  error?: boolean;
  autoComplete?: string;
  name?: string;
  id?: string;
  size?: 'medium' | 'small';
  className?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  multiline?: boolean;
  rows?: number;
  InputProps?: object;
  select?: boolean;
  children?: ReactNode;
  margin?: 'dense' | 'none' | 'normal';
  fullWidth?: boolean;
  disabled?: boolean;
}

const InputField = (props: IInputField) => {
  const { placeholder, autoComplete, className } = props;

  return (
    <TextFieldContainer className={className}>
      <StyledTextField
        {...props}
        placeholder={placeholder || 'Enter value ...'}
        autoComplete={autoComplete || 'chrome-off'}
      >
        {props.children}
      </StyledTextField>
    </TextFieldContainer>
  );
};

export default InputField;

const TextFieldContainer = styled.div<{ marginTop?: number }>`
  width: 100%;
  display: flex;
`;

const StyledTextField = styled(TextField)`
  width: 100%;

  & .MuiInputBase-root {
    // match the border radius around the input field
    border-radius: 10px;
    font-size: 14px;
  }

  & .MuiOutlinedInput-input {
    background: transparent;
  }

  & .MuiFormLabel-root {
    // set a smaller label (no input) to adjust to reduced padding
    font-size: 14px;
  }

  & .MuiOutlinedInput-input::placeholder {
    font-size: 12px;
    font-style: italic;
  }
  // Handle the autofill color
  & :-webkit-autofill,
  & :-webkit-autofill:hover,
  & :-webkit-autofill:focus,
  & :-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
  }
`;
