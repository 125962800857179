import React from 'react';

import SciScoreLogo from '@components/icons/sciscore.logo.icon';
import DashboardIcon from '@components/icons/dashboard.icon';
import CompoundsIcon from '@components/icons/compounds.icon';
import MyCompoundsIcon from '@components/icons/myCompounds.icon';
import FavoritesIcon from '@components/icons/favorites.icon';
import UploadIcon from '@components/icons/upload.icon';
import GraphIcon from '@components/icons/graph.icon';
import NewCompoundIcon from '@components/icons/newCompound.icon';
import CompoundUniverseIcon from '@components/icons/compoundUniverse.icon';
import MarketIcon from '@components/icons/market.icon';
import OurBiologyIcon from '@components/icons/ourBiology.icon';
import OasisLogo from "@components/icons/oasis.logo";
import HgncIcon from "@components/icons/hgnc.icon";
import NaturalProductsIcon from "@components/icons/naturalProducts.icon";
import CustomCurationIcon from "@components/icons/customCuration.icon";
import ThirdPartyDataIcon from "@components/icons/thirdPartyData.icon";
import ThirdPartyAppsIcon from "@components/icons/thirdPartyApps.icon";
import {HomeTileCardT} from "@as_core/tiles/HomePageTiles";

const TILE_ICON_HEIGHT = 50

const homeCards: HomeTileCardT[] = [
  {
    id: '3RnD_OurBiology',
    title: 'OurBiology',
    newTab: false,
    icon: <OurBiologyIcon size={TILE_ICON_HEIGHT} />,
    link: '/biology/systemetric/summary',
    description: 'Description of the various Biological Assays',
    isPinned: false,
  },
  {
    id: '3RnD_Dashboard',
    title: '3RnD Dashboard',
    newTab: false,
    icon: <DashboardIcon size={TILE_ICON_HEIGHT} />,
    link: '/dashboard',
    description:
      'Summary views of the AsedaSciences compounds',
    isPinned: false,
  },
  {
    id: '3RnD_OrderAssays',
    title: 'Order Screens/Assays',
    newTab: false,
    icon: <OurBiologyIcon size={TILE_ICON_HEIGHT} />,
    link: '/orders/create',
    description: 'Access novel assays to improve the understanding of toxicity and bio-availability.',
    isPinned: false,
  },
  {
    id: '3RnD_CompoundMarketplace',
    title: 'Compound Marketplace',
    newTab: false,
    icon: <MarketIcon size={TILE_ICON_HEIGHT} />,
    link: '',
    description: 'Search and Order compounds as reference or SAR starting points.',
    isPinned: false,
  },
  {
    id: 'AS_Website',
    title: 'AsedaSciences Website',
    newTab: true,
    icon: (
      <img src='/img/AsedaSciences_logo.svg' alt='AsedaSciences' width={TILE_ICON_HEIGHT} />
    ),
    link: 'https://asedasciences.com',
    description: 'Access AsedaSciences Company and Product information',
    isPinned: false,
  },
  {
    id: 'AS_SciScore',
    title: "SciScore",
    newTab: true,
    icon: <SciScoreLogo size={TILE_ICON_HEIGHT} />,
    link: "https://sciscore-app.asedasciences.com",
    description: "Score methods section of a manuscript before submission",
    isPinned: false,
  },
  {
    id: '3RnD_MyCompounds',
    title: 'My Compounds',
    newTab: false,
    icon: <MyCompoundsIcon size={TILE_ICON_HEIGHT} />,
    link: '/compounds/user/table',
    description: 'Table/Tile view of My Compounds',
    enable: ['academic', 'commercial', 'pilot'],
    isPinned: false,
  },
  {
    id: '3RnD_Favorites',
    title: 'Favorites',
    newTab: false,
    icon: <FavoritesIcon size={TILE_ICON_HEIGHT} />,
    link: '/compounds/favorites',
    description: 'Lists of saved favorite compounds',
    restrict: ['basic'],
    isPinned: false,
  },
  {
    id: '3RnD_UploadData',
    title: 'Upload Data',
    newTab: false,
    icon: <UploadIcon size={TILE_ICON_HEIGHT} />,
    link: '/import/upload',
    description: 'Upload data for plotting, analysis and visualizations',
    enable: ['academic', 'commercial', 'pilot'],
    isPinned: false,
  },
  {
    id: '3RnD_ASCompounds',
    title: 'AsedaSciences Cpds',
    newTab: false,
    icon: <CompoundsIcon size={TILE_ICON_HEIGHT} />,
    link: '/compounds/aseda/table',
    description: 'Table/Tile view of AsedaSciences assayed compounds',
    restrict: ['basic'],
    isPinned: false,
  },
  {
    id: '3RnD_MyPlots',
    title: 'My Plots',
    newTab: false,
    icon: <GraphIcon size={TILE_ICON_HEIGHT} />,
    link: '/plots',
    description: 'Edit or create new plotting',
    enable: ['academic', 'commercial', 'pilot'],
    isPinned: false,
  },
  {
    id:'3RnD_DrawCompounds',
    title: 'Draw Compounds',
    newTab: false,
    icon: <NewCompoundIcon size={TILE_ICON_HEIGHT} />,
    link: '/compounds/add',
    description: 'Add new compounds to your compound library',
    restrict: ['basic'],
    isPinned: false,
  },
  {
    id: '3RnD_CompoundUniverse',
    title: 'Compound Universe',
    newTab: false,
    icon: <CompoundUniverseIcon size={TILE_ICON_HEIGHT} />,
    link: '/universe',
    description: 'Visualize chemical and biological similarity for different assays',
    restrict: ['basic'],
    isPinned: false,
  },
  {
    id: 'AS_3rdPartyApps',
    title: '3rd Party Apps',
    newTab: false,
    icon: <ThirdPartyAppsIcon size={TILE_ICON_HEIGHT} />,
    link: '',
    description: 'Access useful third-party applications, capabilities, and data to accelerate your Research Activities.',
    enable: ['academic', 'commercial', 'pilot'],
    isPinned: false,
  },
  {
    id: 'AS_3rdPartyData',
    title: '3rd Party Data Sets',
    newTab: false,
    icon: <ThirdPartyDataIcon size={TILE_ICON_HEIGHT} />,
    link: '',
    description: 'Search for compounds in 3rd Party datasets and retrieve their associated or curated data.',
    enable: ['academic', 'commercial', 'pilot'],
    isPinned: false,
  },
  {
    id: 'AS_NaturalProducts',
    title: 'Natural Products',
    newTab: false,
    icon: <NaturalProductsIcon size={TILE_ICON_HEIGHT} />,
    link: '',
    description: 'Search natural product databases to retrieve relevant compound data, activity, and known toxicity',
    enable: ['academic', 'commercial', 'pilot'],
    isPinned: false,
  },
  {
    id: 'AS_Chosmos',
    title: 'Chosmos',
    newTab: true,
    icon: <img src="/img/Chosmos_Logo.svg" width={150} alt={"Chosmos Logo"}/>,
    link: 'https://chosmos.thewarriors3.com',
    description: 'Explore chemical compound databases to support your Discovery Research.',
    restrict: ['basic'],
    isPinned: false,
  },
  {
    id: 'GoSTAR',
    title: 'GoSTAR MiniApp',
    newTab: true,
    icon: <img src='/img/GOSTAR_Logo.svg' width={150} alt="GOSTAR Logo"/>,
    link: 'https://gostar.thewarriors3.com',
    description: 'Additional searches for compound data across curated literature and patent sources.',
    restrict: ['basic'],
    isPinned: false,
  },
  {
    id: 'HGNC',
    title: 'HGNC',
    newTab: false,
    icon: <HgncIcon size={TILE_ICON_HEIGHT} />,
    link: '',
    description: 'Explore/Find compounds of biological relevance to specific disease targets or biological pathways of interest.',
    restrict: ['basic'],
    isPinned: false,
  },
  {
    id: 'Curation',
    title: 'Curation Center',
    newTab: true,
    icon: <CustomCurationIcon size={TILE_ICON_HEIGHT} />,
    link: 'https://3rnd-tools.thewarriors3.com/curation',
    description: 'An AsedaSciences internal service for providing curated annotations of known literature toxicity.',
    restrict: ['basic'],
    isPinned: false,
  },
  {
    id: '3RnD_OasisCompounds',
    title: 'Oasis Consortium Cpds',
    newTab: false,
    icon: <OasisLogo size={TILE_ICON_HEIGHT} />,
    link: '',
    description: 'Access the reference Cell Painting Dataset from JMP and OASIS (pending data availability)',
    restrict: ['basic'],
    isPinned: false,
  }
];

export default homeCards;
