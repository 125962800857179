import React from 'react';
import styled from 'styled-components/macro';

import { useNavigate } from 'react-router-dom';
import { FlexItem, FlexBanner } from '@components/layout/FlexStyles';
import { TextSmall, TextSmallItalics, TextSmallBold, TextSpace } from "@components/elements/TextStyles";
import TextButton from '@components/controls/TextButton';
import MenuButton from '@components/elements/MenuButton';
import GraphIcon from '@components/icons/graph.icon';
import TrashIcon from '@components/icons/trash.icon';
import TableIcon from '@components/icons/table.icon';
import KinomeUniverseIcon from "@components/icons/kinomeUniverse.icon";
import DoseResponseIcon from "@components/icons/doseResponse.icon";
import Alert from "@components/elements/Alert";
import usePlots from '../../../plotting/usePlots';
import useCognito from "@as_core/account/useCognito";
import { truncateString } from "@utils/misc";
import { FlexBannerGroup } from "@as_core/elements/flexStyles";
import { Universe } from '@utils/api';


// Interface for the DataSet prop
interface DataSet {
  uuid: string;
  type: 'Generic' | 'Target Panel' | 'Dose Response' | 'Cell Painting';
  plotId?: string;
  universeId?: string;
  nColumns?: number;
  nRows?: number;
  computationStatus?: string;
  name?: string;
  createdOn?: string;
}

// Interface for the component props
interface UploadDocumentListItemProps {
  dataSet: DataSet;
  handleDeleteClick: (uuid: string) => void;
  handlePreviewClick: (uuid: string) => void;
}

const iconSet: Record<DataSet['type'], React.ReactElement> = {
  'Generic': <GraphIcon />,
  'Target Panel': <KinomeUniverseIcon />,
  'Dose Response': <DoseResponseIcon />,
  'Cell Painting': <KinomeUniverseIcon />
};

const UploadDocumentListItem: React.FC<UploadDocumentListItemProps> = ({ dataSet, handleDeleteClick, handlePreviewClick }) => {
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  // eslint-disable-any
  const [message, setMessage] = React.useState<any>(null);
  const { getToken } = useCognito();
  const { getPlotByDataId } = usePlots();

  // Handlers
  const _handleDeleteClick = (uuid: string) => {
    setAlertOpen(false);
    handleDeleteClick(uuid);
  };

  const handleCreatePlot = (uuid: string) => {
    navigate('/plots/create', { state: { dataId: uuid } });
  };

  const checkBeforeDelete = async (uuid: string) => {
    if (dataSet.type === 'Dose Response') {
      const plotData = await getPlotByDataId(getToken(), uuid);
      const message = createMessage(plotData.uuid, undefined);
      setMessage(message);
    }
    else if (dataSet.type === 'Target Panel') {
      const universe = await Universe.getUniverseByDataId(getToken(), 'kinome', uuid).then((res) => {
        return res.data;
      })
      const message = createMessage(undefined, universe.data.uuid);
      setMessage(message);
    }
    setAlertOpen(true);
  };

  const createMessage = (plotId?: string, universeId?: string): React.ReactElement => {

    if (!plotId && !universeId) {
      return (
        <div>
          <p>Are you sure you want to delete this data set?</p>
        </div>
      );
    }

    return (
      <div>
        <p>This data set is used in a Dose Response plot. Deleting this data set will remove the elements below:</p>
        <ul>
          {plotId && <li>Plot ID: <Text>{plotId}</Text></li>}
          {universeId && <li>Universe ID: <Text>{universeId}</Text></li>}
        </ul>
        <p>Are you sure you want to delete this data set?</p>
      </div>
    );
  };


  const handleView = (path: string) => {
    navigate(path);
  };

  const getViewResultsButton = (): React.ReactElement | null => {
    switch (dataSet.type) {
      case 'Dose Response':
      case 'Generic':
        return (
          <TextButton
            text={'Create Plot'}
            icon={<GraphIcon />}
            width={150}
            height={28}
            margin={'0'}
            onClick={() => handleCreatePlot(dataSet.uuid)}
          />
        );
      case 'Target Panel':
        if (dataSet.computationStatus === "Pending") {
          return <TextSmallItalics color={'accent'}>Computation Pending</TextSmallItalics>;
        }
        return (
          <TextButton
            text={'View Universe'}
            icon={<KinomeUniverseIcon />}
            width={150}
            height={28}
            margin={'0'}
            onClick={() => handleView(`/universe/user/${dataSet?.universeId}`)}
          />
        );
      case 'Cell Painting':
        if (dataSet.computationStatus !== "Complete") {
          return <TextSmallItalics color={'accent'}>{dataSet.computationStatus}</TextSmallItalics>;
        }
        return (
          <TextButton
            text={'View Universe'}
            icon={<KinomeUniverseIcon />}
            width={150}
            height={28}
            margin={'0'}
            onClick={() => handleView(`/universe/user/${dataSet?.universeId}`)}
          />
        );
      default:
        if (dataSet.plotId) {
          return (
            <TextButton
              text={'View Plot'}
              icon={<GraphIcon />}
              width={150}
              height={28}
              margin={'0'}
              onClick={() => handleView(`/plots/${dataSet.plotId}`)}
            />
          );
        }
        return null;
    }
  };

  const title = `${truncateString(dataSet?.name, 30)} (${new Date(dataSet?.createdOn).toLocaleDateString()})`;

  return (
    <DataSetContainer>
      <FlexBanner>
        <FlexBannerGroup>
          <FlexItem width={'30px'}>{iconSet[dataSet.type]}</FlexItem>
          <FlexItem width={'350px'}>
            <TextSmall color={'primary'}>{title}</TextSmall>
          </FlexItem>
        </FlexBannerGroup>
        <FlexItem>
          <TextSmallBold color={'accentSecondary'}>{dataSet.type}</TextSmallBold>
          <TextSpace />
          { dataSet.type !== 'Cell Painting' ?
          <TextSmallItalics>({dataSet?.nColumns} columns x {dataSet?.nRows} rows)</TextSmallItalics>
            : null
          }
        </FlexItem>
        <FlexBannerGroup>
          <FlexItem width={'160px'}>{getViewResultsButton()}</FlexItem>
          { dataSet.type !== 'Cell Painting' ?
            <FlexItem width={'160px'}>
              <TextButton
                text={'View Data'}
                icon={<TableIcon />}
                width={125}
                height={28}
                margin={'0'}
                onClick={() => handlePreviewClick(dataSet.uuid)}
              />
            </FlexItem>
          :
            <FlexItem width={'160px'} />
          }
          <FlexItem width={'50px'}>
            <MenuButton
              onClick={() => checkBeforeDelete(dataSet.uuid)}
              isActive={false}
              tooltipPlacement={'bottom'}
              icon={<TrashIcon />}
              margin={'0'}
              text={'Delete Data Set'}
            />
          </FlexItem>
        </FlexBannerGroup>
        <Alert
          type={'general'}
          title={'Delete Data Set'}
          width={540}
          height={345}
          message={message}
          alertOpen={alertOpen}
          closeAlert={() => setAlertOpen(false)}
          onConfirm={() => _handleDeleteClick(dataSet.uuid)}
          buttonText={'Close Alert'}
        />
      </FlexBanner>
    </DataSetContainer>
  );
};

export default UploadDocumentListItem;

// Styled Components
const DataSetContainer = styled.div`
  width: calc(100% - 10px);
  display: flex;
  padding: 0 5px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.backgroundTertiary};
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
  margin-bottom: 5px;
`;

const Text = styled.span`
  color: ${(p) => p.theme.palette.accentSecondary};
`;
