import { useState } from "react";
import styled from 'styled-components/macro';
import useRequests, {RequestT} from "../../hooks/useRequests";
import React from "react";
import TextButton from "@as_core/controls/buttons/TextButton/TextButton";
import UserRequestToDo, {StatusMapT} from "@subApps/orders/components/Common/UserRequestToDo";
import {Email, CalendarToday} from "@mui/icons-material";
import HoverInfoText from "@as_core/account/fields/HoverInfoText";
import DataLoading from "@as_core/elements/DataLoading";

const ScheduleInfo = 'Please schedule the meeting using the email button. '
  + 'Once you have confirmed a date/time, please confirm successful scheduling via the Confirm Scheduling button.';
const ConfirmMeetingInfo = 'Once you held the consulting meeting, please confirm '
  + ' meeting occurred via the Confirm Successful Meeting button.';

interface PropsI {
  request: RequestT;
  updateSelectedRequest: (r: RequestT)=>void;
  setUpdatedRequestForms: ()=>void;
}

const debug = false;
const ConsultingUserUpdate = (props: PropsI) => {
  const {request, updateSelectedRequest, setUpdatedRequestForms} = props;
  const { updateRequest } = useRequests();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const confirmScheduling = () => {
    setIsProcessing(true);
    updateRequest(request.id, {status: 'Scheduled'})
      .then((request)=>{
        updateSelectedRequest(request);
        setIsProcessing(false);
        setUpdatedRequestForms();
      }
    )
  }

  const confirmMeeting = () => {
    setIsProcessing(true);
    updateRequest(request.id, {status: 'Completed'})
      .then((request)=>{
          updateSelectedRequest(request);
          setIsProcessing(false);
          setUpdatedRequestForms();
        }
      )
  }
  if (debug) console.log('ConsultingUserUpdate | request:', request);

  const statusMap: StatusMapT[] = [
    {label: 'Schedule Meeting(s)', checked: ['Scheduled', 'Completed'].includes(request.status)},
    {label: 'Attend Meeting(s)', checked: request.status === 'Completed'},
  ]
  return (
    <ViewContainer>
      <Row>
        <Header>User Consulting Request Actions</Header>
      </Row>
      <Row padding={'0'}>
        <UserRequestToDo statusMap={statusMap}/>
      </Row>
      { isProcessing ? <DataLoading />
        : request.status === 'Open' ?
        <>
          <Row>
            <Label>Schedule Meeting: <HoverInfoText text={ScheduleInfo}/></Label>
            <Value>
              <TextButton
                width={270}
                height={30}
                fontSize={12}
                margin={'2px'}
                icon={<EmailIcon />}
                label={'Click to Schedule via Email'}
                onClick={() => window.location.href = `mailto:info@asedasciences.com?subject=Need to Schedule ${request?.prod_name}`}
              />
            </Value>
          </Row>
          <Row>
            <TextButton
              label = 'Confirm Scheduling'
              icon={<CalendarIcon />}
              height={28}
              width={180}
              onClick={()=> confirmScheduling()}
            />
          </Row>
        </>
      : request.status !== 'Completed' ?
        <Row>
          <HoverInfoText text={ConfirmMeetingInfo}/>
          <TextButton
            label = 'Confirm Successful Meeting(s)'
            width={225}
            height={28}
            isDisabled={request.status!=='Scheduled'}
            onClick={()=>confirmMeeting()}
          />
        </Row>
        :
        <Row />
      }
    </ViewContainer>
  );
};

export default ConsultingUserUpdate;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 420px;
  background: ${(p) => p.theme.palette.backgroundTertiary};
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  border-radius: 15px;
`;

const Row = styled.div<{padding?: string}>`
  display: flex;
  width: calc(100% - 10px);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: ${(p) => p.padding};
`;
Row.defaultProps = {
  padding: `3px 10px`
}

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 14px;
  font-style: italic;
  font-weight: bold;
  color: ${(p) => p.theme.palette.accentSecondary};
`;

const Label = styled.div`
  display: flex;
  width: 150px;
  text-align: end;
`;

const Value = styled(Label)`
  flex-direction: row;
  justify-content: center;
  min-width: 200px;
`;

const EmailIcon = styled(Email)`
  display: flex;
  color: ${(p) => p.theme.palette.textPrimary};
`;

const CalendarIcon = styled(CalendarToday)`
  display: flex;
  color: ${(p) => p.theme.palette.textPrimary};
`;
