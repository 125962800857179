import React from 'react';
import { LayerCard } from '../LayerCard';
import { ScatterBase, PlotConfig, KeyColor } from '@plotting/single-plot-view/plot.types';
import { VariableSelect } from '@plotting/controls/VariableSelect';
import { Checkbox, FormControlLabel, Slider, Typography } from '@mui/material';
import { DEFAULT_CIRCLE_SIZE } from '@dataviz/scatterplot/ScatterPlot';
import { Stack } from '@mui/system';
import { DiscreteColorPaletteSelect } from '@plotting/controls/ColorPaletteSelect';
import { COLORS } from '@utils/scales/color/ColorSchemes';
import { scaleOrdinal } from 'd3-scale';
import InputField from '@as_core/components/inputs/InputField';

type ScatterCardProps = {
  plot: PlotConfig<ScatterBase>;
  onChange: (newPlotConfig: PlotConfig<ScatterBase>) => void;
};

export const ScatterCard: React.FC<ScatterCardProps> = ({ plot, onChange }) => {
  const updateXColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: { ...plot.config, xColumn: columnName },
    };
    onChange(newPlotConfig);
  };

  const updateYColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: { ...plot.config, yColumn: columnName },  // Fixing the update to `yColumn`
    };
    onChange(newPlotConfig);
  };

  const updateGroupColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: { ...plot.config, groupByColumn: columnName },
    };
    onChange(newPlotConfig);
  };

  const updateSizeColumn = (columnName: string) => {
    let newCircleSize = plot.config.circleSize;
    if (!plot.config.sizeColumn && columnName !== '') {
      newCircleSize = [DEFAULT_CIRCLE_SIZE, DEFAULT_CIRCLE_SIZE + 6];
    }
    if (plot.config.sizeColumn && columnName === '') {
      newCircleSize = DEFAULT_CIRCLE_SIZE;
    }
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: {
        ...plot.config,
        sizeColumn: columnName,
        circleSize: newCircleSize,
      },
    };
    onChange(newPlotConfig);
  };

  const updateCircleSize = (e: Event, newSize: number | [number, number]) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: { ...plot.config, circleSize: newSize },
    };
    onChange(newPlotConfig);
  };

  const updateIsLinearRegressionEnabled = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: { ...plot.config, isLinearRegressionEnabled: checked },
    };
    onChange(newPlotConfig);
  };

  const updateXAxisName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: {
        ...plot.config,
        xAxisName: event.target.value,
      },
    };
    onChange(newPlotConfig);
  };

  const updateYAxisName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: {
        ...plot.config,
        yAxisName: event.target.value,
      },
    };
    onChange(newPlotConfig);
  };

  const updateIsLogScaleXAxis = (event: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: {
        ...plot.config,
        xAxisScale: {
          type: isChecked ? 'log' : 'linear',
        },
      },
    };
    onChange(newPlotConfig);
  };

  const updateIsLogScaleYAxis = (event: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: {
        ...plot.config,
        yAxisScale: {
          type: isChecked ? 'log' : 'linear',
        },
      },
    };
    onChange(newPlotConfig);
  };

  const updateStyle = (style: KeyColor[]) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: { ...plot.config, style },
    };
    onChange(newPlotConfig);
  };

  const groups: string[] = [...new Set(plot.data.map((d) => d[plot.config.groupByColumn]))];
  const defaultColors = COLORS.find((col) => col.id === 'aseda');
  const defaultColorScale = scaleOrdinal<string>()
    .domain(groups.map((g) => String(g)))
    .range(defaultColors?.scheme || []);

  const defaultKeyColor = groups.map((group) => {
    return {
      id: group,
      color: defaultColorScale(group),
    };
  });

  const defaultSizeSlideValue =
    plot.config.sizeColumn && plot.config.sizeColumn !== ''
      ? [DEFAULT_CIRCLE_SIZE, DEFAULT_CIRCLE_SIZE + 6]
      : DEFAULT_CIRCLE_SIZE;

  const circleSizeSliderValue = plot.config.circleSize || defaultSizeSlideValue;

  return (
    <>
      <LayerCard title={'Variables'} disableExpand>
        <VariableSelect
          id='variable-value'
          label={'X Axis Variable'}
          value={plot.config.xColumn}
          options={plot.columns}
          onChange={updateXColumn}
        />
        <VariableSelect
          id='variable-value'
          label={'Y Axis Variable'}
          value={plot.config.yColumn}
          options={plot.columns}
          onChange={updateYColumn}
        />
        <VariableSelect
          id='variable-group'
          label={'Group By'}
          value={plot.config.groupByColumn}
          options={plot.columns}
          onChange={updateGroupColumn}
        />
        <VariableSelect
          id='variable-size'
          label={'Size By'}
          value={plot.config.sizeColumn}
          options={plot.columns}
          onChange={updateSizeColumn}
        />
      </LayerCard>

      <LayerCard title={'Customization'} disableExpand>
        <FormControlLabel
          control={
            <Checkbox
              checked={plot.config.isLinearRegressionEnabled}
              onChange={updateIsLinearRegressionEnabled}
            />
          }
          label={<Typography fontSize={12}>Add linear regression</Typography>}
        />
        <DiscreteColorPaletteSelect
          values={plot.config.style || defaultKeyColor}
          onChange={updateStyle}
        />
        <Stack style={{ width: '100%' }}>
          <Typography
            color={'textSecondary'}
            sx={{ marginBottom: '2px', fontSize: 14, marginTop: 1 }}
          >
            Circle Size
          </Typography>
          <Slider
            value={circleSizeSliderValue}
            step={1}
            marks
            size='small'
            min={3}
            max={50}
            valueLabelDisplay='auto'
            onChange={updateCircleSize}
          />
        </Stack>
      </LayerCard>

      <LayerCard title={'Axes'} disableExpand>
        <Stack direction={'column'} width={'100%'}>
          <InputField
            value={plot.config.xAxisName}
            onChange={updateXAxisName}
            label='X Axis Name'
            size='small'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={plot.config.xAxisScale?.type === 'log'}
                onChange={updateIsLogScaleXAxis}
              />
            }
            label={<Typography fontSize={12}>Use Log Scale</Typography>}
          />
        </Stack>

        <Stack direction={'column'} mt={2} width={'100%'}>
          <InputField
            value={plot.config.yAxisName}
            onChange={updateYAxisName}
            label='Y Axis Name'
            size='small'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={plot.config.yAxisScale?.type === 'log'}
                onChange={updateIsLogScaleYAxis}
              />
            }
            label={<Typography fontSize={12}>Use Log Scale</Typography>}
          />
        </Stack>
      </LayerCard>
    </>
  );
};
