import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import { ResponseT, authClient } from '@utils/api/base';
import { AppThunk } from '@src/store';

// ###########################################################################
// TYPES
// ###########################################################################
export enum CompoundViewTypeT {
  Finite,
  Infinite,
}

export enum CompoundViewSortT {
  CreatedOnAsc = 'CREATEDONASC',
  CreatedOnDesc = 'CREATEDONDESC',
  ModifiedOnAsc = 'MODIFIEDONASC',
  ModifiedOnDesc = 'MODIFIEDONDesc',
  TitleAsc = 'TITLEASC',
  TitleDesc = 'TITLEDESC',
}

export interface CompoundFilterT {
  createdOn: string,
  modifiedOn: string,
  field_id: string,
  comparator: string,
  value: string | number,
}

export interface LibraryCompoundListT {
  libraryId: string;
  compoundIds: Array<string>;
}

export interface CompoundViewItemT {
  uuid: string;
  title: string;
  createdOn?: string;
  modifiedOn?: string;
  viewType: CompoundViewTypeT;
  userId?: string;
  sort?: CompoundViewSortT;
  filters?: Array<CompoundFilterT>;
  fieldIds: Array<string>;
  compoundCount?: number;
  compoundIds?: Array<LibraryCompoundListT>;
}

export interface CompoundViewsT {
  viewIds: string[];
  views: { [propName: string]: CompoundViewItemT };
}

export interface RemoveViewPayloadT {
  uuid: string;
}

export interface removeCompoundIdPayloadT {
  uuid: string;
  compoundId: string;
}
export interface SetFiltersPayloadT {
  uuid: string;
  filters: Array<CompoundFilterT>;
}

const initialState: CompoundViewsT = {
  viewIds: [],
  views: {},
};

// ###########################################################################
// REDUCERS
// ###########################################################################
const compoundViewsSlice = createSlice({
  name: 'compoundViews',
  initialState,
  reducers: {
    clearViews: (state) => {
      state.views = {};
      state.viewIds = [];
    },
    addView: (state, { payload }: PayloadAction<CompoundViewItemT>) => {
      state.views[payload.uuid] = payload;
      state.viewIds.push(payload.uuid);
    },
    updateView: (state, { payload }: PayloadAction<CompoundViewItemT>) => {
      state.views[payload.uuid] = payload;
    },
    removeView: (state, { payload }: PayloadAction<RemoveViewPayloadT>) => {
      const idx = state.viewIds.indexOf(payload.uuid);
      delete state.views[payload.uuid];
      state.viewIds.splice(idx, 1);
    },
    setFilters: (state, { payload }: PayloadAction<SetFiltersPayloadT>) => {
      state.views[payload.uuid].filters = payload.filters;
    },
    sortCompoundViews: (
      state,
      { payload }: PayloadAction<CompoundViewSortT>
    ) => {
      switch (payload) {
        case CompoundViewSortT.CreatedOnAsc:
          break;
        case CompoundViewSortT.CreatedOnDesc:
          break;
        case CompoundViewSortT.ModifiedOnAsc:
          break;
        case CompoundViewSortT.ModifiedOnDesc:
          state.viewIds = Object.keys(state.views).sort(
            (a, b) =>
              DateTime.fromISO(state.views[b].modifiedOn, {
                zone: 'utc',
              }).toMillis() -
              DateTime.fromISO(state.views[a].modifiedOn, {
                zone: 'utc',
              }).toMillis()
          );
          break;
        case CompoundViewSortT.TitleAsc:
          break;
        case CompoundViewSortT.TitleDesc:
          break;
        default:
          break;
      }
    },
  },
});

export const {
  clearViews,
  addView,
  updateView,
  removeView,
  sortCompoundViews,
} = compoundViewsSlice.actions;

export default compoundViewsSlice.reducer;

// ###########################################################################
// HELPERS
// ###########################################################################
export const getCompoundViews =
  (token, append: boolean = false, start: number = 0): AppThunk =>
  async (dispatch) => {
    //console.log('GetViews');
    try {
      const path = `/settings/user/compoundViews`;

      const apiResponse = await authClient(token).get(path, {
        params: { start },
      });
      const apiPayload: ResponseT<any[]> = apiResponse.data;

      if (!append) {
        dispatch(clearViews());
      }
      apiPayload.data.forEach((payloadItem) => {
        dispatch(addView(payloadItem));
      });

      if (apiPayload.continues) {
        const _start = apiPayload.size + apiPayload.start_after;
        getCompoundViews(token, (append = true), (start = _start));
      } else {
        //console.log('SORT COMPOUND VIEWS');
        dispatch(sortCompoundViews(CompoundViewSortT.ModifiedOnDesc));
      }
    } catch (err) {
      console.error('getViews Failed: ', err);
    }
  };

export const addCompoundView =
  (token, payload: CompoundViewItemT): AppThunk =>
  async (dispatch) => {
    //console.log('addCompoundView', payload);

    const path = `/settings/user/compoundViews`;
    try {
      const apiResponse = await authClient(token).post(path, payload);
      const apiPayload: ResponseT<CompoundViewItemT> = apiResponse.data;

      if (apiPayload.size === 1) {
        dispatch(addView(apiPayload.data));
      } else {
        throw new Error(`Expected response size 1 but got ${apiPayload.size}`);
      }
    } catch (err) {
      console.error('addCompoundView Failed: ', err);
    }
  };

export const updateCompoundView =
  (token, payload: CompoundViewItemT): AppThunk =>
  async (dispatch) => {
    //console.log('updateCompoundView', payload);

    const path = `/settings/user/compoundViews`;
    try {
      const apiResponse = await authClient(token).put(path, payload);
      const apiPayload: ResponseT<CompoundViewItemT> = apiResponse.data;

      if (apiPayload.size === 1) {
        dispatch(updateView(apiPayload.data));
      } else {
        throw new Error(`Expected response size 1 but got ${apiPayload.size}`);
      }
    } catch (err) {
      console.error('addCompoundView Failed: ', err);
    }
  };

export const removeCompoundView =
  (token, payload: string): AppThunk =>
  async (dispatch) => {
    //console.log('removeCompoundView', payload);

    const path = `/settings/user/compoundViews/${payload}`;
    try {
      await authClient(token).delete(path);
    } catch (err) {
      console.error('addCompoundView Failed: ', err);
    }
    dispatch(removeView({ uuid: payload }));
  };
