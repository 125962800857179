import { LegendProps } from '@nivo/legends';

export const DEFAULT_TITLE_SIZE = 12;

export const DEFAULT_LEGEND_CONFIG: LegendProps = {
    anchor: 'bottom-right',
    direction: 'column',
    justify: false,
    translateX: 100,
    translateY: 0,
    itemsSpacing: 0,
    itemDirection: 'left-to-right',
    itemWidth: 80,
    itemHeight: 20,
    itemOpacity: 0.75,
    symbolSize: 12,
    symbolShape: 'circle',
    symbolBorderColor: 'rgba(0, 0, 0, .5)',
    effects: [
        {
            on: 'hover',
            style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1
            }
        }
    ]
}

const DEFAULT_AXIS_STYLE = {
    tickSize: 5,
    tickPadding: 10,
    tickRotation: 0,
    legendPosition: 'middle' as const,
};

export const DEFAULT_X_AXIS_STYLE = {
    ...DEFAULT_AXIS_STYLE,
    legendOffset: 40,
    format: '~g',
    tickValues: 5,
};

export const DEFAULT_Y_AXIS_STYLE = {
    ...DEFAULT_AXIS_STYLE,
    legendOffset: -50,
    intervals: 5,
    format: '~g',
};
