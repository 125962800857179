import React, { useMemo, useEffect } from 'react';
import { roundOf } from '@utils/misc';
import _ from 'lodash';

import SelectableTable from './SelectableTable';
import styled, { keyframes } from 'styled-components/macro';
import zebraRenderer from '@components/valueRenderers/zebraRenderer';
import circleRenderer from '@components/valueRenderers/circleRenderer';
import MolImgTile from '@components/compounds/images/MolImgTile';
import useWindowDimensions from '@as_core/hooks/useWindowDimensions';
import MagnifiedSvgImage from '@components/compounds/images/MagnifiedSvgImage';
import { checkDuplicatedRows } from '@components/compounds/compounds.utils';
import { Column, SortOrder } from 'react-base-table';
import { CompoundT } from '@src/type';

type CompoundTableProps = {
  rowKey?: string;
  fixed?: boolean;
  columns: Column[];
  data: CompoundT[];
  selectable?: boolean;
  handleClick?: (props?: unknown) => void;
  handleDoubleClick?: (props?: unknown) => void;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  setPinnedData: React.Dispatch<React.SetStateAction<CompoundT[]>>;
  pinnedData?: CompoundT[];
  selectedRowKeys?: Array<string>;
  loading?: boolean;
  loadingMore?: boolean;
  onEndReachedThreshold?: number;
  onEndReached?: () => void;
  onColumnSort?: (props?: unknown) => void;
  sortBy?: {
    key: React.Key;
    order: SortOrder;
  };
  topMargin?: number;
  libraryId?: string;
};

// COMPONENT START
const CompoundTable = ({
  rowKey = 'searchKey', // change from uuid to searchKey : DHR 2023-08-08
  columns,
  data,
  selectable = true,
  setSelected,
  pinnedData = [],
  setPinnedData = () => {},
  selectedRowKeys = [],
  loading,
  loadingMore,
  onEndReachedThreshold,
  onEndReached,
  handleClick = () => null,
  handleDoubleClick = () => null,
  onColumnSort = () => null,
  sortBy,
  topMargin = 0,
  libraryId,
}: CompoundTableProps) => {
  const { height, width } = useWindowDimensions();
  const tableWidth = width - 62;

  const rowEventHandlers = {
    onClick: (props) => {
      handleClick(props);
    },
    onDoubleClick: (props) => {
      handleDoubleClick(props.rowKey);
    },
  };

  // INFINITE LOADING
  const renderEmpty = () => {
    if (loading) return null;
    return <Empty>No data available</Empty>;
  };

  const renderOverlay = React.useCallback(() => {
    if (loadingMore)
      return (
        <LoadingMoreLayer>
          <LoadingMoreText>Loading More</LoadingMoreText>
          <Loader small />
        </LoadingMoreLayer>
      );
    if (loading)
      return (
        <LoadingLayer>
          <Loader />
        </LoadingLayer>
      );

    return null;
  }, [loadingMore, loading]);

  const notPinnedData = useMemo(() => {
    return data.filter(
      (obj) => !pinnedData.some((pinned) => pinned?.uuid === obj?.uuid)
    );
  }, [pinnedData, data]) as CompoundT[];

  useEffect(() => {
    if (sortBy && sortBy?.key) {
      setPinnedData((previous) =>
        previous.sort((a, b) =>
          a.cfields[sortBy?.key]?.value.toString().toLowerCase() >
            b.cfields[sortBy?.key]?.value.toString().toLowerCase() &&
          sortBy?.order === 'asc'
            ? 1
            : -1
        )
      );
    }
  }, [sortBy]);

  useEffect(() => {
    checkDuplicatedRows(data);
  }, [data]);

  return (
    <Container topMargin={topMargin}>
      <SelectableTable
        rowKey={rowKey}
        width={tableWidth}
        height={height}
        selectable={selectable}
        rowHeight={45}
        columns={columns}
        data={notPinnedData}
        rowEventHandlers={rowEventHandlers}
        frozenData={pinnedData}
        rowRenderer={rowRenderer}
        components={components}
        onSelectedRowsChange={(e) => {
          setSelected(e);
        }}
        selectedRowKeys={selectedRowKeys}
        disabled={loading}
        onEndReachedThreshold={onEndReachedThreshold}
        onEndReached={onEndReached}
        overlayRenderer={renderOverlay}
        emptyRenderer={renderEmpty}
        onColumnSort={onColumnSort}
        sortBy={sortBy}
        setPinnedData={setPinnedData}
        hasSelectAll={libraryId === 'user'}
      />
      <MagnifiedSvgImage />
    </Container>
  );
};

export default CompoundTable;

interface StyledExtraProps {
  small?: boolean;
}

const Container = styled.div<{ topMargin: number }>`
  height: calc(100vh - 101px);
  width: calc(100vw - 50px);
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  overflow-x: auto;
`;

const Empty = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div<StyledExtraProps>`
  display: inline-block;
  border-radius: 100%;
  margin: 2px;
  border: ${(p) => p.theme.borders.default};
  width: ${(props) => (props.small ? 12 : 22)}px;
  height: ${(props) => (props.small ? 12 : 22)}px;
  animation: ${rotate} 0.75s linear infinite;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
`;

const LoadingLayer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  margin: 0;
  width: 100%;
  height: 100%;
`;

const LoadingMoreLayer = styled.div`
  pointer-events: none;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
`;

const LoadingMoreText = styled.span`
  color: ${(p) => p.theme.palette.titlePrimary};
  margin-right: 5px;
`;

const stringRenderer = ({ className, cellData }) => {
  const _value = _.isNumber(cellData)
    ? _.isInteger(cellData)
      ? cellData
      : roundOf(cellData, 2).toFixed(2)
    : cellData;
  return <div className={className}>{_value}</div>;
};

const svgRenderer = ({ rowData, column }) => {
  let mol_svg = rowData?.mol_svg;
  if (column.format === 'xxCompare') {
    mol_svg = rowData[column.dataKey];
  }
  return <MolImgTile mol_svg={mol_svg} width={45} hover={true} />;
};

const renderers = {
  string: stringRenderer,
  mol_svg: svgRenderer,
  circle: circleRenderer,
  zebrafish: zebraRenderer,
};

const Cell = (cellProps) => {
  let format = 'string';
  if (cellProps.column.format === 'xxCompare') {
    format = cellProps.rowData.format || 'string';
  } else {
    format = cellProps.column.format || 'string';
  }
  const renderer = renderers[format] || renderers.string;

  return renderer(cellProps);
};

const components = {
  TableCell: Cell,
};

const rowRenderer = ({ rowData, cells }) => {
  if (_.has(rowData, 'x_type')) {
    switch (rowData['x_type']) {
      case 'section': {
        const { x_span } = rowData;
        const x_offset = _.get(rowData, 'x.offset', 0);

        let width = cells[x_offset].props.style.width;
        for (let i = 1; i < x_span; i++) {
          width += cells[x_offset + i].props.style.width;
          cells[i] = null; // Clear contents of other cells
        }
        const style = {
          ...cells[x_offset].props.style,
          width,
          backgroundColor: '#02022C',
          color: '#1f7d59',
          fontSize: '1.3rem',
        };
        cells[x_offset] = React.cloneElement(cells[x_offset], { style });
        break;
      }
      default:
        break;
    }
  }
  return cells;
};
