import _ from 'lodash';

// add some functions so that there is error checking
const getFieldName = (f, allFields) => {
  return _.get(allFields[f], 'long_name', '');
}

const getFieldDataKey = (f, allFields) => {
  return _.get(allFields[f], 'data_key', '');
}

const getFieldOptions = (fieldId, allFields) => {
  return _.get(allFields, `${fieldId}.field_options`, '').split(';');
};

const setCompoundFieldValue = (compound, f, v, allFields) => {
  const data_key=getFieldDataKey(f, allFields);
  console.log("utils | setCompoundFieldValue | data_key:", data_key);
  if (data_key) {
    _.set(compound, data_key, v);
  }
}

const getCompoundFieldValue = (compound, f, allFields) => {
  const field_info = _.get(allFields, f, '');
  if (field_info) {
    let value = _.get(compound, field_info.data_key, '');
    if (f === 'exact_mol_wt' || f === 'crippen_log_p' || f === 'fs_p3' ||
        f === 'polar_surface_area' || f === 'c_sp3') {
      value = parseFloat(value).toFixed(2)
    }
    return value;
  } else {
    console.log("Error: " + f + " not in either dataFields");
    return '';
  }
}

export {
  getFieldName,
  getFieldDataKey,
  getFieldOptions,
  getCompoundFieldValue,
  setCompoundFieldValue
}