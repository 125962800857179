import React, {useContext} from 'react';
import styled from 'styled-components/macro';
import SubscriptionView from "./components/SubscriptionView";
import SubscriptionUsers from "./components/SubscriptionUsers";
import SubscriptionInvites from "./components/SubscriptionInvites";
import {UserContext} from "@stores/UserContext";
import LabeledContainer from "@as_core/elements/LabeledContainer";

const SubscriptionManagement = () => {
  const {user} = useContext(UserContext);
  const {subscription} = user.appInfo;
  const isOwner = subscription.ownerAuthId == user.authId;
  return (
    <Container>
      <SubscriptionView />
      { isOwner ?
        <LabeledContainer label={'Subscription Management'} width={'550px'}>
          <SubscriptionInvites subscription={subscription}/>
          <SubscriptionUsers />
        </LabeledContainer>
         : null }
    </Container>
  );
};

export default SubscriptionManagement;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  height: max-content;
  margin: 10px;
  padding: 10px;
  border-radius: 15px;
`;