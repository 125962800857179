import React, {useState} from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import BasicTable from '@as_core/tables/BasicTable';
import RequestFormDetails from "@subApps/orders/components/Assays/RequestFormDetails";
import {TableFieldsT} from "@subApps/orders/utils";
import {RequestFormT} from "@subApps/orders/hooks/useRequestForms";
import MenuButton from "@components/elements/MenuButton";
import ViewIcon from "@as_core/icons/view.icon";
import HoverTextTruncate from "@as_core/elements/HoverTextTruncate";
import StationaryModal from "@as_core/elements/StationaryModal";
import TrashIcon from "@components/icons/trash.icon";
import Alert from "@components/elements/Alert";
import HoverInfoText from "@as_core/account/fields/HoverInfoText";

const NoDeleteInfo:string = 'Once you have confirmed shipping, plate map files cannot be deleted. '
 + 'Please reach out directly to the laboratory to which you shipped your compounds to provide any '
 + 'updated files.';

const tableFields: {[key: string]: TableFieldsT[]} = {
  'short': [
    {value: 'view', label: '', type: 'action', width: 40},
    {value: 'file_name', label: 'File', type: 'file', width: 150},
    {value: 'delete', label: '', type: 'action', width: 60},
  ],
  'long': [
    {value: 'view', label: '', type: 'action', width: 40},
    {value: 'original_file_name', label: 'Uploaded File Name', type: 'string', width: 150},
    {value: 'file_name', label: 'Stored File Name', type: 'string', width: 150},
  ]
};

const getTableRows = (requestForms: RequestFormT[], showDelete: boolean, fields: TableFieldsT[], handleClick: (id:string, type:string)=>void) => {
  const rows = [];
  console.log('showDelete', showDelete);
  requestForms.forEach((form) => {
    const row = [];
    fields.forEach((f:TableFieldsT) => {
      if (f.type === 'action') {
        if (f.value === 'view') {
          row[f.value] = <MenuButton
            onClick={() => handleClick(form?.id, 'view')}
            icon={<ViewIcon/>}
            text={'View Plate Map Details'}
            tooltipPlacement={'bottom'}
          />;
        } else if (f.value === 'delete') {
          if (showDelete) {
            row[f.value] = <MenuButton
              onClick={() => handleClick(form?.id, 'delete')}
              icon={<TrashIcon />}
              text={'Delete Uploaded Plate Map File'}
              tooltipPlacement={'bottom'}
            />;
          } else {
            row[f.value] = <HoverInfoText text={NoDeleteInfo}/>
          }
        }
      } else if (f.type === 'string_truncate') {
        row[f.value] = <HoverTextTruncate text={form.original_file_name} />
      } else {
        row[f.value] = _.get(form, f.value, '');
      }
    });
    rows.push(row);
  })
  return rows;
}

type PropsT = {
  requestForms: RequestFormT[];
  handleDeleteForm?: (id:number) => void;
  short?: boolean;
  header?: boolean;
}


const debug = false;
const RequestFormsTable = (props:PropsT) => {
  const { requestForms, short=true, header= false } = props;
  if (debug) console.log('requestFormsTable | requestForms: ', requestForms);
  const [selectedRequestForm, setSelectedRequestForm] = useState(null);
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState<boolean>(false);

  const handleClick = (requestFormId: string, clickType: string) => {
    if (debug) console.log('handleClick: ', requestFormId, clickType);
    const matched:RequestFormT = requestForms.find((o) => o.id === requestFormId);
    if (matched) {
      setSelectedRequestForm(matched);
      if (clickType === 'view') {
        setIsDetailsOpen(true);
      } else {
        setIsDeleteAlertOpen(true);
      }
    } else {
      console.error(`RequestsTable | unable to locate request by id ${requestFormId})`);
    }
  };

  const handleDelete = () => {
    if (debug) console.log('handleDelete: ', selectedRequestForm);
    if (props?.handleDeleteForm) props.handleDeleteForm(selectedRequestForm.id);
  };

  return (
    <RequestTableContainer>
      { requestForms !== null && requestForms.length !== 0 ?
        <BasicTable
          header={header}
          fields={short ? tableFields['short']: tableFields['long']}
          rows={getTableRows(requestForms, !!props?.handleDeleteForm, short ? tableFields['short']: tableFields['long'], handleClick)}
          border={false}
        />
        :
        <></>
      }
      <StationaryModal
        title={'Plate Map Upload Details'}
        opacity={0.95}
        height={240}
        width={420}
        modalOpen={isDetailsOpen}
        onCloseClick={() => setIsDetailsOpen(false)}
      >
        <RequestFormDetails
          requestForm={selectedRequestForm}
        />
      </StationaryModal>
      <Alert
        type={'deleteConfirm'}
        title={'Delete Uploaded Plate Map'}
        message={'Confirm delete by typing DELETE below to Enable Delete'}
        alertOpen={isDeleteAlertOpen}
        onConfirm={handleDelete}
        closeAlert={() => setIsDeleteAlertOpen(false)}
      />
    </RequestTableContainer>
  );
};

export default RequestFormsTable;


const RequestTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
