import styled from 'styled-components/macro';
import _ from 'lodash';
import React from "react";
import {TableFieldsT} from "@subApps/orders/utils";
import {RequestFormT} from "@subApps/orders/hooks/useRequestForms";
import DownloadRequestForm from "@subApps/orders/components/Assays/DownloadRequestForm";

const userFields: TableFieldsT[] = [
  {
    value: 'created_at',
    label: 'Created',
    type: 'datetime',
  },
  {
    value: 'original_file_name',
    label: 'Uploaded File Name',
    type: 'string',
  },
  {
    value: 'file_name',
    label: 'Stored File Name',
    type: 'string',
  },
  {
    value: 'updated_at',
    label: 'Last Updated',
    type: 'datetime',
  },
];

function getFieldValue(form: RequestFormT, item: TableFieldsT) {
  const value = _.get(form, item.value, '');
  if (value === '') return value;
  if (item.type === 'datetime') {
    const date = new Date(value);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }
  return value;
}


interface PropsI {
  requestForm: RequestFormT;
}

const debug = false;
const RequestFormDetails = (props: PropsI) => {
  const {requestForm} = props;
  if (debug) console.log('RequestFormDetails | requestForm:', requestForm);

  const displayValue = (form: RequestFormT, item: TableFieldsT, index: number) => {
    if (item?.type === 'line') {
      return <HorizontalLine key={index} />;
    }
    return (
      <UserInformation key={index}>
        <Label>{item.label}:</Label>
        <Value>{getFieldValue(form, item)}</Value>
      </UserInformation>
    );
  };

  return (
    <ViewContainer>
      <HeaderRow border={'top'}>
        <RequestHeader>{ requestForm?.name }</RequestHeader>
      </HeaderRow>
      <Row>
        <ReportContainer>
          {Object.keys(requestForm) ? (
            userFields.map((item, index) => displayValue(requestForm, item, index))
          ) : (
            <></>
          )}
        </ReportContainer>
      </Row>
      <Row>
        <DownloadRequestForm requestForm={requestForm} />
      </Row>
    </ViewContainer>
  );
};

export default RequestFormDetails;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Row = styled.div<{border?: string}>`
  display: flex;
  font-size: 12px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  padding-top: 5px;
  ${(p) => p.border === 'top' && `border-top: 1px solid ${p.theme.palette.accentPrimary}`};
`;
Row.defaultProps = {
  border: ''
}

const HeaderRow = styled(Row)`
  justify-content: center;
`;

const ActionButtons = styled.div`
  display: flex;
  width: max-content;
`

const RequestHeader = styled(ActionButtons)`
  font-size: 14px;
  color: ${(p) => p.theme.palette.accentSecondary};
`;

const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  height: max-content;
`;

const HorizontalLine = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
`;

const UserInformation = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  display: flex;
  width: 150px;
  margin-right: 5px;
  justify-content: flex-end;
  font-size: 14px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const Value = styled.div`
  display: flex;
  width: 250px;
  font-size: 14px;
`;
