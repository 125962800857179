import React, { useContext } from 'react';
import { PlotContext } from '../plot-panel/plot.context';
import { Box, Button, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridFooter,
  GridFooterContainer,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { Download } from '@mui/icons-material';
import styled from 'styled-components/macro';
import { getDownloadLink } from './utils';
import { getPlotVariables } from '@plotting/plotting-utils';

export const ViewDataPanel = () => {
  const { plot } = useContext(PlotContext);
  const headers = getPlotVariables(plot);

  const download = () => {
    const link = getDownloadLink(plot.data);
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  };

  const columns: GridColDef[] = headers.map((header) => ({
    field: `${header}`,
    headerName: `${header}`,
    width: 150,
    editable: false,
  }));

  columns.unshift({
    field: '__id',
    headerName: '',
    width: 45,
    editable: false,
    align: 'right',
    resizable: false,
    disableColumnMenu: true,
    renderCell: ({ value }: GridRenderCellParams<number>) => (
      <Typography color='textSecondary'>{value}</Typography>
    ),
  });

  const rows = plot.data.map((value, index) => ({
    __id: index + 1,
    ...value,
  }));

  const getRowId = (datum) => {
    return datum.__id;
  };

  const footer = () => (
    <GridFooterContainer>
      <Box m={2}>
        <Button endIcon={<Download />} onClick={download}>
          Download as CSV
        </Button>
      </Box>
      <GridFooter sx={{ border: 'none' }} />
    </GridFooterContainer>
  );

  return (
    <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
      <Box sx={{ position: 'absolute', inset: '60px' }} m={4}>
        <Box style={{ height: '100%', width: '100%' }}>
          <StyledDataGrid
            rows={rows}
            columns={columns}
            getRowId={getRowId}
            components={{ Footer: footer }}
            pageSize={100}
            rowsPerPageOptions={[5]}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: theme.palette.backgroundQuaternary,
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader': {
    borderRight: 'none',
    borderLeft: 'none',
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: 'none',
  },
  '& .MuiDataGrid-cell': {
    color: theme.palette.textPrimary,
    borderRight: 'none',
    borderLeft: 'none',
    borderBottom: 'none',
  },
}));
