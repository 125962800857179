import React, { useContext } from "react";
import { useParams } from 'react-router-dom';
import PageContainer from "@as_core/pages/PageContainer";
import { MenuContext } from '@stores/MenuContext';
import { getTitle, getComponent } from './utils';
import {UserContext} from "@stores/UserContext";
import useShoppingCart from "@as_core/hooks/useShoppingCart";

const actionToMenuId = {
  'order': 'order',
  'create': 'order',
  'stripe': 'orders_active',
  'success': 'orders_active',
  'active': 'orders_active',
  'complete': 'orders_complete',
  'requests': 'orders_requests',
  'consulting': 'orders_consulting'
}


const debug = false;
const PgOrders = () => {
  const { action } = useParams(); // this is the action that sets what to do
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  const { user, setUser} = useContext(UserContext);
  const ordersCart = useShoppingCart({});

  if (user.subApp !== 'Orders') {
    setUser((prev) => ({
      ...prev, subApp: 'Orders'
    }));
  }
  // make sure menu is correct for the subApp
  if (Object.hasOwn(actionToMenuId, action)) {
    if (activeMenuId !== actionToMenuId[action]) setActiveMenuId(actionToMenuId[action]);
  } else {
    console.error('Unknown menuId for action ', action);
  }

  if (debug) console.log("PgOrders | action:", action);

  return (
    <PageContainer title={getTitle(action)} padding={5}>
      { getComponent(action, ordersCart) }
    </PageContainer>
  );
};

export default PgOrders;
