import React, {useEffect, useState} from "react";
import styled from 'styled-components/macro';
import {FilesT} from "@as_core/controls/inputs/FileUpload";
import {RequestT} from "@subApps/orders/hooks/useRequests";
import useRequestForms, {RequestFormT} from '@subApps/orders/hooks/useRequestForms';
import DataLoading from "@as_core/elements/DataLoading";
import ErrorMessages, {MessageItem} from "@as_core/elements/UserMessages";
import TextButton from "@as_core/controls/buttons/TextButton";
import RequestFormsTable from "@subApps/orders/components/Assays/RequestFormsTable";

interface PropsI {
  request: RequestT;
  requestForms: RequestFormT[];
  setRequestForms: (forms:RequestFormT[])=>void;
  setUpdated: (v:boolean)=>void;
  handleClose: ()=>void;
  files: FilesT;
}

const debug = false;
const RequestUploadForms = (props:PropsI) => {
  const {request, requestForms, setRequestForms, setUpdated, handleClose, files} = props;
  const { deleteRequestForm, createRequestFormWithFile } = useRequestForms();
  const [areFormsUploaded, setAreFormsUploaded] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  if (debug) console.log('RequestUploadForms | request:', request, 'files:', files);

  const deletePriorForms = async(requestForms: RequestFormT[])=> {
    const promises = requestForms.map(async (form): Promise<void> => {
      return deleteRequestForm(Number(form.id));
    });
    await Promise.all(promises);
  }

  const createRequestForms = async (request: RequestT, files: FilesT): Promise<RequestFormT[]> => {
    const promises = files.map(async (file, index): Promise<RequestFormT> => {
      // create the request - need to send as form data to handle the file object for s3
      const formData = new FormData();
      formData.append('plate_map_file',  file);
      formData.append('index', index.toString());
      formData.append('request_id', request.id);
      formData.append('request_name',request.name + '_form_' + index )
      if (debug) console.log('RequestUploadForms | formData', formData);
      return createRequestFormWithFile(formData);
    });
    const requestForms: RequestFormT[] = await Promise.all(promises);
    if (debug) console.log('requestForms ', requestForms);
    return requestForms;
  }

  // Step 2: Create the requests and add them to the order
  useEffect(() => {
    if (debug) console.log('areFormsUploaded', areFormsUploaded);
    if (!areFormsUploaded && !isUploading) {
      if (files.length !== 0) {
        if (debug) console.log('OrdersStripe | USE_EFFECT | create requests');
        setIsUploading(true);
        if (debug) console.log('RequestUploadForms | creating requests for request:', request?.id);
        // create the requests -- but catch if they have already been created
        deletePriorForms(requestForms)
          .then(() => {
            createRequestForms(request, files)
              .then((requestForms) => {
                  if (debug) console.log('RequestUploadForms | requestIds ', requestForms);
                  setRequestForms(requestForms);
                  setAreFormsUploaded(true);
                  setIsUploading(false);
                  setUpdated(true);
                }
              );
          }
        );
      } else {
        if (debug) console.log('RequestUploadForms | USE_EFFECT | no files -- skipping');
        setAreFormsUploaded(true);
      }
    }
  }, [files]);

  return (
    <ListContainer>
      {isUploading ?
        <DataLoading />
        :
        <>
          <ErrorMessages messages={[
            <MessageItem key='error_message_item_1'>{files.length} plate map {files.length > 1 ? 'files' : 'file' } successfully uploaded</MessageItem>
          ]}/>
          <RequestFormsTable
            header={true}
            short={false}
            requestForms={requestForms}
          />
          <TextButton
            key={'request_form_upload_confirm'}
            label={'Finish'}
            onClick={handleClose}
          />
        </>
      }
    </ListContainer>
  );
};

export default RequestUploadForms;

const ListContainer = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;
