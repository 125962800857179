import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {RequestT} from '../../hooks/useRequests';
import BasicTable from '@as_core/tables/BasicTable';
import RequestDetails from "@subApps/orders/components/Common/RequestDetails";
import ErrorMessages, {MessageItem} from "@as_core/elements/UserMessages";
import {TableFieldsT, getTableRows} from "@subApps/orders/utils";

const tableFields: TableFieldsT[] = [
  { value: 'view', label: '', type: 'action', width: 40 },
  { value: 'status', label: 'Fulfilment Status', type: 'status', width: 150},
  { value: 'prod_name', label: 'Request Type', width: 240 },
  { value: 'name', label: 'Name', type: 'shortString', width: 200 },
  { value: 'created_at', label: 'Created', type: 'date', width: 100 },
  { value: 'quantity', label: 'Quantity', type: 'quantity', width: 100 },
  { value: 'updated_at', label: 'Last Updated', type: 'datetime', width: 150 },
];

type PropsT = {
  requests: RequestT[];
  requestsUpdated: ()=> void;
  requestType?: string;
}


const debug = false;
const RequestsTable = (props:PropsT) => {
  const { requests, requestsUpdated, requestType = 'assay' } = props;
  if (debug) console.log('requestsList | user: ', requests);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const handleClick = (requestId: string) => {
    if (debug) console.log('handleClick: ', requestId);
    const matched:RequestT = requests.find((o) => o.id === requestId);
    if (matched) {
      setSelectedRequest(matched);
    } else {
      console.error(`RequestsTable | unable to locate request by id ${requestId})`);
    }
  };

  if (debug) console.log('RequestsTable | requests:', requests);
  return (
    <>
      { requests.length ?
        <RequestTableContainer>
          { selectedRequest !== null ?
            <RequestDetails
              request={selectedRequest}
              updateRequest={setSelectedRequest}
              setUpdatedRequestForms={requestsUpdated}
              handleBack={()=>setSelectedRequest(null)}
            />
          :
            <BasicTable
              fields={tableFields}
              rows={getTableRows(requests, tableFields, handleClick)}
              border={false}
            />
          }
        </RequestTableContainer>
      :
          <ErrorMessages messages={[
            <MessageItem key='emi_no_requests'>You do not have any {requestType} requests</MessageItem>
          ]}/>
      }
    </>
  );
};

export default RequestsTable;


const RequestTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
