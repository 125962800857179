import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import useAggregation from './useAggregation';
import useCognito from '@as_core/account/useCognito';
import { LineGraphT, LineGraph } from './plots/LineGraph';
import ToTableViewButton from '@pages/dashboard/ToTableViewButton';
import { ButtonBase } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { segmentImages } from '@as_core/account/utils/images';
interface CategoryT {
  _id: string;
  count: number;
}

const cat_values = [
  { _id: 'Agricultural Chemical', count: 21 },
  { _id: 'Cosmetic Chemical', count: 7 },
  { _id: 'Industrial Chemical', count: 63 },
  { _id: 'Natural Product', count: 103 },
  { _id: 'Nutraceutical', count: 5 },
  { _id: 'Pharmaceutical', count: 203 },
  { _id: 'Veterinary', count: 5 },
  { _id: 'Vitamin/Supplement', count: 5 },
  { _id: 'Un-Annotated', count: 393 },
];

type mapRequestDataPropsT = { result: any; ann_name: string };

const mapRequestData = ({
  result,
  ann_name,
}: mapRequestDataPropsT): LineGraphT => {
  const { values } = result;
  return {
    id: ann_name + 'xz',
    data: values
      .filter((item) => item._id.toString() !== 'other')
      .map((item) => ({
        x: item._id,
        y: item.count,
      })),
  };
};

const debug = false;
const ZebraFishDistributionExplorer = () => {
  const {
    getBucketAnnotationsDataCategory,
    getBucketAnnotationsDataCategoryDistribution,
    getCancelSource,
  } = useAggregation();
  const { getToken } = useCognito();
  const cancelSource = getCancelSource();
  const navigate = useNavigate();
  // SET PARAMETERS
  const library = 'aseda';

  // STATES
  const [yMax, setYMax] = useState(5);
  const [selectedClass, setSelectedClass] = useState<string>(
    'Agricultural Chemical'
  );
  const [categoryDataLoading, setCategoryDataLoading] = React.useState(false);
  const [categoryData, setCategoryData] = React.useState<CategoryT[]>([]);
  const [noObserved, setNoObserved] = useState(0);
  const [lineGraphLoading, setLineGraphLoading] = React.useState(false);
  const [lineGraphData, setLineGraphData] = React.useState<LineGraphT[]>([]);

  // HANDLERS
  const handleCategoryClick = (id) => {
    console.log('handleCategoryClick', id);
    setSelectedClass(id);
  };

  const handleClick = () => {
    const url = '/compounds/aseda/table?filter=&value=' + selectedClass;
    navigate(url);
  };

  //EFFECTS DATA LOADING
  useEffect(() => {
    if (!categoryDataLoading) {
      // turn off temporarily
      setCategoryDataLoading(true);
      const config = {
        cancelToken: cancelSource.token,
        params: { library: library },
      };
      getBucketAnnotationsDataCategory(
        getToken(),
        'market_segment',
        'zf_mort',
        config
      ).then((result) => {
        if (result != null) {
          if (debug) console.log('result ', result);
          setCategoryData(result.values.filter((v) => v._id !== ''));
        }
        setCategoryDataLoading(false);
      });
    } else {
      setCategoryData(cat_values);
    }
    return () => {
      setCategoryDataLoading(false);
      cancelSource.cancel('exit');
    };
  }, [library]);
  if (debug) console.log('categoryData', categoryData);

  const parseForOther = (values) => {
    let otherValues = values.filter((item) => item._id === 'other');
    debug &&
      console.log('ZebraFishDistributionExplorer | otherValues:', otherValues);
    if (otherValues.length) {
      const noObserved = _.get(otherValues[0], 'count', 0);
      setNoObserved(noObserved);
    }
  };

  useEffect(() => {
    if (!lineGraphLoading && selectedClass !== null) {
      setLineGraphLoading(true);
      const params = new URLSearchParams();
      params.append('ann_field_id', 'market_segment');
      params.append('data_exists_field_id', 'zf_mort');
      params.append('ann_name', selectedClass);
      params.append('dist_field_id', 'zf_mort');
      params.append('log10', 'True');
      params.append('library', library);

      const config = { cancelToken: cancelSource.token, params };
      getBucketAnnotationsDataCategoryDistribution(getToken(), config).then(
        (result) => {
          if (result != null) {
            let ann_name = selectedClass;
            parseForOther(result?.values);
            setLineGraphData([mapRequestData({ result, ann_name })]);
            let max = 5;
            result.values.map((item) => {
              if (item._id !== 'other' && item?.count > max) {
                max = item.count;
              }
            });
            if (max > 5) {
              max = 5 * (Math.floor(max / 5.0) + 1);
            }
            setYMax(max);
          }
          setLineGraphLoading(false);
        }
      );
      return () => {
        setLineGraphLoading(false);
        cancelSource.cancel('exit');
      };
    }
  }, [selectedClass, library]);

  return (
    <Row>
      <Item>
        <CategoryTiles>
          {categoryData.map((v, i) => {
            return (
              <CategoryTile
                key={'tile_' + i}
                className={v._id === selectedClass ? 'active' : ''}
                onClick={() => handleCategoryClick(v._id)}
              >
                <Top>
                  <CategoryNumber>{v.count}</CategoryNumber>
                  <CategoryImage>
                    {segmentImages.hasOwnProperty(v._id) ? (
                      <img src={segmentImages[v._id]} height='50' alt={v._id} />
                    ) : (
                      <></>
                    )}
                  </CategoryImage>
                </Top>
                <CategoryName>{v._id}</CategoryName>
              </CategoryTile>
            );
          })}
        </CategoryTiles>
      </Item>
      <Item>
        <ItemPartsTop>
          <NoObserved>No Observed Effect: {noObserved}</NoObserved>
          <ToTableViewButton
            text={'Zebrafish Mortality for ' + selectedClass + ' compounds'}
            tooltip={'View compounds in table'}
            tooltipPlacement={'bottom'}
            onClick={handleClick}
          />
        </ItemPartsTop>
        <ItemPartsBottom>
          <GraphContainer>
            {!lineGraphLoading ? (
              <LineGraph
                data={lineGraphData}
                xAxisLabel={'Zebrafish Mortality-24hr (BMD10), uM'}
                yAxisLabel={'Number of Compounds'}
                yMax={yMax}
                onClick={() => {}}
              />
            ) : (
              <div>Loading</div>
            )}
          </GraphContainer>
        </ItemPartsBottom>
      </Item>
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 500px;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
`;

const ItemPartsTop = styled.div`
  display: flex;
  width: 100%;
  margin-left: -140px;
`;

const ItemPartsBottom = styled.div`
  display: flex;
  width: 100%;
`;

const NoObserved = styled.div`
  font-size: 12px;
  display: inline-flex;
  position: relative;
  top: 100px;
  left: 200px;
  width: 150px;
`;

const GraphContainer = styled.div`
  align-items: center;
  height: 440px;
  width: 100%;
`;

const CategoryTiles = styled.div`
  padding: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const CategoryTile = styled(ButtonBase)`
  display: flex;
  flex-direction: column;
  margin: 5px;
  padding: 5px;
  border-radius: 10px;
  font-style: normal;
  text-decoration: none;
  &.MuiButtonBase-root {
    background-color: ${(p) => p.theme.palette.backgroundTertiary};
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    width: 150px;
    height: 80px;
  }
  &.active {
    background-color: ${(p) => p.theme.palette.accentPrimary};
    color: ${(p) => p.theme.palette.textPrimary};
  }
  :hover {
    background-color: ${(p) => p.theme.palette.accentSecondary};
  }
`;

const CategoryNumber = styled.div`
  display: flex;
  font-size: 32px;
  width: 40px;
  margin-top: 5px;
  margin-right: 10px;
  justify-content: center;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const CategoryImage = styled.div`
  display: flex;
`;

const CategoryName = styled.div`
  display: flex;
  padding: 0;
  font-size: 12px;
  margin-top: 5px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

export default ZebraFishDistributionExplorer;
