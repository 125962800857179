import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import PageContainer from '@as_core/pages/PageContainer';
import HeaderMenu from '@as_core/controls/HeaderMenu';
import { PagePanelContainer } from '@components/pages/PageComponents';
import UploadData from './userData/UploadData';
import { UploadDocumentList } from './userData/dataList/UploadDocumentList';
import { MenuContext } from '@stores/MenuContext';
import { FlexBanner } from '@as_core/elements/flexStyles';

type actionT = {
  key: string;
  label: string;
};

const menuActions: Array<actionT> = [
  { key: 'list', label: 'My Data Sets' },
  { key: 'upload', label: 'Upload New Data Set' },
];

const debug: boolean = process.env.REACT_APP_DEBUG === 'dev';

const PgUserData = () => {
  const params = useParams();
  const { tab } = params;
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  if (activeMenuId !== 'upload') setActiveMenuId('upload');
  const navigate = useNavigate();
  if (debug) console.log('PgUserData | userParams:', params, ' tab:', tab);

  return (
    <PageContainer title='Import Data Tool' padding={0}>
      <PagePanelContainer overflowY={'hidden'}>
        <FlexBanner>
          {menuActions.map((action) => (
            <HeaderMenu
              key={`user_data_${action.key}`}
              label={action.label}
              isActive={action.key === tab}
              onClick={() => navigate(`/import/${action.key}`)}
            />
          ))}
        </FlexBanner>
        <UserDataContent>
          {tab === 'upload' ? <UploadData /> : <UploadDocumentList />}
        </UserDataContent>
      </PagePanelContainer>
    </PageContainer>
  );
};

export default PgUserData;

const UserDataContent = styled.div`
  height: 100%;
  width: calc(100% - 10px);
`;
