import React from 'react';
import { linearGradientDef } from '@nivo/core';
import { ResponsiveLine } from '@nivo/line';
import { useTheme } from 'styled-components/macro';

interface Datum {
  x: number;
  y: number;
}

interface Series {
  id: string;
  data: Datum[];
}

export interface LineGraphT {
  id: string;
  data: Datum[];
}

interface PropType {
  data: Series[];
  xAxisLabel?: string;
  yAxisLabel?: string;
  yMax?: number;
  onClick?: (point, event) => void;
}

export const LineGraph = (props: PropType) => {
  const theme = useTheme();
  const { data, xAxisLabel, yAxisLabel, yMax = 5, onClick } = props;

  const yFormat = (e: number | string | Date) => {
    if (typeof e === 'number') {
      if (Math.floor(e) === e) {
        return Math.floor(e);
      } else {
        return '';
      }
    } else {
      throw new Error('Unexpected value');
    }
  };

  return (
    // TS doesn't like the nivo TS definition
    // @ts-ignore
    <ResponsiveLine
      defs={[
        linearGradientDef('gradientA', [
          { offset: 0, color: theme.palette.accentPrimary },
          { offset: 100, color: theme.palette.accentSecondary, opacity: 0.05 },
        ]),
      ]}
      data={data}
      onClick={onClick}
      margin={{ top: 30, right: 10, bottom: 60, left: 60 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 0,
        max: yMax,
      }}
      enableGridX={false}
      enableGridY={false}
      isInteractive={false}
      enableCrosshair={false}
      curve='stepAfter'
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: xAxisLabel ?? '',
        legendOffset: 36,
        legendPosition: 'middle',
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: yAxisLabel ?? '',
        legendOffset: -40,
        legendPosition: 'middle',
        format: e => yFormat(e)
      }}
      pointSize={0}
      pointLabelYOffset={0}
      useMesh={true}
      enableArea={true}
      areaOpacity={0.6}
      fill={[{ match: '*', id: 'gradientA' }]}
      colors={[theme.palette.accentPrimary]}
      theme={{
        textColor: theme.palette.textSecondary,
        crosshair: {
          line: {
            stroke: theme.palette.accentSecondary,
            strokeWidth: 1,
            strokeOpacity: 0.8,
          },
        },
        tooltip: {
          container: {
            background: '#111111',
            color: '#fff',
            opacity: 0.8
          }
        },
        axis: {
          domain: {
            line: {
              strokeWidth: 1,
              stroke: theme.palette.textSecondary
            }
          }
        }
      }}
    />
  );
};
