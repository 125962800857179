import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '@stores/UserContext';
import PageContainer from '@as_core/pages/PageContainer';
import AdminUserManagement from '@subApps/admin/AdminUserManagement';
import AdminRepoManagement from '@subApps/admin/AdminRepoManagement';
import UserInsights from "@subApps/admin/UserInsights";
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { isUserAnAdmin } from '@as_core/account/utils/admin';
import {FlexBanner} from "@as_core/elements/flexStyles";
import HeaderMenu from "@as_core/controls/HeaderMenu";
import {useNavigate, useParams} from "react-router-dom";
import {MenuContext} from "@stores/MenuContext";

// ####################################################################
type actionT = {
  key: string;
  label: string;
  icon?: React.ReactNode;
};

const menuActions: Array<actionT> = [
  { key: 'users', label: 'User Management', icon: <ManageAccountsOutlinedIcon /> },
  { key: 'repos', label: 'Repository Management', icon: <GroupsOutlinedIcon /> },
];

const debug = false;
const PgAdmin = () => {

  const params = useParams();
  const { tab } = params;
  const tabs = menuActions.map((v) => v.key);
  const { user, setUser } = useContext(UserContext);
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  const navigate = useNavigate();

  if (user.subApp !== 'Admin') {
    setUser((prev) => ({
      ...prev, subApp: 'Admin'
    }));
  }
  // some by tabs - some by tab as view
  const menuId = tabs.includes(tab) ? 'admin': 'a.' + tab;
  if (activeMenuId !== menuId) setActiveMenuId(menuId);

  if (debug) console.log('PgAdmin | user:', user);

  return (
    <PageContainer title='Admin'>
      <AdminPanel>
        {isUserAnAdmin(user) ?
          tabs.includes(tab) ?
            <>
              <FlexBanner>
                {menuActions.map((action) => (
                  <HeaderMenu
                    key={`user_data_${action.key}`}
                    label={action.label}
                    icon={action.icon}
                    isActive={action.key === tab}
                    onClick={() => navigate(`/admin/${action.key}`)}
                  />
                ))}
              </FlexBanner>
              <AdminContent>
                {tab === 'users' ? <AdminUserManagement /> : <AdminRepoManagement />}
              </AdminContent>
            </>
          :
            <UserInsights />
        :
          <ErrorContainer>
            It appears that you landed on a page you are not authorized to
            access.
          </ErrorContainer>
        }
      </AdminPanel>
    </PageContainer>
  );
};

export default PgAdmin;

const AdminPanel = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: column;
  width: calc(100vw - 67px);
  height: calc(100vh - 70px);
  overflow-y: auto;
  overflow-x: hidden;
`;

const ErrorContainer = styled.div`
  align-items: center;
`;

const AdminContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: calc(100% - 10px);
`;
