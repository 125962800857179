import React, { useState } from 'react';
import styled from 'styled-components/macro';
import '@pages/pages_styles/ingestionUI.css';
import PageContainer from '@as_core/pages/PageContainer';
import UploadComponentV2 from '@components/UploadComponentV2';
import { FileImportContext } from '@stores/FileImportContext';
import UploadDataStepper from '../subApps/dataUpload/userData/shared/UploadDataStepper';
import * as XLSX from 'xlsx';
import AssignColumnsTableRequiredV2 from '../subApps/dataUpload/userData/shared/assignColumns/AssignColumnTableRequiredV2';
import { useNavigate } from 'react-router-dom';
import PagePanelUpload from '@components/pages/PagePanelUpload';
import files from '../images/files.png';
import dataset from '../images/dataset.png';
import viz from '../images/viz.png';
import {
  ColumnsInit,
  LibrariesInit,
  LibraryT,
  UseFileImportContextT,
  workbookT,
} from '../subApps/dataUpload/userData/useFileImport';
import { LibrarySearchRequestT } from '@utils/useLibrarySearch';


export type matchColumnT = {
  value: string;
  label: string;
  matched_column: string;
  required: boolean;
};

const columnIdentities: Array<matchColumnT> = [
  { value: 'compound', label: 'Compound Name/ID', required: true, matched_column: '' },
  { value: 'sample', label: 'Sample or Lot ID', required: true, matched_column: '' },
  { value: 'dose', label: 'Dose or Concentration', required: true, matched_column: '' },
  { value: 'dose_units', label: 'Dose or Conc. Units', required: false, matched_column: '' },
  { value: 'response_%', label: 'Response (%)', required: true, matched_column: '' },
];

const INITIAL_STEP = 0;

const PgUploadV2 = () => {
  const steps = ['Choose Dataset', 'Column Mapping', 'Processing & Transformation', 'Data Cleaning', 'Data Visualization'];

  const [workbook, setWorkbook] = useState<workbookT>();
  const [activeStep, setActiveStep] = useState(INITIAL_STEP);
  const [importer, setImporter] = useState('generic');
  const [fileLoaded, setFileLoaded] = useState(false);
  const [activeColumn, setActiveColumn] = useState<number>(null);
  const [columns, setColumns] = useState([]);
  const [libraries, setLibraries] = useState<LibraryT[]>(LibrariesInit);
  const [requests, setRequests] = useState<LibrarySearchRequestT[]>([]);
  const [nextAvailable, setNextAvailable] = useState<boolean>(false);
  const [columnIDs, setColumnIDs] = useState<matchColumnT[]>(columnIdentities);
  const [showSectionHome, setShowSectionHome] = useState(true);
  const [showSectionTable, setShowSectionTable] = useState(false);
  const [columnData, setColumnData] = useState({});
  const [fileData, setFileData] = useState(null);
  // const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  // if (activeMenuId !== 'upload') setActiveMenuId('upload');

  const navigate = useNavigate();

  // activeStep,
  // setActiveStep,
  // columns,
  // setColumns,
  // libraries,
  // setLibraries
  
  const contextData: UseFileImportContextT = {
    workbook,
    setWorkbook,
    activeStep,
    setActiveStep,
    columns,
    setColumns,
    activeColumn,
    setActiveColumn,
    importer,
    setImporter,
    fileLoaded,
    setFileLoaded,
    libraries,
    setLibraries,
    requests,
    setRequests,
  };

  const handleReset = () => {
    contextData.setFileLoaded(false);
    contextData.setWorkbook(null);
    contextData.setActiveColumn(null);
    contextData.setColumns(ColumnsInit);
    contextData.setLibraries(LibrariesInit);
    contextData.setActiveStep(INITIAL_STEP);
    navigate('/import/V2');
  };

  const setMatchedColumn = (name, value) => {
    const updatedColumnIDs = columnIDs;
    const index = columnIdentities.findIndex((c) => c.value === name);
    if (index !== -1) updatedColumnIDs[index].matched_column = value;
    setColumnIDs(updatedColumnIDs);
  };

  const handleNext = () => {
    contextData.setActiveStep((currStep) => currStep + 1);
  };

  const handleBack = () => {
    contextData.setActiveStep((currStep) => {
      let nextStep = currStep;
      if (currStep > 0) {
        nextStep = currStep - 1;
      }
      if (nextStep <= 0) {
        handleReset();
        setShowSectionHome(true);
        setShowSectionTable(false);
      }
      return nextStep;
    });
  };

  const handleFileDrop = async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const fileData = await readFileData(file);
      const workbook = XLSX.read(fileData, { type: 'array' });
      setFileData(acceptedFiles);
      setShowSectionHome(false);
      setShowSectionTable(true);
      contextData.setActiveStep((currStep) => currStep + 1);
      let columnHeaders = [];

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const newColumnData = {};

      for (let key in sheet) {
        let regEx = new RegExp('^(\\w)(1){1}$');
        if (regEx.test(key) === true) {
          columnHeaders.push(sheet[key].v);
          const header = sheet[key].v;
          newColumnData[header] = sheet[`${key[0]}${2}`]?.v;
        }
      }
      contextData.setColumns(columnHeaders);
      contextData.setWorkbook({
        filename: file.name,
        workbook: workbook,
      });
      setColumnData(newColumnData);
    }
  };

  const readFileData = (file: File)=> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsArrayBuffer(file);
    });
  };

    return (
      <FileImportContext.Provider value={contextData}>
        <PageContainer title="Upload Data" >
          <UploadDataStepper
            handleBack={handleBack}
            handleReset={handleReset}
            handleNext={handleNext}
            uploadStep={5}
            steps={steps}
            noJustify={true}
          />
          {showSectionHome && (
            <SectionHome>
              <PagePanelUpload height="81vh" width="98%">
                <Container>
                  <LeftColumn>
                    <TitleSpan>3RnD Platform Analysis</TitleSpan>
                    <DescSpan>
                      <CreateSpan>Create</CreateSpan> <TransformSpan>Transform</TransformSpan> & <AnalyzeSpan>Analyze</AnalyzeSpan> your data
                    </DescSpan>
                    <ImageContainer>
                      <ImageContent>
                        <img src={files} height={100} width={100} alt="Files" />
                        <Text>Upload Data</Text>
                      </ImageContent>
                      <Arrow>&#8594;</Arrow>
                      <ImageContent>
                        <img src={dataset} height={100} width={180} alt="Dataset" />
                        <Text>Transform Data</Text>
                      </ImageContent>
                      <Arrow>&#8594;</Arrow>
                      <ImageContent>
                        <img src={viz} height={100} width={180} alt="Visualization" />
                        <Text>Analyze Data</Text>
                      </ImageContent>
                    </ImageContainer>
                  </LeftColumn>
                  <RightColumn>
                    <UploadComponentV2 onDrop={handleFileDrop} />
                  </RightColumn>
                </Container>
              </PagePanelUpload>
            </SectionHome>
          )}
          {showSectionTable && (
            <SectionTable>
              <AssignColumnsTableRequiredV2
                columnFields={columnIDs}
                columnData={columnData}
                setMatchedColumn={setMatchedColumn}
                nextAvailable={nextAvailable}
                setNextAvailable={setNextAvailable}
                fileData={fileData}
              />
            </SectionTable>
          )}
        </PageContainer>
      </FileImportContext.Provider>
    );
};

export default PgUploadV2;



const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  

`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 50%;
`;

const RightColumn = styled.div`
  margin-top: 50px !important;
  width:45%;
  height: 100%;
  border-radius: 85px;
  border: dashed;
  margin-left: 10px;
`;

const CreateSpan = styled.span`
  color: rgb(214, 11, 81);
`;

const TransformSpan = styled.span`
  color: #3838dc;
`;

const AnalyzeSpan = styled.span`
  color: gold;
`;

const TitleSpan = styled.span`
  font-size: 48px;
  font-weight: bold;
  margin-top: 10%;
  text-align: center;
`;

const DescSpan = styled.span`
  font-size: 32px;
  font-weight: bold;
  margin-top: 12%;
  text-align: center;
`;

const SectionHome = styled.div`

  height: 100vh; /* Set the height to 100% of the viewport height */
`;
const SectionTable = styled.div`

  height: 100%;
  width: 98%;
  align-self: center;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15%;
  flex-wrap: wrap;
  
`;
const ImageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;



const Arrow = styled.span`
  font-size: 36px;
  margin: 0 20px;
  align-self: center;
`;

const Text = styled.span`
  text-align: center;
  width: 175px;
`;

