import React from 'react';
import { rgb, hsl } from 'd3-color';
import { ScatterPlotDatum, ScatterPlotTooltipProps } from '@nivo/scatterplot';
import { TooltipContainer } from '@dataviz/TooltipContainer';

export const getScatterplotTooltip = () => {
  const ScatterplotTooltip = ({
    node,
  }: ScatterPlotTooltipProps<ScatterPlotDatum & { size: number }>) => {
    const { serieId, color, formattedX, formattedY } = node;

    const baseColor = hsl(rgb(color).formatHsl());
    baseColor.l = Math.max(0.7, baseColor.l + 0.2);

    return (
      <TooltipContainer color={baseColor.toString()}>
        <p style={{ color: baseColor.toString(), fontSize: 14 }}>
          <b> {serieId}</b>
        </p>
        <p>{'x: ' + parseFloat(Number(formattedX).toFixed(3))}</p>
        <p>{'y: ' + parseFloat(Number(formattedY).toFixed(3))}</p>
        {node.data.size && (
          <p>{'size: ' + parseFloat(Number(node.data.size).toFixed(3))}</p>
        )}
      </TooltipContainer>
    );
  };

  return ScatterplotTooltip;
};
