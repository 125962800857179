import React from "react";
import styled from "styled-components/macro";
import DataSaveIcon from "@components/icons/dataSave.icon";
import {Tooltip} from "@mui/material";
import useRequestForms, {RequestFormT} from "@subApps/orders/hooks/useRequestForms";

type PropsT = {
  requestForm: RequestFormT;
};

const debug = false;
const DownloadRequestForm = (props:PropsT) => {
  const { requestForm } = props;
  const { getRequestFormFile } = useRequestForms();

  const handleClick = async (requestForm: RequestFormT) => {
    if (debug) console.log('DownloadRequestForm | handleClick', requestForm);
    const response = await getRequestFormFile(requestForm.id)
    const link=document.createElement('a');
    if (debug) console.log('DownloadRequestForm | handleClick | response', response);
    link.href = response.data;
    link.setAttribute('download', requestForm.original_file_name);
    document.body.appendChild(link);
    link.click();
    link.remove();
    document.body.appendChild(link);
  }

  return(
    <Tooltip
      title={`Download ${requestForm.original_file_name}`}
      arrow
      placement={'bottom-end'}
      >
     <DownloadLink onClick={()=>handleClick(requestForm)}>
       <DataSaveIcon />
     </DownloadLink>
    </Tooltip>
  )
}

export default DownloadRequestForm;

const DownloadLink = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: grayscale(0.75);
  :hover {
    filter: grayscale(0.0);
  }
`;