import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components/macro';

import PageContainer from '@as_core/pages/PageContainer';
import { useParams } from 'react-router-dom';
import CompoundUniverse from '../components/universe/CompoundUniverse';
import useUniverses from '@components/universe/useUniverses';
import { FlexColumn, FlexItem } from '@components/layout/FlexStyles';
import {
  TextSmallBold,
  TextSmallItalics,
} from '@components/elements/TextStyles';
import { PagePanelContainer } from '@components/pages/PageComponents';
import DataLoading from '@as_core/elements/DataLoading';
import { UniverseCreate } from '@components/universe/user/UniverseCreate/UniverseCreate';
import { UniverseList } from '@components/universe/user/UniverseList/UniverseList';
import { MenuContext } from '@stores/MenuContext';

const debug: string = ''; // data || info

const myComponentName = 'PUniverseUser';
const PgUniverseUser = () => {
  const { uuid } = useParams();
  const { getInfo, getNetwork, getImages } = useUniverses();
  // eslint-disable-next-line
  const [info, setInfo] = useState<any>();
  // eslint-disable-next-line
  const [data, setData] = useState<any>();
  // eslint-disable-next-line
  const [images, setImages] = useState<any>();
  const [loadingErrors, setLoadingErrors] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMessage, setLoadingMessage] = useState<string>('');

  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);

  useEffect(() => {
    if (activeMenuId !== 'userUniverse') setActiveMenuId('userUniverse');
  }, [activeMenuId]);

  if (debug) console.log(myComponentName, 'loading', loading, 'uuid:', uuid);

  useEffect(() => {
    if (uuid) {
      setLoading(true);
      ['data'].includes(debug) &&
        console.log(myComponentName + ' | loading data for: ', uuid);
      (async function () {
        setLoadingMessage('Retrieving Universe Information');
        const response = await getInfo(uuid, {});
        ['data'].includes(debug) &&
          console.log(myComponentName + ' | response(info): ', response);
        setInfo(response);
        setLoadingMessage('Retrieving Universe Graph');
        const responseNetwork = await getNetwork(uuid, {});
        ['data'].includes(debug) &&
          console.log(
            myComponentName + ' | response(network): ',
            responseNetwork
          );
        if (responseNetwork.errors.length) {
          console.error(responseNetwork.errors);
          setLoadingErrors(responseNetwork.errors[0]);
        } else {
          setData(responseNetwork?.data);
        }
        if (!loadingErrors) {
          setLoadingMessage('Retrieving Universe Images');
          const images = await getImages(uuid, {});
          ['data'].includes(debug) &&
            console.log(myComponentName + ' | response2(graph): ', images);
          setImages(images);
        }
        setLoading(false);
      })();
    }
  }, [uuid]);

  if (!uuid) {
    return (
      <PageContainer title='My Universe' padding={0}>
        <PagePanelContainer>
          <UniverseCreate />
          <UniverseList />
        </PagePanelContainer>
      </PageContainer>
    );
  }

  ['info', 'data'].includes(debug) &&
    console.log('PgMyUniverse | info.settings :', info?.settings);
  ['info', 'data'].includes(debug) &&
    console.log('PgMyUniverse | data :', info?.data);

  return (
    <PageContainer title='My Universe' padding={0}>
      <PagePanelContainer>
        {loading ? (
          <DataLoading message={loadingMessage} />
        ) : data ? (
          <CompoundUniverse
            data={data}
            images={images}
            settings={info.settings}
          />
        ) : (
          <Error>
            <ErrorBox>
              <FlexItem>
                <TextSmallBold color={'accent'}>
                  Error Loading Data for Universe
                </TextSmallBold>
              </FlexItem>
              <FlexItem>
                <Label>Universe Name:</Label>
                <Value>{info?.title}</Value>
              </FlexItem>
              <FlexItem>
                <Label>Error Message:</Label>
                <Value>{loadingErrors}</Value>
              </FlexItem>
              <FlexItem>
                <TextSmallItalics color={'primary'}>
                  If you think this is in error, please submit a bug report and
                  specify the universe name listed above
                </TextSmallItalics>
              </FlexItem>
            </ErrorBox>
          </Error>
        )}
      </PagePanelContainer>
    </PageContainer>
  );
};

export default PgUniverseUser;

const Error = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const ErrorBox = styled(FlexColumn)`
  border: 1px solid ${(p) => p.theme.palette.accentPrimary};
  max-width: 40%;
  padding: 15px;
  border-radius: 15px;
`;

const Label = styled.div`
  color: ${(p) => p.theme.palette.accentSecondary};
  width: 150px;
  padding-right: 5px;
  text-align: right;
`;

const Value = styled.div`
  color: ${(p) => p.theme.palette.textSecondary};
`;
