import _ from 'lodash';
import styled from 'styled-components/macro';
import { Checkbox } from '@mui/material';
import { CompoundFieldT } from '@stores/fields';


interface ExtraProps {
  compound?: CompoundFieldT;
  field_id?: string;
  fieldSrc?: string;
  border?: boolean;
}

export const Row = styled.tr<ExtraProps>`
  margin-bottom: 3px;
  
  ${(p) => p.border && `border-bottom: 1px solid ${p.theme.palette.accentSecondary}`};

  &:nth-child(even) {
    background-color: ${(p) => p.theme.palette.backgroundTertiary};
  }

  background-color: ${({ compound, field_id, theme, fieldSrc }) => {
    const sourceId = _.get(compound, `${fieldSrc}.${field_id}.source_id`, '-') as string;
    return sourceId === 'new'
      ? theme.palette.accentPrimary
      : 'inherit';  // Should be 'inherit', not 'inherits'
  }};

  color: ${({ compound, field_id, theme, fieldSrc }) => {
    const sourceId = _.get(compound, `${fieldSrc}.${field_id}.source_id`, '-') as string;
    return sourceId === 'new'
      ? theme.palette.textPrimary
      : 'inherit';
  }};
`;

export const Header = styled.th<{ parent?: string }>`
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  font-size: 14px;
  text-align: center;
  padding: 5px;
  padding-left: ${(p) => (p.parent === 'compoundSet' ? '0px' : '5px')};
  width: ${(p) => (p.parent === 'compoundSet' ? '0px' : 'auto')};
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const Cell = styled.td<{ parent?: string }>`
  height: ${(p) => p.theme.sizes.xlarge};
  min-width: ${(p) => (p.parent === 'compoundSet' ? '0px' : '120px')};
  max-width: ${(p) => (p.parent === 'compoundSet' ? '0px' : '400px')};
  padding-left: ${(p) => (p.parent === 'compoundSet' ? '0px' : p.theme.sizes.xsmall)};
  vertical-align: middle;
  text-align: center;
  font-size: 12px;
  font-weight: ${(p) => p.theme.weights.light};
`;

export const No = styled(Checkbox)`
  vertical-align: middle;
  color: ${(p) => p.theme.palette.backgroundQuaternary};
`;
