import React from "react";
import styled from "styled-components/macro";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";

export type StatusMapT = {
  label: string,
  checked: boolean
}

type PropsT = {
  statusMap: StatusMapT[]
}

const debug = false;
const UserRequestToDo = (props:PropsT) => {
  const {statusMap} = props;
  if (debug) console.log('props', props);
  const total = statusMap.length;
  const notComplete = statusMap.filter(m => m.checked === false).length;
  const allComplete = !statusMap.some(m => m.checked === false);
  if (debug) console.log('allComplete', allComplete, 'notComplete', notComplete);

  return(
    <ToDoContainer>
      <ToDoList>
        <Item><Highlight>{allComplete ? 'All Steps Completed:': `Steps to Complete (${notComplete} of ${total}):` }</Highlight></Item>
        {statusMap.map((item, index) => (
          <Item key={`todo_item_${index}`}>
            { item.checked ?
              <CheckBox />
              :
              <CheckBoxOutlineBlank />
            }
            <input type={'checkbox'} checked={item.checked}/>
            <Label>{item.label}</Label>
          </Item>
        ))}
      </ToDoList>
    </ToDoContainer>
  )
}
export default UserRequestToDo;

const ToDoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  border: 1px; solid ${(p)=>p.theme.palette.backgroundTertiary };
  background: ${(p)=>p.theme.palette.backgroundPrimary };
`;

const ToDoList = styled.div`
  display: flex;
  width: max-content;
  flex-direction: column;
`;

const Item = styled.div`
  display: flex;
  color: ${(p) => p.theme.palette.accentSecondary};
  margin-right: 5px;
`;

const Label = styled(Item)`
  color: ${(p) => p.theme.palette.textSecondary};
`;

const Highlight = styled(Label)`
  color: ${(p) => p.theme.palette.textPrimary};
  font-size: 14px;
  font-weight: bold;
`;