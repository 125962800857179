import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { UserContext } from '@stores/UserContext';
import PageContainer from '@as_core/pages/PageContainer';
import AccountDetails from "@as_core/account/AccountDetails";
import {MenuContext} from "@stores/MenuContext";
import Preferences from "./accountManagement/Preferences";
import RepoManagement from "@subApps/account/repositoryManagement/RepoManagement";
import SubscriptionManagement from "./subscriptionManagement/SubscriptionManagement";

const Titles = {
  'repositories': 'Manage My Repositories',
  'subscriptions': 'Manage My Team',
  'user': 'Manage My Profile',
  'preferences': 'Manage My Preferences'
}

function getTitle(view: string) {
  if (Object.hasOwn(Titles, view)) return Titles[view];
  return view;
}

const debug = false;
const PgUserAccount = () => {
  const { view} = useParams();
  const { user, setUser} = useContext(UserContext);
  if (debug) console.log('PgUserAccount: {action, user}', view, user);
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  if (user.subApp !== 'Account') {
    setUser((prev) => ({
      ...prev, subApp: 'Account'
    }));
  }
  const menuId = 'u.' + view;
  if (activeMenuId !== menuId) setActiveMenuId(menuId);

  return (
    <PageContainer title={getTitle(view)} >
      <UserAccount>
      {
        view === 'user' ?
          <AccountDetails />
        : view === 'subscriptions' ?
          <SubscriptionManagement />
        : view === 'repositories' ?
          <RepoManagement />
        : view === 'preferences' ?
          <Preferences />
        : <></>
      }
      </UserAccount>
    </PageContainer>
  );
};

export default PgUserAccount;

const UserAccount = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  justify-content: center;
  align-content: flex-start;
  padding: 10px;
  width: calc(100vw - 76px);
  height: calc(100vh - 70px);
  overflow-y: auto;
  overflow-x: hidden;
`;
