import styled from 'styled-components/macro';
import {RequestT} from "../../hooks/useRequests";
import MenuButton from "@components/elements/MenuButton";
import React from "react";
import BackArrowIcon from "@components/icons/backArrow.icon";
import {TableFieldsT, getFieldValue, isRequestConsulting} from "@subApps/orders/utils";
import RequestUserUpdate from "@subApps/orders/components/Assays/RequestUserUpdate";
import ConsultingUserUpdate from "@subApps/orders/components/Consulting/ConsultingUserUpdate";

const userFields: TableFieldsT[] = [
  {
    value: 'created_at',
    label: 'Created',
    type: 'date',
  },
  {
    value: 'name',
    label: 'Name',
    type: 'string',
  },
  {
    value: 'status',
    label: 'Status',
    type: 'string',
  },
  {
    value: 'assigned_to',
    label: 'Assigned To',
    type: 'vendor',
  },
  {
    value: 'cat_code',
    label: 'Catalog #',
    type: 'string',
  },
  {
    value: 'prod_name',
    label: 'Type',
    type: 'string',
  },
  {
    value: 'quantity',
    label: 'Quantity',
    type: 'quantity',
  },
  {
    value: 'updated_at',
    label: 'Last Updated',
    type: 'date',
  },
];

interface PropsI {
  request: RequestT;
  updateRequest: (r: RequestT)=>void;
  setUpdatedRequestForms: ()=>void;
  viewBack?: boolean;
  handleBack?: ()=>void;
}

const debug = false;
const RequestDetails = (props: PropsI) => {
  const {request, updateRequest, setUpdatedRequestForms, viewBack=true, handleBack} = props;
  if (debug) console.log('RequestDetails | request:', request);

  const displayValue = (request: RequestT, item: TableFieldsT, index: number) => {
    if (item?.type === 'line') {
      return <HorizontalLine key={index} />;
    }
    return (
      <UserInformation key={index}>
        <Label>{item.label}:</Label>
        <Value>{getFieldValue(request, item)}</Value>
      </UserInformation>
    );
  };

  return (
    <ViewContainer>
      <Row border={'top'}>
        <ActionButtons>
          { viewBack ?
            <MenuButton
              onClick={handleBack}
              width={50}
              icon={<BackArrowIcon />}
              text={'Back to Requests List'}
              tooltipPlacement={'bottom'}
            />
            :
            <></>
          }
        </ActionButtons>
        <RequestHeader>{ request?.name }</RequestHeader>
        <RightHeader></RightHeader>
      </Row>
      <Row>
        <ReportContainer>
          {Object.keys(request) ? (
            userFields.map((item, index) => displayValue(request, item, index))
          ) : (
            <></>
          )}
        </ReportContainer>
        { isRequestConsulting(request) ?
          <ConsultingUserUpdate
            request={request}
            updateSelectedRequest={updateRequest}
            setUpdatedRequestForms={setUpdatedRequestForms}
          />
        :
          <RequestUserUpdate
            request={request}
            updateSelectedRequest={updateRequest}
            setUpdatedRequestForms={setUpdatedRequestForms}
          />
        }
      </Row>
    </ViewContainer>
  );
};

export default RequestDetails;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
`;

export const Row = styled.div<{border?: string}>`
  display: flex;
  font-size: 12px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  padding-top: 5px;
  ${(p) => p.border === 'top' && `border-top: 1px solid ${p.theme.palette.accentPrimary}`};
`;

Row.defaultProps = {
  border: ''
}

const ActionButtons = styled.div`
  display: flex;
  width: max-content;
`

const RequestHeader = styled(ActionButtons)`
  color: ${(p) => p.theme.palette.accentSecondary};
`;

const RightHeader = styled(ActionButtons)`
`;


const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
`;

const HorizontalLine = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
`;

const UserInformation = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  display: flex;
  width: 150px;
  margin-right: 5px;
  justify-content: flex-end;
  font-size: 14px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const Value = styled.div`
  display: flex;
  width: 450px;
  font-size: 14px;
`;
