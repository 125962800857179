import React, { useState } from 'react';
import styled from 'styled-components/macro';

import TextButton from '@components/controls/TextButton';
import CompoundDrawIcon from '@components/icons/compoundDraw.icon';
import CompoundSmilesIcon from '@components/icons/compoundSmiles.icon';
import CompoundUploadIcon from '@components/icons/compoundUpload.icon';
import UploadComponent from '@components/UploadComponent';
import { Molecule } from 'openchemlib/full';
import { TextField } from '@mui/material';
import PagePanel from '@components/pages/PagePanel';
import ForwardArrowIcon from '@components/icons/forwardArrow.icon';

interface InputStructurePropsT {
  setFilename: any;
  setStructure: any;
  nextStep: () => void;
}

const debug = false;
const InputStructure = (props: InputStructurePropsT) => {
  const { setFilename, setStructure, nextStep } = props;
  if (debug) console.log('InputStructure | ');

  // STATES
  const [inputSmiles, setInputSmiles] = React.useState<string | null>(
    'c1ccccc1'
  );
  const [activeButton, setActiveButton] = useState<string>('');

  const importEmpty = () => {
    setStructure({ mol: '' });
    nextStep();
  };

  const importSmiles = () => {
    const molfile = Molecule.fromSmiles(inputSmiles).toMolfile();
    if (debug) console.log('smiles', inputSmiles);
    if (debug) console.log('mol', molfile);
    setStructure({ mol: molfile });
    nextStep();
  };

  const importFile = (acceptedFiles) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const result = event.target.result as string;
      setStructure({ mol: result });
      nextStep();
    };
    reader.readAsText(acceptedFiles[0]);
    setFilename(acceptedFiles[0].name);
  };
  let buttonWidth = 220;
  let buttonHeight = 40;
  let buttonMargin = '4px 10px';

  return (
    <PagePanel width={'850px'} height={'500px'}>
      <InputStructureContainer width={850}>
        <Row>
          <TextButton
            text={'Draw New Structure'}
            onClick={importEmpty}
            icon={<CompoundDrawIcon size={32} />}
            tooltipPlacement={'bottom'}
            width={buttonWidth}
            height={buttonHeight}
            margin={buttonMargin}
          />
          <TextButton
            text={'Input SMILES Notation'}
            isActive={activeButton === 'SMILES'}
            onClick={() => setActiveButton('SMILES')}
            icon={<CompoundSmilesIcon size={32} />}
            tooltipPlacement={'bottom'}
            width={buttonWidth}
            height={buttonHeight}
            margin={buttonMargin}
          />
          <TextButton
            text={'Upload Mol/SDF File'}
            isActive={activeButton === 'File'}
            onClick={() => setActiveButton('File')}
            icon={<CompoundUploadIcon size={32} />}
            tooltipPlacement={'bottom'}
            width={buttonWidth}
            height={buttonHeight}
            margin={buttonMargin}
          />
        </Row>
        <Row>
          {activeButton === 'SMILES' && (
            <SmilesContainer>
              <StyledTextField
                label='SMILES'
                variant='filled'
                value={inputSmiles}
                onChange={(e) => setInputSmiles(e.target.value)}
              />
              <TextButton
                text={'Edit/Update'}
                onClick={importSmiles}
                icon={<ForwardArrowIcon size={28} />}
                iconRight={true}
                width={120}
              />
            </SmilesContainer>
          )}
          {activeButton === 'File' && (
            <MyForm>
              <UploadLabel>Import SDF/Mol File</UploadLabel>
              <UploadComponent onDrop={importFile} />
            </MyForm>
          )}
        </Row>
      </InputStructureContainer>
    </PagePanel>
  );
};

export default InputStructure;

const InputStructureContainer = styled.div<{ width: number }>`
  display: flex;
  flex-direction: column;
  width: ${(p) => p.width}px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 20px;
`;

const SmilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: center;
  padding-top: 15px;
  border-top: 1px solid ${(p) => p.theme.palette.textSecondary};
`;

const StyledTextField = styled(TextField)`
  display: flex;
  margin: 8px;
  width: 400px;
`;

const MyForm = styled.form`
  border: none;
  margin: 0 auto;
  width: 90%;
  justify-content: center;
  padding-top: 15px;
  border-top: 1px solid ${(p) => p.theme.palette.textSecondary};
`;

const UploadLabel = styled.div`
  font-size: 18px;
  margin-left: 20px;
`;
