import React from 'react';
import styled from 'styled-components/macro';
import { OpenInNew } from '@mui/icons-material';
import MolImgTile from '../images/MolImgTile';
import SegmentImage from '@as_core/account/fields/SegmentImage';
import ColorBar from '@components/elements/ColorBar';
import {
  getFieldName,
  getCompoundFieldValue,
} from '@components/elements/compound/utils';
import { Tooltip, Checkbox } from '@mui/material';
import PushPinIcon from '@components/icons/pushPin.icon';
import { CompoundT } from '@src/type';

const bar_fields = ['cell_health_index'];
const annotation_fields = [
  'market_segment',
  'exact_mol_wt',
  'polar_surface_area',
  'crippen_log_p',
  'aromatic_rings',
  'fs_p3',
];

const debug = false;

interface CompoundTileProps {
  compound: CompoundT;
  allFields: unknown;
  handleRedirect: (rowKey: string) => void;
  isChecked: boolean;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  setPinnedData: React.Dispatch<React.SetStateAction<CompoundT[]>>;
  isPinned: boolean;
  width?: number | string;
  margin?: number | string;
}

const CompoundTile = (props: CompoundTileProps) => {
  const {
    compound,
    allFields,
    handleRedirect,
    isChecked,
    setSelected,
    setPinnedData,
    isPinned,
    width = 350,
    margin = 10,
  } = props;

  if (debug && isPinned) {
    console.log('CompoundTile | props', props);
  }

  const handleCheck = () => {
    if (!isChecked) {
      setSelected((previous) => [...previous, compound.searchKey]);
    } else {
      setSelected((previous) =>
        previous.filter((uuid) => uuid !== compound.searchKey)
      );
    }
  };

  const handlePinSelect = () => {
    if (debug) console.log('CompoundTile | handlePinSelect', isPinned);
    if (isPinned) {
      // remove from list
      setPinnedData((previous) =>
        previous.filter((comp) => comp.searchKey !== compound.searchKey)
      );
    } else {
      setPinnedData((previous) => [...previous, compound]);
    }
  };

  return (
    <TileContainer width={width} margin={margin}>
      <TileHead>
        <TileLeft>
          {getCompoundFieldValue(compound, 'compound_name', allFields)}
        </TileLeft>
        <TileRight>
          <StyledPin
            className={isPinned ? 'active' : 'normal'}
            onClick={handlePinSelect}
          />
          <StyledCheckbox checked={isChecked} onChange={handleCheck} />
          <Tooltip title={'View Full Compound Details'} arrow>
            <StyledOpenInNewIcon
              onClick={() => handleRedirect(compound.searchKey)}
            />
          </Tooltip>
        </TileRight>
      </TileHead>
      <TileBody>
        <MolImgTileContainer mol_svg={compound?.mol_svg} hover={false} />
        {bar_fields.map((f, index) => (
          <Item key={`${index}-${f}`}>
            <CellLabel>{getFieldName(f, allFields)}</CellLabel>
            <CellValue>
              <ColorBar value={getCompoundFieldValue(compound, f, allFields)} />
            </CellValue>
          </Item>
        ))}
        {annotation_fields.map((field, index) => (
          <Item key={`${index}-${field}`}>
            <CellLabel>{getFieldName(field, allFields)}</CellLabel>
            <CellValue>
              {field === 'market_segment' ? (
                <SegmentImage
                  data={compound?.annotations?.market_segment?.value}
                />
              ) : (
                getCompoundFieldValue(compound, field, allFields)
              )}
            </CellValue>
          </Item>
        ))}
      </TileBody>
      <TileSpace></TileSpace>
      <TileFooter>
        {getCompoundFieldValue(compound, 'casrn', allFields)}
      </TileFooter>
    </TileContainer>
  );
};

export default CompoundTile;

const StyledOpenInNewIcon = styled(OpenInNew)`
  cursor: pointer;
  opacity: 0.25;
  :hover {
    color: ${(p) => p.theme.palette.accentPrimary};
    opacity: 1;
  }
`;

const StyledPin = styled(PushPinIcon)`
  color: ${(p) => p.theme.palette.accentPrimary};
  cursor: pointer;
  transform: rotate(deg);
  filter: grayscale(1);
  width: 18px;

  :hover {
    filter: grayscale(0);
  }

  &.active {
    transform: rotate(-45deg);
    filter: grayscale(0);
  }
`;

const StyledCheckbox = styled(Checkbox)`
  display: flex;
  &.MuiCheckbox-root {
    padding: 0;
  }
`;

const TileContainer = styled.div<{
  width: number | string;
  margin?: number | string;
}>`
  display: flex;
  flex-direction: column;
  color: ${(p) => p.theme.palette.textPrimary};
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
  width: ${(p) => p.width}px;
  margin: ${(p) => (p.margin ? `${p.margin}px` : 'unset')};
  height: 400px;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
  align-items: center;
`;

const TileHead = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: ${(p) => p.theme.sizes.small};
`;

const TileLeft = styled.div`
  display: flex;
  width: 250px;
  justify-items: flex-start;
`;

const TileRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: flex-end;
  align-items: center;
`;

const TileBody = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  padding-left: ${(p) => p.theme.sizes.small};
  padding-right: ${(p) => p.theme.sizes.small};
  overflow-x: hidden;
  overflow-y: hidden;
`;

const TileSpace = styled.div`
  display: flex;
  flex-grow: 1;
`;

const TileFooter = styled.div`
  display: flex;
  align-self: end;
  font-size: ${(p) => p.theme.sizes.small};
`;

const MolImgTileContainer = styled(MolImgTile)`
  justify-self: center;
  align-self: center;
  height: 150px;
  width: 150px;
  overflow: hidden;
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
`;

const Item = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
`;

const CellLabel = styled.div`
  display: flex;
  padding-right: 5px;
  justify-content: end;
  width: 125px;
  font-size: ${(p) => p.theme.sizes.xsmall};
  color: ${(p) => p.theme.palette.textSecondary};
`;

const CellValue = styled.div`
  display: flex;
  width: 150px;
  font-size: ${(p) => p.theme.sizes.xsmall};
`;
