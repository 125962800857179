import React from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';

import { TextSmallItalics } from '@as_core/elements/textStyles';
import BasicTable from '@as_core/tables/BasicTable';
import IconCart from '@components/icons/IconCart';
import MenuButton from '@components/controls/MenuButton/MenuButton';
import TrashIcon from '@components/icons/trash.icon';
import {CartObjectType, UseCartObjectReturnType} from "@as_core/hooks/useShoppingCart";
import {Info, AddCircle, RemoveCircle} from "@mui/icons-material";
import { Tooltip } from '@mui/material';

type PropsI = {
  cart: UseCartObjectReturnType;
  noQuantityChanges?: boolean;
  debug?: boolean;
};

const tableFields = [
  { value: 'delete', label: '', width: 50 },
  { value: 'info', label: '', width: 50 },
  { value: 'name', label: 'Product Name', width: 250, align: 'left' },
  { value: 'metadata.catalogNumber', label: 'Catalog Number', width: 150, align: 'left' },
  { value: 'price', label: 'Unit Price', width: 100, align: 'right' },
  { value: 'quantity', label: 'Quantity', width: 100 },
  { value: 'totalCost', label: 'Line Item Cost', width: 100, align: 'right' },
]

// compute and format total cost from the shoppingCart
function getTotalCost(cart: CartObjectType) {
  let totalCost = 0;
  for (const cartItem of Object.keys(cart)) {
    if (cartItem !== 'AS-SYS-ACS12-ACD') {
      totalCost = totalCost + cart[cartItem].product?.price.unit_amount / 100.0 * cart[cartItem].quantity;
    }
  }
  return '$ ' + totalCost.toLocaleString(undefined, {minimumFractionDigits: 2});
}

// format the specific columns from the shoppingCart
const getTableRows = (cart:UseCartObjectReturnType, props: PropsI) => {
  const rows = [];
  for (const cartItem of Object.keys(cart.data)) {
    if (props.debug) console.log('cartItem', cartItem, cart.data[cartItem])
    const productInfo = cart.data[cartItem].product;
    const quantity = cart.data[cartItem].quantity
    if (props.debug) console.log('OrdersCart | getTableRows', cartItem, productInfo);
    const row = {};
    row['delete'] = productInfo.metadata.catalogNumber === 'AS-SCREEN-3RND' ? null :
      <MenuButton
        icon={<TrashIcon />}
        onClick={() => cart.delete(productInfo.metadata.catalogNumber)}
        text={'Delete Item'}
        tooltipPlacement={'bottom-end'}
      />;
    row['info'] =
      <Tooltip
        title={productInfo.description}
        arrow
        placement={'right-end'}
      >
        <StyledInfo />
      </Tooltip>;
    for (const field of tableFields) {
      if (!['delete', 'info', 'price', 'quantity', 'totalCost'].includes(field.value)) {
        row[field.value] = _.get(productInfo, field.value, '');
      } else if (field.value === 'price') {
        row[field.value] = '$' + (productInfo.price.unit_amount/100.0).toLocaleString(undefined, {minimumFractionDigits: 2});
      } else if (field.value === 'quantity') {
        if (props.noQuantityChanges || productInfo.metadata.catalogNumber === 'AS-SCREEN-3RND') {
          row[field.value] = <PrimaryText>{ quantity } </PrimaryText>;
        } else {
          const subtract = quantity > Number(productInfo.metadata.unitOrderMinSize) ?
            <StyledRemoveCircle sx={{fontSize: 18}} onClick={()=>cart.add(productInfo, -1)}/> : <EmptyIcon />;
          const add = <StyledAddCircle sx={{fontSize: 18}} onClick={()=>cart.add(productInfo, 1)}/>
          row[field.value] = <QuantityContainer>{subtract}<QuantityText>{quantity}</QuantityText>{add}</QuantityContainer>;
        }
      } else if (field.value === 'totalCost') {
        if (cartItem === 'AS-SYS-ACS12-ACD') {
          row[field.value] = <PrimaryText>Contact Us</PrimaryText>
        } else {
          const showValue = '$' + (quantity * productInfo.price.unit_amount / 100.0)
            .toLocaleString('en-US', {minimumFractionDigits: 2});
          row[field.value] = <PrimaryText>{showValue}</PrimaryText>;
        }
      }
    }
    rows.push(row);
  }
  return rows;
}

const OrdersCart = (props:PropsI) => {
  const { cart} = props;
  if (props.debug) console.log('OrderCart | {props}:', props);

  // empty carts
  if (!Object.keys(cart.data).length) {
    return(
      <Contents>
        <EmptyCartContainer>
          <TextSmallItalics color={'accentSecondary'}>Empty Cart</TextSmallItalics>
        </EmptyCartContainer>
      </Contents>
    )
  }

  // cart with items
  const showFields = props.noQuantityChanges ?
    tableFields.filter((f)=> f.value !== 'delete') : tableFields;
  return (
    <Contents>
      <OrdersCartTitle>
        <IconCart/><Space/>
        Orders Cart - Total Cost {getTotalCost(cart.data)}
      </OrdersCartTitle>
      <BasicTable
        fields={showFields}
        rows={getTableRows(cart, props)}
      />
    </Contents>
  );
};

OrdersCart.defaultProps = {
  noQuantityChanges: false,
  debug: false
}

export default OrdersCart;

const Contents = styled.div`
  display: flex;
  width:  100%;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  font-size: ${(p) => p.theme.sizes.small};
`;

const EmptyCartContainer = styled.div`
  display: flex;
  padding: 10px;
  margin-top: 20px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
`;

const Space = styled.div`
  display: flex;
  width: 10px;
`;

const OrdersCartTitle = styled.div`
  display: flex;
  font-size: 18px;
  margin-top: 12px;
`;

const PrimaryText = styled.div`
  display: flex;
  font-size: 14px;
  color: ${(p) => p.theme.palette.textPrimary};
`;

const QuantityContainer = styled.div`
  display: flex;
  font-size: 14px;
  flex-direction: row;
  align-items: center;
  color: ${(p) => p.theme.palette.textPrimary};
`;

const StyledInfo = styled(Info)`
  display: flex;
  cursor: pointer;
  color: ${(p) => p.theme.palette.accentSecondary};
  :hover {
    color: ${(p) => p.theme.palette.textPrimary};
  }
`;

const StyledRemoveCircle = styled(RemoveCircle)`
  display: flex;
  cursor: pointer;
  color: ${(p) => p.theme.palette.accentSecondary};
  :hover {
    color: ${(p) => p.theme.palette.textPrimary};
  }
`;

const StyledAddCircle = styled(AddCircle)`
  display: flex;
  cursor: pointer;
  color: ${(p) => p.theme.palette.accentSecondary};
  :hover {
    color: ${(p) => p.theme.palette.textPrimary};
  }
`;

const EmptyIcon = styled.div`
  width: 18px;
`;

const QuantityText = styled.div`
  display: flex;
  justify-content: center;
  width: 30px;
`;
