import React, {useState} from "react";
import styled from "styled-components/macro";
import FileUpload, {FilesT} from "@as_core/controls/inputs/FileUpload";
import RequestUploadForms from "@subApps/orders/components/Assays/RequestUploadForms";
import {RequestT} from "@subApps/orders/hooks/useRequests";
import {RequestFormT} from "@subApps/orders/hooks/useRequestForms";
import {WarningAmber} from "@mui/icons-material";

type PropsT = {
  request: RequestT;
  requestForms: RequestFormT[];
  setRequestForms: (forms:RequestFormT[])=>void;
  handleClose: ()=>void;
  setUpdated:(v:boolean)=>void;
}

const debug = false;
const RequestFormUpload = (props:PropsT) => {
  const { request, requestForms, setRequestForms, handleClose, setUpdated} = props;
  if (debug) console.log('RequestFormUpload | request:', request);
  const [files, setFiles] = useState<FilesT>([]);
  const handleOnDrop = async (acceptedFiles:FilesT) => {
    if (debug) console.log('RequestFormUpload | acceptedFiles: ', acceptedFiles);
    const newFiles = [];
    acceptedFiles.forEach((file)=>{
      if (debug) console.log('RequestFormUpload | f', file.name);
      newFiles.push(file);
    });
    setFiles(newFiles);
  };

  return(
    <Container>
      { (!files.length) ?
        <>
          { requestForms !== null && requestForms.length ?
            <Message><WarningAmber sx={{color: 'yellow'}}/>Currently uploaded files ({requestForms.length}) will be deleted and replaced.</Message>
            :
            null
          }
          <FileUpload
            handleUpload={handleOnDrop}
          />
        </>
      :
        <RequestUploadForms
          request={request}
          requestForms={requestForms}
          setRequestForms={setRequestForms}
          setUpdated={setUpdated}
          handleClose={handleClose}
          files={files}
        />
      }
    </Container>
  )
}
export default RequestFormUpload;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Message = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
  color: ${(p) => p.theme.palette.accentPrimary};
`;