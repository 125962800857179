import React from "react";
import DashboardIcon from '@components/icons/dashboard.icon';
import CompoundsIcon from '@components/icons/compounds.icon';
import MyCompoundsIcon from '@components/icons/myCompounds.icon';
import CompoundUniverseIcon from '@components/icons/compoundUniverse.icon';
import IconInfo from '@components/icons/info.icon';
import KinomeUniverseIcon from '@components/icons/kinomeUniverse.icon';
import GraphIcon from '@components/icons/graph.icon';
import EditIcon from '@components/icons/edit.icon';
import FavoritesIcon from '@components/icons/favorites.icon';
import UploadIcon from '@components/icons/upload.icon';
import OurBiologyIcon from '@components/icons/ourBiology.icon';
import IconHome from '@components/icons/IconHome';
import SubscriptionsIcon from "@components/icons/subscriptions.icon";
import OrdersAddIcon from "@components/icons/orders.add";
import OrdersActiveIcon from "@components/icons/orders.active";
import OrdersPastIcon from "@components/icons/orders.past";
import {AccountTree, GroupAdd, Person} from "@mui/icons-material";
import SettingsIcon from "@components/icons/settings.icon";
import FulfillmentActiveIcon from "@as_core/icons/fulfillmentActive.icon";
import FulfillmentConsultingIcon from "@as_core/icons/fulfillmentConsulting.icon";
import InsightsIcon from "@as_core/icons/insights.icon";

export type MenuItemsT = {
  id: string;
  name: string;
  label: string;
  url?: string;
  pathname: string;
  icon: React.ReactNode;
  subscriptions?: string[];
  authorizedOnly: boolean;
  subApp?: string[];
}

export const menuItems: MenuItemsT[] = [
  {
    id: 'home',
    name: 'Home',
    label: 'HM',
    url: '/home',
    pathname: '/config',
    icon: <IconHome />,
    authorizedOnly: false,
  },
  {
    id: 'dashboard',
    name: 'Dashboard',
    label: 'DB',
    pathname: '/dashboard',
    url: '/dashboard',
    icon: <DashboardIcon />,
    authorizedOnly: true,
    subApp: ['3RnD']
  },
  {
    id: 'asedaCompounds',
    name: 'AsedaSciences Compounds',
    label: 'ASC',
    pathname: '/compounds/aseda/table?from=menu',
    url: '/compounds/aseda/table',
    icon: <CompoundsIcon />,
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+'],
    authorizedOnly: true,
    subApp: ['3RnD']
  },
  {
    id: 'asedaCompoundsPublic',
    name: 'AsedaSciences Compounds (Public)',
    label: 'ASC',
    pathname: '/compounds/aseda_public/cellHealth',
    url: '/compounds/aseda_public/cellHealth',
    icon: <CompoundsIcon />,
    subscriptions: ['basic'],
    authorizedOnly: true,
    subApp: ['3RnD']
  },
  {
    id: 'userCompounds',
    name: 'My Compounds',
    label: 'MC',
    pathname: '/compounds/user/table?from=menu',
    url: '/compounds/user/table',
    icon: <MyCompoundsIcon />,
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+'],
    authorizedOnly: true,
    subApp: ['3RnD']
  },
  {
    id: 'favorites',
    name: 'My Favorites',
    label: 'FV',
    pathname: '/compounds/favorites',
    url: '/compounds/favorites',
    icon: <FavoritesIcon />,
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+'],
    authorizedOnly: true,
    subApp: ['3RnD']
  },
  {
    id: 'compoundUniverse',
    name: 'Compound Universe',
    label: 'CU',
    pathname: '/universe',
    url: '/universe',
    icon: <CompoundUniverseIcon />,
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+'],
    authorizedOnly: true,
    subApp: ['3RnD']
  },
  {
    id: 'userUniverse',
    name: 'My Universes',
    label: 'MU',
    pathname: '/universe/user',
    icon: <KinomeUniverseIcon />,
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+'],
    authorizedOnly: true,
    subApp: ['3RnD']
  },
  {
    id: 'upload',
    name: 'Upload Data',
    label: 'IM',
    pathname: '/import/list',
    icon: <UploadIcon />,
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+'],
    authorizedOnly: true,
    subApp: ['3RnD']
  },
  {
    id: 'plots',
    name: 'My Plots',
    label: 'MP',
    pathname: '/plots',
    icon: <GraphIcon />,
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+'],
    authorizedOnly: true,
    subApp: ['3RnD']
  },
  {
    id: 'fields',
    name: 'Fields Editor',
    label: 'FE',
    pathname: '/fields',
    icon: <EditIcon />,
    subscriptions: ['disable'],
    authorizedOnly: true,
    subApp: ['3RnD']
  },
  {
    id: 'order',
    name: 'Create Screen Order',
    label: 'ORDR',
    pathname: '/orders/create',
    icon: <OrdersAddIcon />,
    authorizedOnly: false,
    subApp: ['Orders']
  },
  {
    id: 'orders_active',
    name: 'My Open Orders',
    label: 'ORDR_OPEN',
    pathname: '/orders/active',
    icon: <OrdersActiveIcon />,
    authorizedOnly: false,
    subApp: ['Orders']
  },
  {
    id: 'orders_requests',
    name: 'Order Fulfillment',
    label: 'ORDR_REQUEST',
    pathname: '/orders/requests',
    icon: <FulfillmentActiveIcon />,
    authorizedOnly: false,
    subApp: ['Orders']
  },
  {
    id: 'orders_consulting',
    name: 'Consulting Fulfillment',
    label: 'ORDR_CONSULTING',
    pathname: '/orders/consulting',
    icon: <FulfillmentConsultingIcon />,
    authorizedOnly: false,
    subApp: ['Orders']
  },
  {
    id: 'orders_complete',
    name: 'My Completed Orders',
    label: 'ORDRLST',
    pathname: '/orders/complete',
    icon: <OrdersPastIcon />,
    authorizedOnly: false,
    subApp: ['Orders']
  },
  {
    id: 'biology',
    name: 'Our Biology',
    label: 'OB',
    pathname: '/biology/systemetric/summary',
    icon: <OurBiologyIcon />,
    authorizedOnly: false,
    subApp: ['3RnD', 'Orders']
  },
  {
    id: 'u.repositories',
    name: 'Manage My Repositories',
    label: 'UO',
    pathname: '/account/repositories/owned',
    icon: <AccountTree />,
    authorizedOnly: false,
    subApp: ['Account']
  },
  {
    id: 'u.subscriptions',
    name: 'Manage My Team',
    label: 'US',
    pathname: '/account/subscriptions',
    icon: <GroupAdd />,
    subscriptions: ['pilot', 'academic+'],
    authorizedOnly: false,
    subApp: ['Account']
  },
  {
    id: 'u.user',
    name: 'Manage My Profile',
    label: 'UR',
    pathname: '/account/user',
    url: '/account/user',
    icon: <Person />,
    authorizedOnly: false,
    subApp: ['Account']
  },
  {
    id: 'u.preferences',
    name: 'Manage My Preferences',
    label: 'US',
    pathname: '/account/preferences',
    icon: <SettingsIcon />,
    authorizedOnly: false,
    subApp: ['Account']
  },
  {
    id: 'admin',
    name: 'User/Repo Administration',
    label: 'SC',
    pathname: '/admin/users',
    icon: <SettingsIcon />,
    authorizedOnly: false,
    subApp: ['Admin']
  },
  {
    id: 'a.insights',
    name: 'User/Repo Administration',
    label: 'SC',
    pathname: '/admin/insights',
    icon: <InsightsIcon />,
    authorizedOnly: false,
    subApp: ['Admin']
  },
  {
    id: 'subscriptions',
    name: 'Subscriptions',
    label: 'SC',
    pathname: '/subscriptions',
    icon: <SubscriptionsIcon />,
    subscriptions: ['basic'],
    authorizedOnly: false,
    subApp: ['Account', '3RnD']
  },
  {
    id: 'info',
    name: 'Info/FAQ',
    label: 'FAQ',
    pathname: '/info/upload',
    icon: <IconInfo />,
    authorizedOnly: true,
  },
];
