import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';

interface GenericRendererProps {
  value?: string;
  fieldId: string;
  handleChange: (fieldId: string, value: string) => void;
  error?: { text: string };
}

const debug = false;
const GenericRenderer = (props: GenericRendererProps) => {
  const { value= "No value defined", fieldId, handleChange, error } = props;

  const [newValue] = useState(value);
  const inputRef = useRef();

  if (debug) console.log('GenericRenderer | newValue: ', newValue);

  return (
    <StyledInput
      ref={inputRef}
      type='text'
      name={fieldId}
      value={newValue}
      placeholder={error?.text || 'enter value'}
      onChange={(e) => {
        handleChange(fieldId, e.target.value);
      }}
      error={!!error}
    />
  );
};

export default GenericRenderer;

const StyledInput = styled.input<{ error?: boolean }>`
  box-sizing: border-box;
  border: ${(p) =>
    p.error
      ? `1px solid ${p.theme.palette.accentPrimary}`
      : '1px solid transparent'};
  outline: none;
  font-size: 12px;
  font-family: ${(p) => p.theme.fonts.main};
  font-weight: ${(p) => p.theme.weights.default};
  color: ${(p) => p.theme.palette.textPrimary};
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: ${(p) => p.theme.palette.backgroundTertiary};

  &::placeholder {
    color: ${(p) =>
      p.error ? p.theme.palette.accentPrimary : p.theme.palette.textSecondary};
  }
`;
