import fromNow from 'fromnow';

// replacement for lodash _.get() -- really only lodash function we consistently use - DHR 2024-07-25

// I think that we should use the lodash one, it's a big an useful package - DTD 2024-10-31
export function getObjectValueByKey(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: { [key: string]: any },
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue: any = ''
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  if (obj === null) return defaultValue;
  const keyParts = key.split('.');
  let newKey = keyParts.shift();
  let objRef = obj;
  while (keyParts.length) {
    if (!Object.hasOwn(objRef, newKey) || objRef[newKey] === null)
      return defaultValue;
    objRef = objRef[newKey];
    newKey = keyParts.shift();
  }
  if (!Object.hasOwn(objRef, newKey) || objRef[newKey] === null)
    return defaultValue;
  return objRef[newKey];
}

// another variant of replacement for lodash _.get() -- really only lodash function we consistently use - DHR 2024-07-25
export function getValueByDot(
  obj: object,
  path: string,
  defaultValue: string | number = ''
) {
  // console.log(obj, path);
  if (obj === undefined || path === undefined) return defaultValue;
  return path.split('.').reduce((acc, key) => acc?.[key], obj) ?? defaultValue;
}

export const roundOf = (n, p) => {
  // https://stackoverflow.com/a/49862458
  const n1 = n * Math.pow(10, p + 1);
  const n2 = Math.floor(n1 / 10);
  if (n1 >= n2 * 10 + 5) {
    return (n2 + 1) / Math.pow(10, p);
  }
  return n2 / Math.pow(10, p);
};

export const ufromNow = (dt: string) =>
  fromNow(dt, { and: false, max: 2, suffix: true });

export const fieldIdToObjectId = {
  anitox: 'animal_tox_pharma',
  '7943dd32-5266-448e-8762-79342c5f4138': 'animal_tox_general',
};

export const compoundObjectNameField = {
  anitox: 'anitox_species_pharma',
  animal_tox_pharma: 'anitox_species_pharma',
  animal_tox_general: 'anitox_species_industrial',
  '7943dd32-5266-448e-8762-79342c5f4138': 'anitox_species_industrial',
};

// https://davidbieber.com/snippets/2020-12-26-pythons-strip-lstrip-and-rstrip-in-javascript/
export function ltrim(x, characters) {
  let start = 0;
  while (characters.indexOf(x[start]) >= 0) {
    start += 1;
  }
  return x.substr(start);
}

export const handleError = (err) => {
  if (err?.response?.status === 400) {
    console.log('ERROR', err?.response?.data?.errors);
    err?.response?.data?.errors?.forEach((error: string) => {
      alert('API Error: ' + error);
    });
  } else {
    if (err?.response?.status) console.log('ERROR CODE', err?.response?.status);
    if (err?.response?.data) console.log('ERROR', err?.response?.data);
  }
};

export function truncateString(str, maxLength, trailing = 0) {
  if (str === undefined || !str) return '';
  if (typeof str === 'number') {
    return str.toString();
  }
  if (str.length <= maxLength) {
    return str;
  } else if (trailing) {
    const strLen = str.length;
    return (
      str.slice(0, maxLength - trailing) +
      '...' +
      str.slice(strLen - trailing, strLen)
    );
  } else {
    return str.slice(0, maxLength) + '...';
  }
}
