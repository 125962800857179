import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components/macro";
import TopMenu from "@as_core/menus/TopMenu";
import { UserContext } from '@stores/UserContext';
import { useApiInfo } from "@stores/apiInfo";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@src/store";
import { getFields as getFields3 } from "@stores/fields";
import useCognito from "@as_core/account/useCognito";

interface propsT {
  title: string;
  padding?: number;
  children: React.ReactNode;
  scroll?: boolean;
}

const debug = false;
const PageContainer = (props: propsT) => {
  const { title, padding = 0, scroll = true } = props;
  const { user } = useContext(UserContext);
  if (debug) console.log("PageContainer | title:", title, " user:", user);
  const [localFieldsLoading, setLocalFieldsLoading] = useState(false);
  const [infoLoaded, setInfoLoaded] = useState<boolean>(false);
  const [appType, setAppType] = useState<string>('');
  const [apiInfo, setApiInfo] = useState({});
  const { getApiInfo } = useApiInfo();
  const [version, setVersion] = useState('');
  const dispatch = useDispatch();
  const { getToken } = useCognito();

  const {
    fields,
    fieldIds,
    loading: fieldsLoading,
  } = useSelector((state: RootState) => state.fields);

  // call the dataFields at this level so that it is maintained
  useEffect(() => {
    if (user.isAuthenticated && !fields.length && !localFieldsLoading) {
      setLocalFieldsLoading(true);
      const token = getToken();
      dispatch(getFields3({ token }));
    }
  }, [dispatch, fields, fieldIds, fieldsLoading, localFieldsLoading]);
  if (fields.length) {
    setLocalFieldsLoading(false);
  }

  useEffect(() => {
    if (user.isAuthenticated && !infoLoaded) {
      let app_type = '';
      if (!process.env.REACT_APP_HOST_TYPE.includes('prd')) {
        app_type = ' (' + process.env.REACT_APP_HOST_TYPE + ')';
      } else if (process.env.REACT_APP_HOST_TYPE.includes('prd-')) {
        const client = process.env.REACT_APP_HOST_TYPE.replace('prd-', '');
        app_type = ' (' + client + ')';
      }
      setAppType(app_type);
      getApiInfo(getToken()).then((response) => {
        if (debug) console.log("apiInfo {response}", response);
        const apiInfo = response.info;
        setInfoLoaded(true);
        setApiInfo(apiInfo);
        const ui = process.env.REACT_APP_HOST_TYPE;
        const version = ui.concat(
          '|',
          apiInfo?.api?.version,
          ':',
          apiInfo?.api?.build_id,
          '|',
          apiInfo?.db?.type,
          ':',
          apiInfo?.db.version
        );
        setVersion(version);
      });
    }
  }, [infoLoaded, apiInfo]);

  return (
    <Container>
      <TopMenu title={title} appType={appType} />
      <PageContent padding={padding}>
        {scroll ?
          <ScrollBox>
            {props.children}
          </ScrollBox>
          :
          <>
            {props.children}
          </>
        }
      </PageContent>
      <PageFooter>vers:{version}</PageFooter>
    </Container>
  );
};

export default PageContainer;

const Container = styled.div`
  position: fixed;
  height: 100vh;
  width: calc(100vw - 50px);
  top: 0;
  left: 50px;
`;

const PageContent = styled.div<{ padding: number }>`
  position: relative;
  width: calc(100vw - 50px - 2*${(p)=>p.padding}'px');
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  padding: ${(p) => p.padding}px;
  flex: auto;
  & ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  & ::-webkit-scrollbar-track {
    background: transparent;
  }
  & ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  & ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
`;

const ScrollBox = styled.div`
  height: calc(100vh - 50px);
  background-color: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0;
`;

const PageFooter = styled.div`
  position: absolute;
  height: 12px;
  font-size: 8px;
  line-height: 10px;
  padding-left: 5px;
  bottom: 0;
  right: 20px;
  color: ${(p) => p.theme.palette.textQuaternary};
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  z-index: 0;
`;
