import { useState } from 'react';
import {StripeProductT} from "@business/products/useStripeProducts";

// Define the type of the object for clarity. Adjust it based on your needs.
type CartItemT = {
  product: StripeProductT,
  quantity: number
}
export type CartObjectType = {
  [catalogNumber: string]: CartItemT
};

// The hook's return type
export type UseCartObjectReturnType = {
  data: CartObjectType;
  length: () => number;
  add: (product: StripeProductT, quantity: number) => void;
  delete: (catalogNumber: string) => void;
  reset: () => void;
};

const useShoppingCart = (initialData: CartObjectType = {}): UseCartObjectReturnType => {
  const [data, setData] = useState<CartObjectType>(initialData);

  const add = (product: StripeProductT, quantity: number) => {
    const catNo = product.metadata.catalogNumber;
    if (Object.hasOwn(data, catNo)) {
      // product already in cart -- update data
      const newQuantity = data[catNo].quantity + quantity;
      setData(prev => ({
        ...prev,
        [catNo]: {product: product, quantity: newQuantity}
      }));
    } else {
      setData(prevData => ({
        ...prevData,
        [catNo]: {product: product, quantity: quantity}
      }));
    }

  };

  const length = () => {
    return Object.keys(data).length;
  }

  const deleteItem = (catalogNumber: string) => {
    setData(prev => (
      Object.fromEntries(Object.entries(prev)
        .filter(([key]) => key !== catalogNumber)
      )));
  };

  const reset = () => {
    setData({});
  };

  return { data, add, length, delete: deleteItem, reset };
};

export default useShoppingCart;
