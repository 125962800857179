import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';
import {defaultDataSet} from '@components/dataSets/dataSet.service';
import useData from "@components/dataSets/useData";
import DataLoading from "@as_core/elements/DataLoading";
import PreviewDataTable from "./preview/PreviewDataTable";
import PreviewDataDoseResponse from "./preview/PreviewDataDoseResponse";
import { DataSetT } from "@components/dataSets/dataSet.types";
import useCognito from "@as_core/account/useCognito";

interface propsT {
  uuid: string;
  height?: number;
  width?: number;
}

const debug = false;
const PreviewData = (props: propsT) => {
  if (debug) console.log('PreviewData | props', props);
  const { getData } = useData();
  const { getToken } = useCognito();
  const {uuid, height=300, width=300} = props;
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [dataSet, setDataSet] = useState<DataSetT>(defaultDataSet(''));

  // Load the data and set it into appropriate format for the DataTable
  useEffect(() => {
    getData(getToken(), uuid)
        .then((dataSet) => {
          // dataSet.data = JSON.parse(dataSet.data);
          if (debug) console.log('PreviewData | Response: ', dataSet);
          setDataSet(dataSet);
          setLoadingData(false);
        }).catch(console.error);

  }, [uuid])
  if (debug) console.log("PreviewData | dataSet:", dataSet);

  return(
      <PreviewDataContainer height={height} width={width}>
        { loadingData ?
          <DataLoading /> :
            dataSet.type === "Dose Response" ?
              <PreviewDataDoseResponse dataSet={dataSet} height={height} width={width}/>
              : <PreviewDataTable data={dataSet.data} />
        }
      </PreviewDataContainer>
  )
}

export default PreviewData;

const PreviewDataContainer = styled.div<{height: number, width: number}>`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  overflow-x: auto;
  overflow-y: auto;
`;